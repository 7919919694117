import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ContentType, Descendant } from "./types.ts";
import { useParams } from "react-router-dom";
import { HistoryEditor, withHistory } from "slate-history";
import { ReactEditor, withReact } from "slate-react";
import { createEditor } from "slate";
import { useSpaceKeywordsQuery } from "../SpacesV2/useSpaces.ts";
import { Keywords } from "../SpacesV2/types.ts";

export type PerfectContentState = {
  isLoading: boolean;
  spaceId?: string;
  asin?: string;
  activeTab?: ContentType;
  setActiveTab: Dispatch<SetStateAction<ContentType>>;
  editor: ReactEditor & HistoryEditor;
  editorContentValue: Descendant[];
  setEditorContentValue: Dispatch<SetStateAction<Descendant[]>>;
  keywordsList: Keywords[];
  selectedKeywords: string[];
  setSelectedKeywords: Dispatch<SetStateAction<string[]>>;
};

export const PerfectContentContext = createContext<PerfectContentState>(
  {} as PerfectContentState
);

export const usePerfectContent = () => useContext(PerfectContentContext);

export const PerfectContentProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [activeTab, setActiveTab] = useState<ContentType>("title");
  const [isLoading, setIsLoading] = useState(true);
  const [editorContentValue, setEditorContentValue] = useState<Descendant[]>([
    {
      children: [
        {
          text: "Speed Stick Men's Deodorant, Regular, 3 Ounce, 4 Pack antiperspirant odor protection hour sweat sweat and odor aluminum free natural",
        },
      ],
    },
  ]);
  const { spaceId, asin } = useParams<{ spaceId: string; asin: string }>();
  const editor = useMemo(
    () => withHistory(withReact(createEditor() as ReactEditor)),
    []
  );

  const { data: keywordsList } = useSpaceKeywordsQuery({
    spaceId: spaceId,
    limit: 1000,
  });
  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const value = {
    isLoading,
    spaceId,
    asin,
    activeTab,
    setActiveTab,
    editor,
    editorContentValue,
    setEditorContentValue,
    keywordsList: keywordsList?.data || [],
    selectedKeywords,
    setSelectedKeywords,
  };

  return (
    <PerfectContentContext.Provider value={value}>
      {children}
    </PerfectContentContext.Provider>
  );
};
