import { Card, CardHeader, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import TitleScoreIndicator from "./TitleScore.tsx";

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    container: {
      display: "flex",
      height: "fit-content",
      width: "70%",
      gap: "16px",
    },
    rightCard: {
      padding: "0 16px 0 16px",
      display: "flex",
      gap: "32px",
    },
    leftCard: {
      display: "flex",
      width: "fit-content",
    },
    border: {
      backgroundColor: `${palette.borders.lines}`,
      width: "1px",
      height: "64px",
    },
    controller: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
    },
    Titlecontroller: {
      paddingTop: "16px",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "end",
      width: "100%",
    },
    smallCard: {
      border: "none !important",
      flexDirection: "column",
      display: "flex",
      justifyContent: "space-between",
      padding: "0",
      width: "100px",
      minHeight: "64px",
      boxShadow: "none !important",
      borderRadius: "0 !important",
      "&:hover": {
        boxShadow: "none !important",
      },
      marginRight: "24px",
    },
    card: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      padding: "8px 12px 16px 12px",
    },
    cardTitle: {
      padding: "0px !important",
      "& p": {
        fontWeight: "500 !important",
        fontSize: "13px!important",
        lineHeight: "16px!important",
        color: "#2C224C !important",
      },
    },
    cardTitleOther: {
      padding: "0px !important",
      "& p": {
        fontWeight: "500 !important",
        fontSize: "13px!important",
        lineHeight: "16px!important",
        color: "#2C224C !important",
      },
    },
    cardTitleAbout: {
      padding: "0px !important",
      "& p": {
        fontWeight: "500 !important",
        fontSize: "13px!important",
        lineHeight: "16px!important",
        color: "#2C224C !important",
      },
    },

    smallCardGrid: {
      padding: "0px",
    },
    cardText: {
      fontWeight: "400 !important",
      fontSize: "14px!important ",
      lineHeight: "12px!important",
      color: "#2C224C!important",
      paddingBottom: "2px",
    },
  })
);

const ScoreCard = ({
  title,
  baseScore = 10,
  currentScore = 12,
  isLoading = false,
}: {
  title: string;
  baseScore?: number;
  currentScore?: number;
  isLoading?: boolean;
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.smallCard}>
      <div className={classes.smallCardGrid}>
        <CardHeader
          className={classes.cardTitleOther}
          title={<Typography>{title}</Typography>}
        />
        <div className={classes.Titlecontroller}>
          <TitleScoreIndicator
            baseValue={baseScore}
            value={currentScore}
            isLoading={isLoading}
          />
        </div>
      </div>
      {/*<Chart*/}
      {/*    baseScore={baseScore}*/}
      {/*    currentScore={+currentScore.toFixed(0)}*/}
      {/*    isLoading={isLoading}*/}
      {/*    isHeaderExpanded={isHeaderExpanded}*/}
      {/*/>*/}
    </Card>
  );
};

export default ScoreCard;
