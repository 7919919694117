import { Box, Stack, Typography } from "@mui/material";
import { useSalesAssistant } from "../SalesAssistantProvider.tsx";
import theme from "../../../themes/light.ts";
import SortableTitle from "./SortableTitle.tsx";

const ListHead = () => {
  const { selectedGroupBy, salesAssistantList } = useSalesAssistant();
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      display="flex"
      padding="0 17px"
    >
      <Typography variant="caption" color={theme.palette.grey[400]} flex={2.5}>
        {salesAssistantList?.total} {selectedGroupBy}
      </Typography>
      <SortableTitle title="Revenue" value="revenue" />
      <SortableTitle title="Units Sold" value="units_sold" />
      <SortableTitle title="Conversion" value="conversion" />
      <SortableTitle title="Glance Views" value="glance_views" />
      <Box width="70px" />
    </Stack>
  );
};

export default ListHead;
