import { Flow, FlowCreate } from "../../types";
import apiRequest from "../apiRequest";
import { FlowInstallResponse, ListFlowsResponse } from "./types";

export async function addFlow(data: FlowCreate): Promise<Flow> {
  return await apiRequest<Flow>("v1/flows", "POST", undefined, {
    display_name: data.displayName,
    description: data.description,
    visibility: data.visibility,
  });
}

export async function updateFlow(
  flowId: string,
  data: FlowCreate,
): Promise<Flow> {
  return await apiRequest<Flow>("v1/flows", "PATCH", undefined, {
    id: flowId,
    display_name: data.displayName,
    description: data.description,
    visibility: data.visibility,
  });
}

export async function installFlow(
  data: FlowCreate,
  options: {
    template: string;
    skipSourceBlock?: boolean;
  },
): Promise<FlowInstallResponse> {
  return await apiRequest<FlowInstallResponse>(
    "v1/flows/install",
    "POST",
    undefined,
    {
      display_name: data.displayName,
      description: data.description,
      visibility: data.visibility,
      type: options.template,
      skip_source_block: !!options.skipSourceBlock,
    },
  );
}

export async function listFlows(
  offset: number = 0,
  limit: number = 50,
): Promise<ListFlowsResponse> {
  return await apiRequest<ListFlowsResponse>("v1/flows", "GET", {
    offset,
    limit,
  }).then((response) => {
    response.items = response.items.map(Flow.fromObject);
    return response;
  });
}

export async function deployDashboards(
  flowId?: string,
): Promise<ListFlowsResponse> {
  return await apiRequest<ListFlowsResponse>(
    `v1/flows/${flowId}/dashboards/deploy`,
    "POST",
  );
}

export async function deleteFlow(flowId: string): Promise<ListFlowsResponse> {
  return await apiRequest<ListFlowsResponse>(`v1/flows/${flowId}`, "DELETE");
}

export async function generateInsights(flowId: string): Promise<void> {
  return await apiRequest<void>(`v1/flows/${flowId}/generate_insights`, "POST");
}

export async function listViews(flowId: string): Promise<ListFlowsResponse> {
  return await apiRequest<ListFlowsResponse>(`v1/flows/${flowId}/views`, "GET");
}

export async function generateViews(flowId?: string): Promise<null> {
  return await apiRequest<null>(`v1/flows/${flowId}/views`, "POST");
}

export async function rerunFlow(flowId: string): Promise<null> {
  return await apiRequest<null>(`v1/flows/${flowId}/run`, "POST");
}

export async function UpdateFlow(flowId: string): Promise<any> {
  return await apiRequest<any>(`v1/flows/${flowId}/update-template`, "PUT");
}
