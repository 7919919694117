import { Box, Stack, Typography } from "@mui/material";
import { formatCurrencyOnlyNumber } from "../../../InsightsV2/SummaryV2.tsx";
import theme from "../../../../themes/light.ts";
import { Icon } from "../../../../components/Icon.tsx";

const NumberSection = ({
  title,
  number = 0,
  percentage = 0,
  change = 0,
  unit,
}: {
  title?: string;
  number?: number;
  percentage?: number;
  change?: number;
  unit?: "$" | "%";
}) => {
  return (
    <Stack>
      <Stack direction="row" gap="4px" alignItems="end">
        <Typography variant="headline3">
          {unit === "$" && "$"}
          {unit !== "%"
            ? formatCurrencyOnlyNumber(number)
            : `${number.toFixed(2)}%`}
        </Typography>
        <Box display="flex" gap="8px">
          <Box display="flex" gap="2px" alignItems="center">
            {percentage >= 0 ? (
              <Icon src="sales/trend-up.svg" height="16px" width="16px" />
            ) : (
              <Icon src="sales/trend-down.svg" height="16px" width="16px" />
            )}{" "}
            <Typography
              variant="body4"
              sx={{
                color:
                  percentage >= 0
                    ? theme.palette.text.success
                    : theme.palette.error.main,
              }}
            >
              {unit === "%"
                ? `${percentage?.toFixed(1).replaceAll(/\.0(0)?/g, "")}%`
                : formatCurrencyOnlyNumber(change)}
            </Typography>
          </Box>
        </Box>
      </Stack>
      {title && <Typography variant="body5">{title}</Typography>}
    </Stack>
  );
};

export default NumberSection;
