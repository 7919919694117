import Controller from "./Controller";
import Box from "@mui/material/Box";
import ContentEditor from "./ContentEditor";

const ContentArea = () => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "24px",
          height: "calc(100% - 110px)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0px",
            flexGrow: 1,
            padding: "12px 20px",
            background: "#F8F7FA",
            borderRadius: "12px",
          }}
        >
          <Controller />
          <ContentEditor />
        </Box>
        {/*<SuggestionsSection*/}
        {/*  highlightedKeywords={highlightedKeywords}*/}
        {/*  keywords={keywords}*/}
        {/*  isKeywordsLoading={isKeywordsLoading}*/}
        {/*/>*/}
      </Box>
      {/*<Box sx={{ width: "100%" }} display="flex" gap="24px">*/}
      {/*  <Box width="100%">*/}
      {/*    <Footer product={product} {...editorContentProps} state={state} />*/}
      {/*    <div>*/}
      {/*      <OriginalContentAccordion*/}
      {/*        isAllLoading={isAllLoading}*/}
      {/*        productOriginalContentPlainTextValue={*/}
      {/*          editorContentProps.productOriginalContentPlainTextValue*/}
      {/*        }*/}
      {/*        handleScroll={handleScroll}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  </Box>*/}
      {/*  <Box*/}
      {/*    sx={{*/}
      {/*      width: "100%",*/}
      {/*      maxWidth: "256px",*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</Box>*/}
      <span id="sppp"></span>
    </Box>
  );
};

export default ContentArea;
