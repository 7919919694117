import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import { useSalesAssistant } from "../SalesAssistantProvider.tsx";
import NumberSection from "../Components/Row/NumberSection.tsx";
import Chart from "react-apexcharts";
import dayjs from "dayjs";

const Index = () => {
  const { salesAssistantHeaderData } = useSalesAssistant();
  return (
    <Grid container spacing={2} marginBottom="10px">
      {salesAssistantHeaderData?.map((card, index) => (
        <Grid item xs={3} key={`sales_assistant_head_card-${index}`}>
          <Card
            sx={{
              flex: 1,
              minHeight: "110px",
              filter:
                "drop-shadow(0px 12px 45px rgba(44, 34, 76, 0.05)) drop-shadow(0px 8px 20px rgba(44, 34, 76, 0.05))",
              border: "0 none",
              borderRadius: "8px",
              padding: "5px 12px",
            }}
          >
            <Stack gap="5px">
              <Box>
                <Typography variant="headline3">{card.title}</Typography>
                <NumberSection
                  number={card.value}
                  change={card.change}
                  unit="$"
                />
              </Box>
              <Box flex={1} marginBottom="-25px">
                <Chart
                  options={{
                    chart: {
                      id: card.title,
                      toolbar: {
                        show: false,
                        tools: {
                          download: false,
                        },
                      },
                    },
                    grid: {
                      xaxis: {
                        lines: {
                          show: false,
                        },
                      },
                      yaxis: {
                        lines: {
                          show: false,
                        },
                      },
                    },
                    stroke: {
                      width: 2,
                      curve: "smooth",
                      colors: index % 2 === 0 ? ["#7F3CEE"] : ["#72CAF0"],
                    },
                    tooltip: {
                      enabled: false,
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    fill: {
                      colors: ["#efefef"],
                      type: "gradient",
                      gradient: {
                        shade: "light",
                        type: "vertical",
                        opacityFrom: 1,
                        opacityTo: 0.5,
                      },
                    },
                    xaxis: {
                      tickAmount: 4,
                      categories: card.categories,
                      labels: {
                        formatter: function (value) {
                          return dayjs(parseInt(value) / 1000).format("MMM");
                        },
                      },
                      axisTicks: {
                        show: false,
                      },
                    },
                    yaxis: {
                      labels: {
                        show: false,
                      },
                    },
                  }}
                  series={[
                    {
                      name: card.title,
                      data: card.graph_data,
                    },
                  ]}
                  type="area"
                />
              </Box>
            </Stack>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Index;
