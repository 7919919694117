import { Box, Link, Tooltip } from "@mui/material";
import theme from "../../../themes/light";
import { ArrowDown } from "../../../assets";

export const ratingRenderer = (p: any) => (
  <Box
    sx={{
      display: "inline-flex",
      gap: "0.3rem",
      alignItems: "center",
    }}
  >
    <Box>⭐️</Box>
    <Box>{p.value}</Box>
  </Box>
);

export const growthRenderer = (p: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "2px",
        "& svg": {
          transform: p.value > 0 ? "rotate(180deg)" : "none",
          "& path": {
            fill:
              p.value > 0
                ? theme.palette.text.success
                : theme.palette.error.main,
          },
        },
        color:
          p.value == 0
            ? theme.palette.secondary.main
            : p.value > 0
              ? theme.palette.text.success
              : theme.palette.error.main,
      }}
    >
      {p.value == 0 ? (
        <Box sx={{ marginLeft: "1.1rem" }}>0%</Box>
      ) : (
        <>
          {p?.value ? (
            <>
              <ArrowDown />
              {`${Number(100 * p.value).toLocaleString(undefined, {
                maximumFractionDigits: 1,
              })}%`}
            </>
          ) : (
            <Box
              sx={{
                marginLeft: "1.1rem",
                color: theme.palette.secondary.main,
              }}
            >
              -
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export const changeRenderer =
  (valueField: string, changeField: string) => (p: any) => {
    const value = p.data[valueField];
    const change = p.data[changeField];

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "2px",
          "& svg": {
            transform: change > 0 ? "rotate(180deg)" : "none",
            "& path": {
              fill: change > 0 ? theme.palette.text.success : theme.palette.error.main,
            },
          },
          color: change === 0 ? theme.palette.secondary.main : change > 0 ? theme.palette.text.success : theme.palette.error.main,
        }}
      >
        {change === 0 ? (
          <Box sx={{ marginLeft: "1.1rem" }}>{value}</Box>
        ) : (
          <>
            {change ? (
              <>
                <ArrowDown />
                {`${value} (${change})`}
              </>
            ) : (
              <Box sx={{ marginLeft: "1.1rem", color: theme.palette.secondary.main }}>
                -
              </Box>
            )}
          </>
        )}
      </Box>
    );
  };

export const productRenderer = (p: any) => {
  const { image_url, asin, title } = p.value;
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Tooltip
          placement="top-start"
          title={
            <Box
              component={"img"}
              src={image_url}
              alt="image"
              sx={{
                height: "130px",
                width: "100px",
                borderRadius: "4px",
              }}
            />
          }
        >
          <Box
            sx={{
              height: "30px",
              width: "30px",
              display: "flex",
              justifyContent: "center",
              border: "0.496px solid rgba(0, 0, 0, 0.10)",
              borderRadius: "4px",
            }}
          >
            <Box
              component={"img"}
              src={image_url}
              alt="image"
              sx={{
                height: "100%",
                width: "15px",
              }}
            />
          </Box>
        </Tooltip>
        {/* <Box>{asin}</Box> */}
        <Link
          underline={"hover"}
          target={"_blank"}
          href={asin ? `https://amazon.com/d/${asin}` : ""}
          sx={{
            color: theme.palette.secondary.main,
            cursor: "pointer",
            "&:hover": {
              color: theme.palette.primary.main,
            },
          }}
        >
          {title ? title : asin ? asin : "N/A"}
        </Link>
      </Box>
    </>
  );
};

export const brandedRenderer = (p: any) => (
  <Box
    sx={{
      background: p.value ? "#EEE5FE" : "#F5F5F5",
      borderRadius: "4px",
      lineHeight: "16px",
      fontWeight: "400",
      color: "#504C55",
      width: "fit-content",
      padding: "2px 8px",
      fontSize: "13px",
      marginTop: "10px",
    }}
  >
    {p.value || "unbranded"}
  </Box>
);

export const relevancyRenderer = (p: any) => {
  const relevancyColorMap: { [key: string]: string } = {
    "Very High": "#4CAF50",
    High: "#8BC34A",
    Medium: "#FFC107",
    Low: "#FF9800",
    "Very Low": "#F44336",
  };

  return (
    <Box
      sx={{
        color: relevancyColorMap[p.value],
        borderRadius: "100px",
        lineHeight: "16px",
        fontWeight: "500",
        width: "fit-content",
        padding: "0 8px",
        fontSize: p.value ? "14px" : "12px",
        margin: "10px 0 0 10px",
      }}
    >
      {p.value}
    </Box>
  );
};
