import theme from "../../themes/light.ts";
import ReactApexChart from "react-apexcharts";
import { Box } from "@mui/material";
import { convertNumberToShortFormat } from "./utils.ts";
import { renderToString } from "react-dom/server";
import "./AreaChart.css";

type GenericChartChartProps = {
  graph_data: {
    graph_type: string;
    data: any;
    series: { name: string; data: any[]; type: any }[];
    categories: string[];
    legend?: { [key: string]: string };
    graph_title?: string;
    axis?: { x: string; y: any[] };
  };
};
type ChartsTypes =
  | "area"
  | "line"
  | "bar"
  | "pie"
  | "donut"
  | "radialBar"
  | "scatter"
  | "bubble"
  | "heatmap"
  | "candlestick"
  | "boxPlot"
  | "radar"
  | "polarArea"
  | "rangeBar"
  | "rangeArea"
  | "treemap"
  | undefined;

const colorsByIdx = ["#45A2F8", "#8558EE", "#66cc99"];

const CustomTooltip = ({
  series,
  dataPointIndex,
  legend,
  categories,
  graphType,
}: any) => {
  return (
    <div className="tooltip-container">
      {series.map((serie: any, index: number) =>
        !serie ||
        convertNumberToShortFormat(
          serie[dataPointIndex],
          serie.type === "growth"
        ) === "NaN" ? (
          ""
        ) : (
          <div key={index} className={`tooltip-row`}>
            <span className={`tooltip-marker-${index + 1}`}></span>
            <span>
              {graphType === "bar" ? legend[index] : categories[dataPointIndex]}
            </span>
            <span className={`tooltip-value-${index + 1}`}>
              {convertNumberToShortFormat(
                serie[dataPointIndex],
                serie.type === "growth"
              )}
            </span>
          </div>
        )
      )}
      <div className="tooltip-arrow"></div>
    </div>
  );
};

const GenericChart = ({ graph_data }: GenericChartChartProps) => {
  const genericOptions = {
    chart: {
      type: graph_data.graph_type as unknown as ChartsTypes,
      height: 430,
      toolbar: {
        show: false,
        tools: {
          download: false,
        },
      },
      // stacked: false,
    },
    plotOptions:
      graph_data.graph_type === "bar"
        ? {
            bar: {
              horizontal: true,
              dataLabels: {
                position: "top",
                total: {
                  offsetX: -10,
                },
              },
              barHeight: "15px",
              borderRadius: 7,
            },
          }
        : {
            bar: {
              horizontal: false,
              columnWidth: "90%",
            },
          },
    dataLabels: {
      enabled: false,
      textAnchor: "start",
      offsetX: 25,
      offsetY: 1,
      style: {
        fontSize: "10px",
        colors: colorsByIdx,
      },
      formatter: (value: string | number) => {
        return convertNumberToShortFormat(value);
      },
    },
    stroke: {
      show: true,
      curve: Array.from({ length: graph_data.series.length }, () => "smooth"),
      width: Array.from({ length: graph_data.series.length }, () => 3),
      colors: colorsByIdx,
      dashArray: [...Array(graph_data.series.length).keys()].map((i) =>
        graph_data.graph_type !== "area" ? 0 : i * 5
      ),
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      showForNullSeries: false,
      position: "top",
      floating: true,
      horizontalAlign: "right",
      fontFamily: "Inter, Roboto, Helvetica, Arial, sans-serif",
      onItemClick: {
        toggleDataSeries: !!graph_data.series?.[1],
      },
      markers: {
        fillColors: ["#45A2F8", "unset"],
      },
      formatter: (value: string | number) => {
        return graph_data.legend?.[value] || value;
      },
      offsetY: -5,
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      followCursor: true,
      custom: ({ series, dataPointIndex }: any) => {
        const seriesWithTypes = series.map((serie: any, index: number) => {
          return {
            ...serie,
            type: graph_data.axis?.y[index]?.type,
          };
        });
        return renderToString(
          <CustomTooltip
            series={seriesWithTypes}
            dataPointIndex={dataPointIndex}
            categories={graph_data.categories}
            graphType={graph_data.graph_type}
            legend={Object.values(graph_data.legend || {})}
          />
        );
      },
    },
    fill: {
      gradient: {
        opacityFrom: 0.7,
        opacityTo: 0,
      },
    },
    xaxis: {
      title: {
        // text: graph_data.axis.x || "Date",
        text: "",
      },
      tickAmount: Math.min(graph_data.categories?.length, 26),
      axisTicks: {
        text: "Your X-Axis Title Here",
        formatter: (value: string | number) => {
          const formatted = convertNumberToShortFormat(value);
          return formatted === "NaN" ? value : formatted;
        },
      },
      categories: graph_data.categories,
      labels: {
        rotate: -30,
        rotateAlways: graph_data.categories.length > 12,
        style: {
          colors: "#2C224C",
          fontSize: "10px",
          fontWeight: 500,
          fontFamily: "Inter, Roboto, Helvetica, Arial, sans-serif",
        },
        formatter: (value: string | number) => {
          const formatted = convertNumberToShortFormat(value);
          return formatted === "NaN" ? value : formatted;
        },
      },
      axisBorder: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    colors: colorsByIdx,
  };

  let uniqueYAxis = [
    ...new Set(graph_data.axis?.y.map((axis: any) => axis.label)),
  ];

  const options = graph_data.axis
    ? {
        ...genericOptions,
        yaxis: graph_data.axis.y.map((axis: any, index: number) => {
          const labelRemoved = !uniqueYAxis.includes(axis.label);
          uniqueYAxis = uniqueYAxis.filter((item) => item !== axis.label);

          return {
            opposite: index !== 0,
            seriesName: !labelRemoved
              ? axis.series
              : graph_data.axis?.y[index - 1]?.series,
            title: {
              // text: axis.label,
              rotate: 0,
            },
            labels: labelRemoved
              ? { show: false }
              : {
                  show: true,
                  maxWidth: 230,
                  style: {
                    colors: "#2C224C",
                    fontSize: "10px",
                    fontWeight: 500,
                    fontFamily: "Inter, Roboto, Helvetica, Arial, sans-serif",
                  },
                  formatter: (value: string | number) => {
                    const formatted = convertNumberToShortFormat(
                      value,
                      axis.type === "growth"
                    );
                    if (formatted === "Nan") {
                      return value;
                    }
                    return !axis.type
                      ? formatted
                      : axis.type === "currency"
                      ? `$${formatted}`
                      : axis.type === "growth"
                      ? formatted
                      : value;
                  },
                },
            axisBorder: {
              show: false,
            },
          };
        }),
      }
    : genericOptions;

  return (
    <Box
      id="area-chart"
      sx={{
        // height: "402px",
        background: theme.palette.grey[25],
        borderRadius: "12px",
        padding: "17px 20px 0px 20px",
      }}
    >
      <ReactApexChart
        // @ts-ignore
        options={options}
        series={graph_data.series}
        type={graph_data.graph_type as unknown as ChartsTypes}
        height={430}
      />
    </Box>
  );
};

export default GenericChart;
