import { createContext, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import {
  useChatQuestionList,
  addStoryPointToStoryboardApi,
  getOptionsData,
  getStoryboardApi,
  useChatSessionsList,
  updateStoryboardApi,
  deleteStoryPointApi,
  updateStoryPointApi,
  deleteStoryboardApi,
  createStoryboardApi,
  syncStoryboardApi,
  copyStoryboardApi,
  getStoryPointApi,
  exportStoryPointApi,
} from "./useChatApi";
// import {
//   // checkTime,
//   // findGraphDataLastStoryPoint,
//   getStoryBoardGroupData,
// } from "./hooks/selectors";
import { useHistory } from "react-router-dom";
import { useQueryParams } from "../../hooks";
import { useSnackbar } from "notistack";
import { v4 } from "uuid";
import { useSpacesQuery } from "../SpacesV2/useSpaces";
import { Space } from "../SpacesV2/types";
import { ChatTemplate } from "./types";
import { formatChatTemplate } from "./utils";
// import { setgid } from "process";

export const insightsChatContext = createContext<any>({});
export const InsightChatProvider = ({ children }: { children: ReactNode }) => {
  const snackbar = useSnackbar();
  const [digitalShelfName, setDigitalShelfName] = useState<string>("");
  const [digitalShelfShortId, setDigitalShelfShortId] = useState<string>("");
  const [categoryList, setCategoryList] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const loaderRef = useRef<any>(null);
  const [selectedSpace, setSelectedSpace] = useState<Space | any>();
  const [toggle, setToggle] = useState(true);
  const [graphData, setGraphData] = useState<any>({});
  const graphDataRef = useRef<any>({});
  const [addQuestionLoader, setAddQuestionLoader] = useState<boolean>(false);

  // Story boards list
  const { data: fetchedStoryboardList, refetch: refetchStoryboardList } =
    useChatSessionsList();
  const [storyboardList, setStoryboardList] = useState<any[] | undefined>(
    fetchedStoryboardList
  );
  const [storyboardListPending, setStoryboardListPending] =
    useState<boolean>(false);

  // Query params
  const queryParams = useQueryParams();
  const storyboardId = queryParams.get("storyboardId") || "";
  const spaceId = queryParams.get("spaceId") || "";
  const chatTemplate = queryParams.get("chatTemplate") || "";

  // Questions list
  const { data: questionList } = useChatQuestionList();

  const filteredQuestionList = useMemo(() => {
    if (!questionList) return [];
    return !chatTemplate
    ? questionList?.filter((q: any) => q.id != "simple-text")
    : questionList?.filter((q: any) => q.tags.includes(chatTemplate));
  }, [questionList, chatTemplate]);

  const history = useHistory();

  const {
    data: fetchedSpacesList,
    isLoading: spacesListLoading,
    isFetching: spacesListFetching,
    error: spacesListError,
    refetch: spacesListRefetch,
  } = useSpacesQuery();

  const [spacesList, setSpacesList] = useState([
    { id: "", name: "All Products" },
    ...(fetchedSpacesList || []),
  ]);

  useEffect(() => {
    if (fetchedSpacesList) {
      setSpacesList([{ id: "", name: "All Products" }, ...fetchedSpacesList]);
    }
  }, [fetchedSpacesList]);

  const getOptionsUrlData = async (url: string, type: string) => {
    try {
      setLoading(true);
      const dataList = await getOptionsData(url, "");
      if (type === "catgory") {
        setCategoryList(dataList);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  // Scroll to the loader
  useEffect(() => {
    if (addQuestionLoader) {
      loaderRef.current?.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }, [addQuestionLoader]);

  useEffect(() => {
    if (fetchedStoryboardList) {
      setStoryboardList(fetchedStoryboardList);
    }
  }, [fetchedStoryboardList]);

  useEffect(() => {
    // Initial fetch of session data (e.g. after page refresh or when choosing a session from the list)
    if (storyboardId && (!graphData || graphData.id != storyboardId)) {
      fetchSessionData(storyboardId);
    }
  }, [storyboardId]);

  // fetch => setGraph
  const fetchSessionData = async (id: string) =>
    await getStoryboardApi(id)
      .then(async (res: any) => {
        updateSessionData(res);
        await refetchStoryboardList();
      })
      .catch(() => {
        snackbar.enqueueSnackbar("Failed to fetch story board", {
          variant: "error",
        });
      });

  // setGraph
  const updateSessionData = async (
    newGraphData: any,
    animate: boolean = false
  ) => {
    if (!graphDataRef.current) {
      graphDataRef.current = newGraphData;
      setGraphData(graphDataRef.current);
      return;
    }

    const previousStoryPoints = graphDataRef.current.story_points;

    let updatedStoryPoints = newGraphData.story_points
      .sort((a: any, b: any) => a.order - b.order) // Sort by order
      .map((storyPoint: any) => {
        // Animation mapping
        const previousStoryPoint = previousStoryPoints?.find(
          (prevStoryPoint: any) => prevStoryPoint.id === storyPoint.id
        );
        if (
          storyPoint.type !== "simple-text" &&
          (!previousStoryPoint || animate)
        ) {
          return { ...storyPoint, showAnimation: true };
        }
        return storyPoint;
      });

    if (previousStoryPoints?.some((storyPoint: any) => storyPoint.isLoader)) {
      // Create a map to keep track of the loaders' original indices
      const loadersIndicesMap = updatedStoryPoints.reduce(
        (acc: any, storyPoint: any, index: number) => {
          if (storyPoint.isLoader) {
            acc[storyPoint.id] = index;
          }
          return acc;
        },
        {}
      );

      // Add loaders from previous story points to updated story points array in their original indices
      updatedStoryPoints = previousStoryPoints?.reduce(
        (acc: any, storyPoint: any) => {
          if (storyPoint.isLoader) {
            const index = loadersIndicesMap[storyPoint.id];
            acc.splice(index, 0, storyPoint);
          }
          return acc;
        },
        updatedStoryPoints
      );
    }

    graphDataRef.current = {
      ...newGraphData,
      story_points: updatedStoryPoints,
    };
    setGraphData(graphDataRef.current);
  };

  const setStoryPoint = async (storyPoint: any) => {
    const updatedStoryPoints = graphDataRef.current.story_points.map(
      (sp: any) => {
        if (sp.id === storyPoint.id) {
          return storyPoint;
        }
        return sp;
      }
    );
    graphDataRef.current = {
      ...graphDataRef.current,
      story_points: updatedStoryPoints,
    };
    setGraphData(graphDataRef.current);
  };

  const setSession = (id: string) => {
    if (storyboardId === id) {
      return;
    }

    // Search chat template by story points
    const selectedStoryboard = storyboardList?.find(
      (storyboard) => storyboard.id === id
    );
    const chatTemplate = [
      "executive_summary",
      "sales_report",
      "keywords_opportunities",
    ].find((template) =>
      selectedStoryboard?.story_points.some(
        (storyPoint: any) => storyPoint.type === template
      )
    );
    history.push(
      `/chat?chatTemplate=${chatTemplate || "executive_summary"}&spaceId=${
        selectedSpace?.id
      }&storyboardId=${id}`
    );
  };

  useEffect(() => {
    if (Array.isArray(spacesList)) {
      if (spaceId) {
        const selectedSpace = spacesList.find((space) => space.id === spaceId);
        if (selectedSpace) {
          setSelectedSpace({ ...selectedSpace });
        } else {
          setSelectedSpace(spacesList[0]);
        }
      } else {
        setSelectedSpace(spacesList[0]);
      }
    }
  }, [spacesList?.length, spaceId]);

  const setLoaderInGraphData = (index: number | null) => {
    const loaderStoryPoint = { id: v4(), isLoader: true };
    const storyPoints = graphDataRef.current?.story_points;
    const storyPointsWithLoader = !storyPoints
      ? [loaderStoryPoint]
      : index !== null
        ? [
            ...storyPoints.slice(0, index),
            loaderStoryPoint,
            ...storyPoints.slice(index),
          ]
        : [...storyPoints, loaderStoryPoint];
    graphDataRef.current = {
      ...graphDataRef.current,
      story_points: storyPointsWithLoader,
    };
    setGraphData(graphDataRef.current);
    setAddQuestionLoader(true);
  };

  const removeLoaderFromGraphData = (index: number | null = null) => {
    const updatedArray = !index
      ? graphDataRef.current?.story_points?.filter(
          (storyPoint: any) => !storyPoint.isLoader
        )
      : graphDataRef.current?.story_points?.filter(
          (_storyPoint: any, i: number) => i !== index
        );

    graphDataRef.current = {
      ...graphDataRef.current,
      story_points: updatedArray,
    };
    setGraphData(graphDataRef.current);
    setAddQuestionLoader(false);
  };

  const deleteStoryPoint = async (storyID: string) => {
    // UI Optimization
    graphDataRef.current = {
      ...graphDataRef.current,
      story_points: graphDataRef.current.story_points?.map(
        (storyPoint: any) => {
          if (storyPoint.id === storyID) {
            return { ...storyPoint, isDeleting: true };
          }
          return storyPoint;
        }
      ),
    };
    setGraphData(graphDataRef.current);

    // API call
    setTimeout(
      async () =>
        await deleteStoryPointApi(storyID)
          .then((res: any) => updateSessionData(res))
          .catch(() => {
            snackbar.enqueueSnackbar("Failed to delete story point", {
              variant: "error",
            });

            // Rollback UI changes
            graphDataRef.current = {
              ...graphDataRef.current,
              story_points: graphDataRef.current.story_points?.map(
                (storyPoint: any) => {
                  if (storyPoint.id === storyID) {
                    return { ...storyPoint, isDeleting: false };
                  }
                  return storyPoint;
                }
              ),
            };

            setGraphData(graphDataRef.current);
          }),
      500
    );
  };

  const deleteStoryboard = async (sId: string) => {
    // UI Optimization
    setStoryboardList(
      storyboardList?.filter((storyboard) => storyboard.id !== sId)
    );
    return await deleteStoryboardApi(sId)
      .then(async (res: any) => {
        if (res?.id === storyboardId) {
          await emptyCurrentSession();
        } else {
          await refetchStoryboardList();
        }
      })
      .catch(() => {
        snackbar.enqueueSnackbar("Failed to delete story point", {
          variant: "error",
        });
        graphDataRef.current = {
          ...graphDataRef.current,
          story_points: graphDataRef.current.story_points?.map(
            (storyPoint: any) => {
              if (storyPoint.id === sId) {
                return { ...storyPoint, isLoader: false };
              }
              return storyPoint;
            }
          ),
        };
        setGraphData(graphDataRef.current);
      });
  };

  const addStoryPointToStoryboard = async (
    storyPointType: string,
    settingsParams: any = [],
    index: number | null
  ) => {
    storyPointType !== "simple-text" && setLoaderInGraphData(index);

    const paramsWithSpaceId = settingsParams.find(
      (param: any) => param.id === "space_id"
    )
      ? settingsParams
      : [...settingsParams, { id: "space_id", value: selectedSpace?.id }];

    return await addStoryPointToStoryboardApi(
      storyPointType,
      storyboardId,
      paramsWithSpaceId,
      index
    )
      .then((res: any) => updateSessionData(res))
      .catch((e) => {
        if (e.message.includes("There is no data for this story point")) {
          snackbar.enqueueSnackbar(
            "The question returned no data for this space",
            {
              variant: "warning",
            }
          );
        } else {
          snackbar.enqueueSnackbar("Unable to create new story board", {
            variant: "error",
          });
        }
      })
      .finally(() => {
        storyPointType !== "simple-text" && removeLoaderFromGraphData();
      });
  };

  const startNewSession = async (template: ChatTemplate) => {
    setStoryboardListPending(true);

    await createStoryboardApi(
      `${selectedSpace?.name} ${formatChatTemplate(template)}`
    )
      .then(async (res) => {
        if (res.id) {
          history.push(
            `/chat?chatTemplate=${template}&spaceId=${selectedSpace?.id}&storyboardId=${res.id}`
          );
          updateSessionData(res);
          await refetchStoryboardList();
        }
      })
      .catch(() =>
        snackbar.enqueueSnackbar("Failed to create new story board", {
          variant: "error",
        })
      )
      .finally(() => setStoryboardListPending(false));
  };
  const getStoryPoint = async (storyPointId: string) => {
    return await getStoryPointApi(storyPointId)
      .then((res: any) => {
        setStoryPoint(res);
      })
      .catch(() => {
        snackbar.enqueueSnackbar("Failed to get story point", {
          variant: "error",
        });
        throw new Error("Failed to get story point");
      });
  };

  const updateStoryPoint = async (storyPointId: string, body: any) => {
    return await updateStoryPointApi(storyPointId, body)
      .then((res: any) => setStoryPoint(res))
      .catch(() => {
        snackbar.enqueueSnackbar("Failed to update story point", {
          variant: "error",
        });
        throw new Error("Failed to update story point");
      });
  };

  const exportStoryPoint = async (storyPointId: string) =>
    await exportStoryPointApi(storyPointId)
      .then((res: any) => {
        setStoryPoint(res);
        return res;
      })
      .catch(() => {
        snackbar.enqueueSnackbar("Failed to export story point", {
          variant: "error",
        });
        throw new Error("Failed to export story point");
      });

  const updateStoryboard = async (storyboardId: string, body: any) => {
    return await updateStoryboardApi(body, storyboardId)
      .then(async (res: any) => {
        updateSessionData(res);
        await refetchStoryboardList();
      })
      .catch(() =>
        snackbar.enqueueSnackbar("Failed to update story point", {
          variant: "error",
        })
      );
  };

  const emptyCurrentSession = async () => {
    history.push(`/chat?spaceId=${selectedSpace?.id}`);
    setAddQuestionLoader(false);

    const currentStoryboardId = storyboardId;

    // If the current session is empty, delete it
    if (graphData.story_points.length === 0) {
      // Delete from storyborad list
      setStoryboardList(
        storyboardList?.filter(
          (storyboard) => storyboard.id !== currentStoryboardId
        )
      );

      await deleteStoryboardApi(currentStoryboardId)
        .then(async () => await refetchStoryboardList())
        .catch(() => {
          // Rollback UI changes
          setStoryboardList(
            storyboardList?.map((storyboard) => {
              if (storyboard.id === currentStoryboardId) {
                return { ...storyboard, isLoader: false };
              }
              return storyboard;
            })
          );
        });
    }

    graphDataRef.current = {};
    setGraphData(graphDataRef.current);
  };

  const syncStoryboard = async (storyboardId: string) =>
    await syncStoryboardApi(storyboardId)
      .then((res: any) => updateSessionData(res, true))
      .catch(() => {
        snackbar.enqueueSnackbar("Failed to sync story board", {
          variant: "error",
        });
        throw new Error("Failed to sync story board");
      });

  const copyStoryboard = async (storyboardId: string) =>
    await copyStoryboardApi(storyboardId)
      .then((res: any) => setSession(res.id))
      .catch(() => {
        snackbar.enqueueSnackbar("Failed to copy story board", {
          variant: "error",
        });
        throw new Error("Failed to copy story board");
      });

  const state = {
    digitalShelfName,
    setDigitalShelfName,
    digitalShelfShortId,
    setDigitalShelfShortId,
    filteredQuestionList,
    getOptionsUrlData,
    setCategoryList,
    categoryList,
    loading,
    graphData: graphDataRef.current,
    setGraphData,
    fetchSessionData,
    storyboardList,
    setLoading,
    spaceId,
    spacesList,
    spacesListLoading,
    spacesListFetching,
    spacesListError,
    spacesListRefetch,
    selectedSpace,
    setSelectedSpace,
    toggle,
    setToggle,
    addQuestionLoader,
    setLoaderInGraphData,
    removeLoaderFromGraphData,
    loaderRef,
    deleteStoryPoint,
    addStoryPointToStoryboard,
    startNewSession,
    emptyCurrentSession,
    storyboardListPending,
    refetchStoryboardList,
    setAddQuestionLoader,
    getStoryPoint,
    updateStoryPoint,
    exportStoryPoint,
    updateStoryboard,
    deleteStoryboard,
    chatTemplate,
    setSession,
    storyboardId,
    syncStoryboard,
    copyStoryboard,
  };
  return (
    <insightsChatContext.Provider value={{ ...state }}>
      {children}
    </insightsChatContext.Provider>
  );
};
