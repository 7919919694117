import * as React from "react";
import { BaseRange, BaseText, Transforms } from "slate";
import { HistoryEditor } from "slate-history";
import { ReactEditor, RenderLeafProps } from "slate-react";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box } from "@mui/material";
import { Keyword } from "../../../types.ts";

export interface CustomLeafProperties extends Partial<Keyword> {
  range?: BaseRange;
  isHighlighted?: boolean;
  isPreviousCharWhitespace?: boolean;
}

export interface WordProps extends RenderLeafProps {
  leaf: BaseText & CustomLeafProperties;
  editor: ReactEditor & HistoryEditor;
  contentHighlightedKeyWords: Keyword[];
}

const Word: React.FC<WordProps> = ({ children, attributes, editor, leaf }) => {
  const onRemoveHighlightedWord = React.useCallback(() => {
    const range = { ...leaf.range } as typeof leaf.range;

    if (range?.anchor && leaf.isPreviousCharWhitespace) {
      range.anchor.offset -= 1; // Remove the white space in the previous character of the word
    }

    Transforms.delete(editor, {
      at: range as BaseRange,
    });
  }, [editor, leaf]);

  if (!leaf.isHighlighted)
    return (
      <span
        style={{
          fontSize: " 16px",
          fontWeight: "400",
          lineHeight: "32px",
          letterSpacing: " -0.01em",
          color: "#2C224C",
          display: "inline",
        }}
        {...attributes}
      >
        {children}
      </span>
    );

  return (
    <>
      <Box
        component="span"
        sx={{
          display: "inline",
          color: "#0062CC",
          fontSize: " 16px",
          fontWeight: "400",
          lineHeight: "32px",
          position: "relative",
          zIndex: 4,
          cursor: "default",
          "&:hover svg": {
            display: "initial",
            opacity: "1",
          },
          "& span": {
            display: "inline-block",
          },
        }}
        {...attributes}
      >
        <CancelIcon
          sx={{
            opacity: "0",
            display: "none",
            position: "absolute",
            width: "18px !important",
            height: "18px !important",
            top: -14,
            right: -10,
            zIndex: 5,
            cursor: "pointer",
            "&:hover ": {
              opacity: "1",
              fill: "#053A73",
            },
          }}
          onClick={onRemoveHighlightedWord}
        />
        {children}
      </Box>
    </>
  );
};

export default Word;
