import { Box, Link, Stack, Typography } from '@mui/material'
import { EmptyKeywordStateLogo } from '../../assets'
import theme from '../../themes/light'

const CustomNoRowsOverlay = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "calc(100vh - 315px)",
        paddingBottom: "6rem"
      }}>
      <Box
        sx={{
          display: "inline-flex"
        }}>
        <EmptyKeywordStateLogo />
      </Box>
      <Stack spacing={3}>
        <Typography variant="body4" fontSize={18} textAlign="center">
          Zero Ranking Detected
        </Typography>
        <Stack spacing={0.3}>
          <Typography variant="body4" fontSize={16} textAlign="center" fontWeight={400}>
            The selected products did not rank on the first page for any keywords.
          </Typography>
          <Link target="_blank" rel="noopener" sx={{marginTop: "2rem !important"}} underline='none' color={theme.palette.primary.main} fontWeight={600} display="inline" href="https://noogata.com/blog/amazon-keywords-tips/">Review Keyword Strategy</Link>
        </Stack>
      </Stack>
    </Box>
  )
}

export default CustomNoRowsOverlay