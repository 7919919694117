import { useHistory, useRouteMatch } from "react-router";

import { LeftNavBarItem } from "./LeftNavBarItem";
import { FC } from "react";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { SPACES_ROUTE } from "../Router";
import theme from "../../../themes/light";

export const SpacesV2NavItem: FC = () => {
  const pageMatch = useRouteMatch({ path: [SPACES_ROUTE] });
  const history = useHistory();
  return (
    <LeftNavBarItem
      selected={!!pageMatch}
      icon={<StorefrontIcon sx={{ color: theme.palette.backgrounds.white }} />} // Render the icon as a component
      onClick={() => history.push(SPACES_ROUTE)}
      label="Spaces"
    />
  );
};
