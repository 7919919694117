import { useSalesAssistant } from "../SalesAssistantProvider.tsx";
import NoogataButtonGroup from "../../../components/ButtonGroup.tsx";
import { IconButton, Stack } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { generateSalesAssistant } from "../api.ts";
import { useSnackbar } from "notistack";
import { useMutation } from "react-query";
import { useState } from "react";
import { useUserHasNoogataAdminRole } from "../../../services/auth.ts";

const FiltersAndSort = () => {
  const [isFetching, setIsFetching] = useState(false);
  const { daysToShow, setDaysToShow } = useSalesAssistant();
  const { enqueueSnackbar } = useSnackbar();
  const generateSalesAssistantMutation = useMutation(() =>
    generateSalesAssistant(),
  );
  const isNoogataAdmin = useUserHasNoogataAdminRole();

  return (
    <Stack direction="row" gap="16px" justifyContent="end" alignItems="center">
      <NoogataButtonGroup
        value={daysToShow}
        onChange={setDaysToShow}
        buttons={[
          { value: "7d", text: "7d" },
          { value: "28d", text: "28d" },
        ]}
      />
      {isNoogataAdmin && (
        <IconButton
          sx={{
            "@keyframes rotate": {
              "0%": {
                transform: "rotate(0deg)",
              },
              "50%": {
                transform: "rotate(180deg)",
              },
              "100%": {
                transform: "rotate(360deg)",
              },
            },
            animation: isFetching ? "rotate 2s ease infinite" : "",
          }}
          disabled={isFetching}
          aria-label="refresh"
          onClick={() => {
            setIsFetching(true);
            generateSalesAssistantMutation
              .mutateAsync()
              .then(() => {
                enqueueSnackbar("Sales assistant update finished succesfully", {
                  variant: "success",
                });
                setIsFetching(false);
              })
              .catch((e: any) => {
                console.warn(e);
                enqueueSnackbar("Could not fetch sales assistant", {
                  variant: "error",
                });
                setIsFetching(false);
              });
          }}
        >
          <RefreshIcon />
        </IconButton>
      )}
    </Stack>
  );
};

export default FiltersAndSort;
