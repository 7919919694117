import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { SpaceState } from "./types.ts";
import {
  useAllSpaceAsinsQuery,
  // addAsinsAPI,
  useSpaceASINsQuery,
  // useSpaceKeywordsQuery,
  // useSpaceQuery,
  useSpacesQuery,
} from "./useSpaces.ts";
import { useLocation, useRouteMatch } from "react-router-dom";
// import { Button } from "@mui/material";
// import theme from "../../themes/light.ts";
// import { useSnackbar } from "notistack";
// import { useMutation } from "react-query";
import { SPACES_ROUTE } from "../../pages/Applications/Router";

export const spaceContext = createContext<SpaceState>({} as SpaceState);

export const useSpace = () => useContext(spaceContext);

export const SpaceProvider = ({ children }: { children: React.ReactNode }) => {
  const match = useRouteMatch(`${SPACES_ROUTE}/:spaceId`);
  const location = useLocation();
  let spaceId: string = "";
  if (match) {
    spaceId = (match.params as { spaceId?: string }).spaceId as string;
  }

  // console.log(spaceId,"spaceIdssssssssssss");

  // const snackbar = useSnackbar();
  const [openKeywordsTable, setOpenKeywordsTable] = useState<boolean>(false);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [contains, setContains] = useState(true);
  const [isSuggestedTab, setIsSuggestedTab] = useState<boolean>(false);
  const [filterByLabel, setFilterByLabel] = useState("all_product");
  const [filterTextAfterDebounce, setFilterTextAfterDebounce] = useState("");
  const [view, setView] = useState<string>("grid");
  const [isArrayEmpty, setIsArrayEmpty] = useState(false);
  // const [keywordsSearchAfterDebounce, setKeywordsSearchAfterDebounce] = useState("");
  const [sort, setSort] = useState({
    column: "bought_past_month",
    ascending: false,
  });

  const [sortKeywordsBy, setSortKeywordsBy] = useState({
    sort: "asc",
    colId: "keyword",
  });

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  // const addAsinsMutation = useMutation(
  //   ({ spaceId, asins }: { spaceId: string; asins: string[] }) =>
  //     addAsinsAPI({ spaceId, asins })
  // );

  const {
    data: spacesList,
    isLoading: spacesListLoading,
    isFetching: spacesListFetching,
    error: spacesListError,
    refetch: spacesListRefetch,
  } = useSpacesQuery();

  const {
    data: allSpaceAsins,
    // isLoading: allSpaceAsinsLoading,
    // isFetching: allSpaceAsinsFetching,
    // error: allSpaceAsinsError,
    // refetch: allSpaceAsinsRefetch,
  } = useAllSpaceAsinsQuery({
    spaceId,
    filterByLabel,
    filterByText: filterTextAfterDebounce,
    filterByContains: contains,
  });

  useEffect(() => {
    if (filterByLabel === "suggested_asins") {
      setIsSuggestedTab(true);
    } else {
      setIsSuggestedTab(false);
    }
  }, [filterByLabel]);

  useEffect(() => {
    setSelectedProducts([]);
  }, [isSuggestedTab]);

  // const {
  //   data: keywordsList,
  //   // isLoading: keywordsListLoading,
  //   // isFetching: keywordsListFetching,
  //   // error: keywordsListError,
  //   // refetch: keywordsListRefetch,
  // } = useSpaceKeywordsQuery({
  //   spaceId,
  //   // filterByText: keywordsSearchAfterDebounce
  // });

  // const {
  //   data: spaceData,
  //   isLoading: isSpaceDataLoading,
  //   refetch: spaceDataRefetch,
  // } = useSpaceQuery(spaceId);

  useEffect(() => {
    if (location?.pathname) {
      setOpenKeywordsTable(false);
    }
  }, [location?.pathname]);

  const {
    data: asinsList,
    fetchNextPage,
    hasNextPage,
    isFetching: isFetchingAsins,
    isFetchingNextPage,
    refetch: refatchAsins,
  } = useSpaceASINsQuery({
    spaceId,
    filterByLabel,
    ...(view === "grid" && {
      filterByText: filterTextAfterDebounce,
    }),
    ...(filterTextAfterDebounce &&
      view === "grid" && {
        filterByContains: contains,
      }),
    ...(sort.column && {
      sortBy: `${sort.ascending ? "" : "-"}${sort.column}`,
    }),
  });
  useEffect(() => {
    const isEmpty = asinsList?.pages?.some(
      (page: any) => page?.asins?.length === 0
    );
    setIsArrayEmpty(isEmpty || false);
  }, [asinsList]);

  // useEffect(() => {
  //   if (filterTextAfterDebounce) {
  //     spaceDataRefetch();
  //   }
  // }, [filterTextAfterDebounce, contains]);

  const toggleProductSelection = useCallback(
    (asin: string, action?: string) => {
      if (action === "toggle") {
        setSelectedProducts((prevSelectedProducts) =>
          prevSelectedProducts.includes(asin)
            ? prevSelectedProducts.filter((el) => el !== asin)
            : [...prevSelectedProducts, asin]
        );
      } else {
        setSelectedProducts((prevSelectedProducts) =>
          prevSelectedProducts.includes(asin)
            ? prevSelectedProducts.filter((el) => el !== asin)
            : [...prevSelectedProducts]
        );
      }
    },
    []
  );

  // const handleUndoAsins = (snackbarId: SnackbarKey, asins: string[]) => {
  //   addAsinsMutation
  //     .mutateAsync({ spaceId: spaceId as string, asins })
  //     .then(async () => {
  //       snackbar.enqueueSnackbar(
  //         asins?.length === 1
  //           ? "ASIN was added successfully"
  //           : "ASINs were added successfully",
  //         {
  //           variant: "success",
  //         }
  //       );
  //       spaceDataRefetch();
  //       await refatchAsins();
  //       closeSnackbar(snackbarId);
  //     })
  //     .catch((e) => {
  //       snackbar.enqueueSnackbar("Something went wrong: " + e, {
  //         variant: "error",
  //       });
  //     });
  // };

  // const undoAction = (snackbarId: SnackbarKey, asins: string[]) => (
  //   <>
  //     <Button
  //       sx={{
  //         color: theme.palette.primary.contrastText,
  //         padding: "5px 10px",
  //         borderRadius: "4px",
  //         background: theme.palette.secondary.main,
  //         ":hover": {
  //           background: theme.palette.secondary.main,
  //         },
  //       }}
  //       onClick={() => handleUndoAsins(snackbarId, asins)}
  //     >
  //       Undo
  //     </Button>
  //   </>
  // );

  const value = {
    selectedProducts,
    setSelectedProducts,
    toggleProductSelection,
    spacesList: spacesList,
    spacesListLoading: spacesListLoading,
    spacesListFetching: spacesListFetching,
    spacesListError: spacesListError,
    spacesListRefetch: spacesListRefetch,
    // spaceData,
    // spaceDataRefetch,
    // isSpaceDataLoading,
    asinsList,
    allSpaceAsins,
    fetchNextPage,
    isFetchingAsins,
    hasNextPage,
    isFetchingNextPage,
    refatchAsins,
    isDrawerOpen: isDrawerOpen,
    setIsDrawerOpen: setIsDrawerOpen,
    setFilterTextAfterDebounce,
    setFilterByLabel,
    filterByLabel,
    openKeywordsTable,
    setOpenKeywordsTable,
    // keywordsList,
    sort,
    setSort,
    contains,
    setContains,
    filterTextAfterDebounce,
    // setKeywordsSearchAfterDebounce,
    sortKeywordsBy,
    setSortKeywordsBy,
    isSuggestedTab,
    view,
    setView,
    isArrayEmpty,
    setIsArrayEmpty,
  };
  return (
    <spaceContext.Provider value={value}>{children}</spaceContext.Provider>
  );
};
