import { FC} from "react";
import { useTenantsState } from "@frontegg/react";
import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { useSalesContext, useDisplayList } from "../hooks/selectors";
import { useLocation } from "react-router-dom";
import SalesFilterComponent from "./SalesFilterComponent";

interface SalesGroupListingHeadProps {
  onTabChange?: (event: React.SyntheticEvent, newValue: string) => void;
  activeTab?: string;
}

export const SalesListingHead: FC<SalesGroupListingHeadProps> = (props) => {
  const { onTabChange, activeTab } = props;
  const displayList = useDisplayList();

  const theme = useTheme();
  const location = useLocation();
  const {
    sortDays,
    sort,
    setSort,
    selectedProductGroup,
    producGroupData,
    groupSearch,
  } = useSalesContext();
  const { activeTenant } = useTenantsState();
  const isGroupPage = location.pathname === "/sales-assistant/home";

  const handleSortFilter = (key: string, value: string | boolean) => {
    const data = {
      ...sort,
      [key]: value,
      isDirty: true,
      days: sortDays,
    };
    const sortFilterData = {
      data: data,
      tenentID: activeTenant?.id,
    };
    setSort(data);
    localStorage.setItem("sortFilter", JSON.stringify(sortFilterData));
  };

  return (
    <Box sx={{ background: theme.palette.backgrounds.secondary, paddingX: 4 }}>
      <Stack
        direction="row"
        padding={isGroupPage ? "0px 20px" : "0px 20px 12px"}
        // maxHeight="36px"
        alignItems="center">
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          sx={{ color: "#75717B", position: "relative" }}
          alignItems="center">
          <Box display="flex" gap="16px" alignItems="center" width={"40%"}>
            {isGroupPage ? (
              <Tabs
                value={activeTab}
                onChange={onTabChange}
                aria-label="basic tabs example">
                <Tab
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}>
                      Groups
                      <Box
                        sx={{
                          padding: "2px 7px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor:
                            activeTab === "groups"
                              ? theme.palette.primary.light
                              : theme.palette.borders.lines,
                          borderRadius: "30px",
                          color:
                            activeTab === "groups"
                              ? theme.palette.primary.dark
                              : theme.palette.grey[400],
                        }}>
                        {producGroupData?.length > 0
                          ? producGroupData?.length - 1
                          : 0}
                      </Box>
                    </Box>
                  }
                  value="groups"
                />
                <Tab
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}>
                      All Products
                      <Box
                        sx={{
                          padding: "1px 5px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor:
                            activeTab === "all-products"
                              ? theme.palette.primary.light
                              : theme.palette.borders.lines,
                          borderRadius: "30px",
                          color:
                            activeTab === "all-products"
                              ? theme.palette.primary.dark
                              : theme.palette.grey[400],
                        }}>
                        {/* {selectedProductGroup?.asins || ""} */}
                        {groupSearch
                          ? displayList.length ||
                          selectedProductGroup?.asins ||
                            0
                          : selectedProductGroup?.asins ||
                            displayList.length ||
                            0}
                      </Box>
                    </Box>
                  }
                  value="all-products"
                />
              </Tabs>
            ) : (
              <Typography variant="caption" sx={{ fontWeight: 500 }}>
                {`${
                  selectedProductGroup?.asins || displayList.length || 0
                } ASINS`}
              </Typography>
            )}
          </Box>
          {location.pathname !== "/sales-assistant/home" ? (
            <SalesFilterComponent handleSortFilter={handleSortFilter} />
          ) : activeTab === "groups" && producGroupData?.length - 1 > 0 ? (
            <SalesFilterComponent handleSortFilter={handleSortFilter} />
          ) : (
            activeTab === "all-products" &&
            displayList.length > 0 && (
              <SalesFilterComponent handleSortFilter={handleSortFilter} />
            )
          )}
        </Stack>
      </Stack>
    </Box>
  );
};
