import {
  Box,
  BoxProps,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  useAuthUserOrNull,
  useTenantsActions,
  useTenantsState,
} from "@frontegg/react-hooks/auth";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";
import { FC, useState } from "react";
import { DropdownIconV2, TenantStartIcon } from "../assets";

export const TenantSelectionV2: FC<BoxProps> = (props) => {
  const user = useAuthUserOrNull();
  const history = useHistory();
  const { tenants: tenantsFromState } = useTenantsState();
  const { switchTenant } = useTenantsActions();
  const queryClient = useQueryClient();
  const [open, setOpen] = useState<boolean>(false);

  const handleChangeTenant = (event: SelectChangeEvent) => {
    switchTenant({ tenantId: event.target.value as string });
    // Clear all React-Query caches
    queryClient.clear();
    history.push("/");
  };

  const handleOpenSelect = () => {
    setOpen(!open);
  };

  return (
    <Box
      {...props}
      sx={{
        position: "relative",
      }}
    >
      <Select
        variant="filled"
        id="tenant-select-component"
        value={user?.tenantId}
        onChange={handleChangeTenant}
        label="Organization"
        IconComponent={DropdownIconV2}
        open={open}
        sx={{
          padding: "0 10px 0 25px",
        }}
        onClick={() => setOpen(!open)}
      >
        {[...tenantsFromState]
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((currentTenant) => (
            <MenuItem
              key={currentTenant.tenantId}
              value={currentTenant.tenantId}
            >
              {currentTenant.name}
            </MenuItem>
          ))}
      </Select>
      <Box
        sx={{
          display: "inline-flex",
          cursor: "pointer",
          position: "absolute",
          left: "10px",
          top: "50%",
          transform: "translateY(-50%)",
        }}
        onClick={handleOpenSelect}
      >
        <TenantStartIcon />
      </Box>
    </Box>
  );
};
