import "ag-grid-enterprise";
import {
  Box,
  Chip,
  debounce,
  Divider,
  Drawer,
  // Paper,
  Typography,
} from "@mui/material";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import theme from "../../themes/light";
import { useSpace } from "./SpaceProvider";
import { ArrowDown, DarkStarIcon } from "../../assets";
import { AgGridReact, CustomHeaderProps } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import { ModuleRegistry } from "@ag-grid-community/core";
import { Search } from "../../components/SearchInput/Search";
import {
  GridReadyEvent,
  SizeColumnsToFitGridStrategy,
  SortDirection,
} from "ag-grid-community";
import apiRequest from "../../services/apiRequest";
import { KeywordsData } from "./types";
import { ServerSideRowModelModule } from "@ag-grid-enterprise/server-side-row-model";
import { getKeywordsListFromAsins } from "./useSpaces";
import CustomHeader from "./CustomHeader";
import CustomPagination from "./Pagination";
import CustomLoadingOverlay from "./CustomLoadingOverlay";
import CustomNoRowsOverlay from "./CustomNoRowsOverlay";
import CustomLoadingCellRenderer from "./CustomLoadingCellRenderer";

ModuleRegistry.registerModules([
  ServerSideRowModelModule,
  // ClientSideRowModelModule,
]);

interface KeywordsTableProps {
  open: boolean;
  spaceId: string | undefined;
}

const KeywordsTable: FC<KeywordsTableProps> = (props) => {
  const { open, spaceId } = props;

  const gridRef = useRef<AgGridReact<any>>(null);
  const [search, setSearch] = useState<string>("");
  const initialSortOrder = {
    growth: null,
    impression: null,
    star: null,
  };
  const [customSortOrder, setCustomSortOrder] = useState<any>(initialSortOrder);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState<string | null>(null);
  const fourHoursInMillis = 4 * 60 * 60 * 1000;

  const pageSize = 10;

  const {
    selectedProducts,
    // setKeywordsSearchAfterDebounce,
    // setSortKeywordsBy
  } = useSpace();

  const createDataSource = (
    page: number = 1,
    search?: string,
    type?: string,
    asins?: string[]
  ) => ({
    getRows: (params: any) => {
      const {
        request: { sortModel, filterModel },
        success,
        fail,
      } = params;
      const queryParams: any = {};
      const limit = 10;
      const startRow = (page - 1) * 10;

      if (sortModel?.length > 0) {
        if (
          sortModel[0]?.colId !== "impressions" &&
          sortModel[0]?.colId !== "growth" &&
          sortModel[0]?.colId !== "star"
        ) {
          setCustomSortOrder(initialSortOrder);
        }
        queryParams.sort_by =
          sortModel[0]?.sort === "asc"
            ? sortModel[0]?.colId
            : `-${sortModel[0]?.colId}`;
      }
      if (limit) {
        queryParams.limit = limit;
      }
      if (startRow) {
        queryParams.offset = startRow;
      }
      if (search) {
        queryParams.text_filter = search;
      } else {
        if (typeof filterModel === "object" && !!filterModel.search_term) {
          queryParams.text_filter = filterModel?.search_term?.filter;
        }
      }

      if (type === "asins" && asins && asins?.length > 0) {
        setLoading(null);
        getKeywordsListFromAsins({ spaceId, asins, queryParams })
          .then((res) => {
            if (res?.total === 0) {
              const spaceDataString = localStorage.getItem("spaceData");
              if (spaceDataString) {
                const spaceData = JSON.parse(spaceDataString);
                const createdSpaceTime = spaceData.createdSpaceTime;
                const createdTime = new Date(createdSpaceTime || "").getTime();
                const currentTime = new Date().getTime();
                const timeDifference = currentTime - createdTime;
                if (timeDifference < fourHoursInMillis) {
                  setLoading("waitingLoader");
                } else {
                  setLoading("noRowsLoader");
                }
              } else {
                setLoading("noRowsLoader");
              }
            } else {
              localStorage.removeItem("spaceData");
              setLoading(null);
            }
            setTotalRows(res?.total);
            success({
              rowData: res?.data,
              rowCount: res?.data?.length,
            });
          })
          .catch((error) => {
            console.error(error);
            setLoading("failed");
            fail();
          });
      } else {
        const url = `v1/keywords/table/${spaceId}`;
        setLoading(null);
        apiRequest<KeywordsData>(url, "GET", queryParams)
          .then((res) => {
            const { data, total } = res;
            if (total === 0) {
              const spaceDataString = localStorage.getItem("spaceData");
              if (spaceDataString) {
                const spaceData = JSON.parse(spaceDataString);
                const createdSpaceTime = spaceData.createdSpaceTime;
                const createdTime = new Date(createdSpaceTime || "").getTime();
                const currentTime = new Date().getTime();
                const timeDifference = currentTime - createdTime;
                if (timeDifference < fourHoursInMillis) {
                  setLoading("waitingLoader");
                } else {
                  setLoading("noRowsLoader");
                }
              } else {
                setLoading("noRowsLoader");
              }
            } else {
              setLoading(null);
              localStorage.removeItem("spaceData");
            }
            setTotalRows(total);
            params.success({ rowData: data, rowCount: data.length });
          })
          .catch((error) => {
            console.error(error);
            setLoading("failed");
            fail();
          });
      }
    },
  });

  const handleCustomSort = (
    event: any,
    props: CustomHeaderProps,
    sortType: string
  ) => {
    let setDir: SortDirection = "asc";
    const currentSortOrder: SortDirection = customSortOrder[sortType];
    if (currentSortOrder) {
      if (currentSortOrder === "asc") {
        setDir = "desc";
      } else {
        setDir = null;
      }
    }
    setCustomSortOrder({
      ...initialSortOrder,
      [sortType]: setDir,
    });
    props.setSort(setDir, event.shiftKey);
  };

  const debouncedOnChange = useCallback(
    debounce((search, selectedProducts) => {
      // setKeywordsSearchAfterDebounce(search);

      if (selectedProducts && selectedProducts?.length > 0) {
        const dataSourceFromAsins = createDataSource(
          undefined,
          search,
          "asins",
          selectedProducts
        );
        gridRef?.current!.api.setGridOption(
          "serverSideDatasource",
          dataSourceFromAsins
        );
      } else {
        const datasource = createDataSource(currentPage, search, "spaceId");
        gridRef?.current!.api.setGridOption("serverSideDatasource", datasource);
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (open) {
      debouncedOnChange(search, selectedProducts);
    }
  }, [search]);
  
  useEffect(() => {
    if(gridRef?.current!.api && open) {
      const datasource = createDataSource(currentPage);
      gridRef?.current!.api.setGridOption("serverSideDatasource", datasource);
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      if (selectedProducts && selectedProducts?.length > 0) {
        const dataSourceFromAsins = createDataSource(
          currentPage,
          search,
          "asins",
          selectedProducts
        );
        gridRef?.current!.api?.setGridOption(
          "serverSideDatasource",
          dataSourceFromAsins
        );
      } else if (gridRef?.current!.api) {
        const datasource = createDataSource(currentPage, search, "spaceId");
        gridRef?.current!.api?.setGridOption(
          "serverSideDatasource",
          datasource
        );
      }
    }
  }, [selectedProducts, currentPage]);

  const defaultColDef = {
    // flex: 1,
    suppressHeaderContextMenu: true,
    suppressHeaderMenuButton: true,
  };

  const convertNumberToShortFormat = (value: string | number) => {
    const number = Number(value);
    const thresholds = [
      { value: 1e9, symbol: "B" },
      { value: 1e6, symbol: "M" },
      { value: 1e3, symbol: "K" },
    ];

    const threshold = thresholds.find((threshold) => number >= threshold.value);

    if (threshold) {
      return (
        (number / threshold.value).toFixed(1).replace(/\.0$/, "") +
        threshold.symbol
      );
    }

    return number.toString();
  };

  const handlePageChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  const valueFormatters: { [key: string]: (p: any) => string } = {
    currency: (p) => "$" + Number(p.value).toLocaleString(),
    // growth: (p) => p.value.toFixed(2) + "%",
    number: (p) => Number(p.value).toLocaleString(),
  };

  const cellRenderers: { [key: string]: (p: any) => JSX.Element } = {
    growth: (p: any) => {
      const numberText = String(p?.value);

      if (String(p?.value)) {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "2px",
              "& svg": {
                transform: p.value > 0 ? "rotate(180deg)" : "none",
                "& path": {
                  fill:
                    p.value > 0
                      ? theme.palette.text.success
                      : theme.palette.error.main,
                },
              },
              color:
                p.value == 0
                  ? theme.palette.secondary.main
                  : p.value > 0
                    ? theme.palette.text.success
                    : theme.palette.error.main,
            }}
          >
            {p.value == 0 ? (
              <Box sx={{ marginLeft: "1.1rem" }}>0</Box>
            ) : (
              <>
                {p?.value ? (
                  <>
                    <ArrowDown />
                    {numberText?.includes("-") ? "-" : ""}
                    {convertNumberToShortFormat(
                      numberText?.includes("-")
                        ? numberText.split("-")[1]
                        : numberText
                    )}
                  </>
                ) : (
                  <Box
                    sx={{
                      marginLeft: "1.1rem",
                      color: theme.palette.secondary.main,
                    }}
                  >
                    -
                  </Box>
                )}
              </>
            )}
          </Box>
        );
      } else {
        return <></>;
      }
    },
    label: (p: any) => (
      <>
        {p.value ? (
          <Box
            sx={{
              display: "inline-flex",
              alignItems: "center",
              "& path": {
                fill: theme.palette.primary.main,
              },
            }}
          >
            <Box>
              <DarkStarIcon />
            </Box>
          </Box>
        ) : (
          <></>
        )}
      </>
    ),
    search_term: (p: any) => (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "0.6rem",
          position: "relative",
        }}
      >
        {p.data.new === true && (
          <Chip
            label="New"
            color="primary"
            size="small"
            sx={{
              fontSize: "0.65rem",
              height: "16px",
              ".MuiChip-label": {
                padding: "0 6px",
              },
            }}
          />
        )}
        <Box
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            lineClamp: 1,
          }}
        >
          {p.value}
        </Box>
      </Box>
    ),
  };

  const colDefs = [
    {
      headerName: "Keyword",
      field: "search_term",
      // flex: 1.1,
      valueFormatter: undefined,
      cellRenderer: cellRenderers.search_term,
      // filter: "agTextColumnFilter",
      loadingCellRenderer: CustomLoadingCellRenderer,
    },
    {
      headerName: "Branded",
      field: "brand",
      // flex: 1.1,
      valueFormatter: undefined,
      cellRenderer: undefined,
      loadingCellRenderer: CustomLoadingCellRenderer,
    },
    {
      headerName: "Impressions",
      field: "impressions",
      // flex: 1.1,
      valueFormatter: valueFormatters.number,
      cellRenderer: undefined,
      headerComponent: CustomHeader,
      headerComponentParams: {
        onSortRequested: (e: any, props: CustomHeaderProps) =>
          handleCustomSort(e, props, "impression"),
        sortOrder: customSortOrder?.impression,
        tooltipTitle: "Last 7 days",
        showTooltip: true,
      },
      loadingCellRenderer: CustomLoadingCellRenderer,
    },
    {
      headerName: "Growth",
      field: "growth",
      // flex: 1,
      valueFormatter: undefined,
      cellRenderer: cellRenderers.growth,
      headerComponent: CustomHeader,
      headerComponentParams: {
        onSortRequested: (e: any, props: CustomHeaderProps) =>
          handleCustomSort(e, props, "growth"),
        sortOrder: customSortOrder?.growth,
        tooltipTitle:
          "Impressions change over the last 7 days vs. previous 7 days.",
        showTooltip: true,
      },
      loadingCellRenderer: CustomLoadingCellRenderer,
    },
    {
      headerName: "",
      field: "star",
      // flex: 0.8,
      maxWidth: 80,
      valueFormatter: undefined,
      cellRenderer: cellRenderers.label,
      headerComponent: CustomHeader,
      sortable: false,
      headerComponentParams: {
        // onSortRequested: (e: any, props: CustomHeaderProps) =>
        //   handleCustomSort(e, props, "star"),
        sortOrder: customSortOrder?.star,
        showHeaderIcon: true,
      },
      loadingCellRenderer: CustomLoadingCellRenderer,
    },
  ];

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      const datasource = createDataSource(currentPage);
      params.api!.setGridOption("serverSideDatasource", datasource);
    },
    [selectedProducts, currentPage]
  );

  const autoSizeStrategy: SizeColumnsToFitGridStrategy = {
    type: "fitGridWidth",
  };

  return (
    <Drawer
      sx={{
        width: "40%",
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: "40%",
          top: "168px",
          paddingTop: "2px",
          background: theme.palette.grey[50],
          height: "calc(100vh - 168px)",
          border: "none",
          boxShadow:
            "0px 6px 15px 0px rgba(44, 34, 76, 0.05),0px 8px 32px 0px rgba(44, 34, 76, 0.05)",
        },
      }}
      variant="persistent"
      anchor="right"
      open={open}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: theme.spacing(0, 3),
          ...theme.mixins.toolbar,
          justifyContent: "space-between",
          background: theme.palette.text.dark,
          gap: "0.5rem",
        }}
      >
        <Typography
          sx={{
            fontWeight: "600",
            textWrap: "nowrap",
          }}
        >
          {`Keywords (${(selectedProducts && selectedProducts?.length > 0) || search
              ? "Filtered"
              : "All"
            })`}
        </Typography>
        <Search
          fullWidth
          placeholder="Search"
          value={search}
          sx={{ fontSize: "15px", verticalAlign: "middle", maxWidth: "250px" }}
          onChange={(e) => setSearch(e.target.value)}
          // onKeyDown={(e: any) => handleSearch(e)}
          id="product-search"
        />
      </Box>
      <Box
        className={"ag-theme-quartz"}
        sx={{
          padding: "2px 0",
          width: "100%",
          position: "relative",
          height: "95%",
          overflow: "hidden",

          "& .ag-root-wrapper": {
            border: "none",
            borderRadius: "unset",
          },
          "& .ag-header-cell-menu-button": {
            padding: "4px",
            ":hover": {
              boxShadow: "unset !important",
              borderRadius: "3px !important",
            },
          },
          "& .ag-overlay-loading-wrapper": {
            background: "unset",
          },
        }}
      >
        <AgGridReact
          loading={
            loading === "failed" ||
            loading === "waitingLoader" ||
            loading === "noRowsLoader"
          }
          ref={gridRef}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          rowHeight={50}
          // pagination
          // paginationPageSizeSelector={false}
          paginationPageSize={pageSize}
          suppressPaginationPanel
          rowModelType="serverSide"
          onGridReady={onGridReady}
          cacheBlockSize={10}
          serverSideInitialRowCount={1}
          maxBlocksInCache={2}
          loadingOverlayComponent={
            loading === "waitingLoader"
              ? CustomLoadingOverlay
              : CustomNoRowsOverlay
          }
          suppressServerSideFullWidthLoadingRow={true}
          autoSizeStrategy={autoSizeStrategy}
        />
        {loading !== "waitingLoader" &&
          loading !== "noRowsLoader" &&
          loading !== "failed" && (
            <CustomPagination
              totalRows={totalRows}
              currentPage={currentPage}
              handleChange={handlePageChange}
              type="KeywordList"
            />
          )}
      </Box>
      <Divider />
    </Drawer>
  );
};

export default KeywordsTable;
