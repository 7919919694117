import CloseIcon from "@mui/icons-material/Close";
import theme from "../../../themes/light.ts";
import { Box, Divider, IconButton, Stack } from "@mui/material";
import { salesColumnWidths } from "../../SalesAssistant/constants.ts";
import ProductImage from "../Components/Row/ProductImage.tsx";
import InfoSection from "../Components/Row/InfoSection.tsx";
import { useSalesAssistant } from "../SalesAssistantProvider.tsx";
import NumberSection from "../Components/Row/NumberSection.tsx";

const DrawerTitle = ({ onDrawerClose }: { onDrawerClose: () => void }) => {
  const { selectedProduct } = useSalesAssistant();
  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={4}
      paddingBottom="20px"
      borderBottom={`1px solid ${theme.palette.borders.lines}`}
    >
      <IconButton
        onClick={onDrawerClose}
        sx={{ background: "none", p: 2.5 / 8 }}
      >
        <CloseIcon
          sx={{ fontSize: "22px" }}
          htmlColor={theme.palette.icons.secondary}
        />
      </IconButton>
      <Stack
        direction="row"
        gap="16px"
        flex={2.5}
        sx={{ display: "flex", gap: "16px", alignItems: "center" }}
        width={salesColumnWidths.info}
      >
        <Box minWidth={salesColumnWidths.image}>
          <ProductImage data={selectedProduct} />
        </Box>
        <Box>
          <InfoSection type="product" data={selectedProduct} />
        </Box>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        border={`1px solid ${theme.palette.borders.lines}`}
        borderRadius="8px"
        padding="8px 12px"
        gap="24px"
      >
        <NumberSection
          unit="$"
          number={selectedProduct?.revenue}
          percentage={selectedProduct?.revenue_percentage}
          change={selectedProduct?.revenue_change}
          title="Revenue"
        />
        <Divider orientation="vertical" flexItem />
        <NumberSection
          number={selectedProduct?.units_sold}
          percentage={selectedProduct?.units_sold_percentage}
          change={selectedProduct?.units_sold_change}
          title="Units Sold"
        />
        <Divider orientation="vertical" flexItem />
        <NumberSection
          unit="%"
          number={selectedProduct?.conversion}
          percentage={selectedProduct?.conversion_percentage}
          change={selectedProduct?.conversion_change}
          title="Conversion"
        />
        <Divider orientation="vertical" flexItem />
        <NumberSection
          number={selectedProduct?.glance_views}
          percentage={selectedProduct?.glance_views_percentage}
          change={selectedProduct?.glance_views_change}
          title="Glance Views"
        />
      </Stack>
    </Stack>
  );
};

export default DrawerTitle;
