import { Box, Skeleton } from "@mui/material";
import theme from "../../../../themes/light.ts";

const SkeletonCard = () => {
  return (
    <Box display="flex" gap="16px">
      <Skeleton
        variant="text"
        width={200}
        sx={{
          background: theme.palette.grey[50],
          borderRadius: "12px",
          padding: "12px",
          width: "200px",
          height: "68px ",
          transform: "scale(1)",
        }}
      />
      <Skeleton
        variant="text"
        width={200}
        sx={{
          background: theme.palette.grey[50],
          borderRadius: "12px",
          padding: "12px",
          width: "200px",
          height: "68px ",
          transform: "scale(1)",
        }}
      />
      <Skeleton
        variant="text"
        width={200}
        sx={{
          background: theme.palette.grey[50],
          borderRadius: "12px",
          padding: "12px",
          width: "200px",
          height: "68px ",
          transform: "scale(1)",
        }}
      />
      <Skeleton
        variant="text"
        width={200}
        sx={{
          background: theme.palette.grey[50],
          borderRadius: "12px",
          padding: "12px",
          width: "200px",
          height: "68px ",
          transform: "scale(1)",
        }}
      />
      <Skeleton
        variant="text"
        width={200}
        sx={{
          background: theme.palette.grey[50],
          borderRadius: "12px",
          padding: "12px",
          width: "200px",
          height: "68px ",
          transform: "scale(1)",
        }}
      />
    </Box>
  );
};

export default SkeletonCard;
