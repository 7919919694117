import {
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
  Popper,
  Paper,
  ClickAwayListener,
  Box,
  ToggleButtonGroup,
} from "@mui/material";
import { CheckboxFilledIcon, DropDownIcon } from "../../assets";
import { Search } from "../../components/SearchInput/Search";
import theme from "../../themes/light";
import React, {
  FC,
  useRef,
  useState,
  useCallback,
  useMemo,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import ProductList from "./ProductList";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { FilterBrands } from "./FilterBrands";
import EmptyCatalogState from "./EmptyCatalogState";
import { UseFormSetValue } from "react-hook-form";
import { AsinFormData } from "./AddSpaceV2";
import { getAllEcommerceProducts } from "./useSpaces";
import { ToggleButton } from "@mui/material";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import CatalogViewGrid from "./CatalogViewGrid";
import { useSpace } from "./SpaceProvider";
// import FilterCategoriesV2 from "./FilterCategoriesV2";
import FilterCategoriesV3 from "./FilterCategoriesV3";

interface CatalogViewProps {
  selectedProducts: string[];
  setSelectedProducts: Dispatch<SetStateAction<string[]>>;
  asinsFromGrid: ProductData;
  setAsinsFromGrid: (data: ProductData) => void;
  setValue: UseFormSetValue<AsinFormData>;
  err: boolean;
}

export interface ProductData {
  total: number;
  asins: string[];
  isDataReady: boolean;
}

export type GetAllProductsResponse = {
  asins: string[];
  count: number;
};

const CatalogView: FC<CatalogViewProps> = (props) => {
  const {
    selectedProducts,
    setSelectedProducts,
    asinsFromGrid,
    setAsinsFromGrid,
    setValue,
    err,
  } = props;

  // Context Text
  const { view, setView } = useSpace();

  const [search, setSearch] = useState<string>("");
  const gridRef = useRef<AgGridReact<any>>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openPopper, setOpenPopper] = useState<string | null>(null);
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[][]>([]);
  // const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const [selectedItems, setSelectedItems] = React.useState<Set<string>>(
    new Set()
  );

  const deSelectAll = useCallback(() => {
    setSelectedProducts([]);
    gridRef?.current!.api.deselectAll();
  }, [setSelectedProducts]);

  useEffect(() => {
    if (selectedProducts.length > 0) {
      setValue("asins", [], {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [selectedProducts.length, setValue]);

  const SelectAgGrid = () => {
    return (
      <Stack>
        <FormControlLabel
          // label={selectedCount > 0 ? `${selectedCount} selected` : "Select all"}
          label=""
          control={
            <Checkbox
              sx={{
                marginLeft: -4.5,
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
              checked={
                selectedCount > 0 &&
                (selectedCount === asinsFromGrid.asins?.length ||
                  selectedCount === asinsFromGrid.total)
              }
              onChange={(e) =>
                handleSelectAll(
                  e,
                  selectedBrands,
                  selectedCategories,
                  search,
                  asinsFromGrid
                )
              }
              indeterminate={
                selectedCount > 0 &&
                selectedCount !== asinsFromGrid.total &&
                selectedCount !== asinsFromGrid.asins?.length
              }
              indeterminateIcon={<CheckboxFilledIcon />}
            />
          }
          sx={{
            marginLeft: "25.5px",
            marginRight: "0",
            // width: "28px",
            "& .MuiFormControlLabel-label": {
              fontSize: "13px",
              fontWeight: 500,
              color: theme.palette.grey[500],
              lineHeight: "16px",
            },
          }}
        />
      </Stack>
    );
  };

  const setProductsLength = useCallback(
    (res: GetAllProductsResponse, search?: string) => {
      setAsinsFromGrid({
        asins: res.asins,
        total: res?.count,
        isDataReady: !search || false,
      });
    },
    []
  );

  const handleSelectAll = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
      brands: string[],
      categories: any,
      search: string,
      asinsFromGrid: ProductData
    ) => {
      const isChecked = e.target.checked;
      // const api = gridRef?.current!.api;
      // const currentPage = api?.paginationGetCurrentPage() || 0;
      // const pageSize = api?.paginationGetPageSize() || 100; // Adjust if page size is dynamic

      if (isChecked) {
        // gridRef?.current!.api.selectAll()
        const filters: any = {};
        if (search) {
          const searchText = search.replaceAll(/\s/g, "");
          if (searchText?.includes(",")) {
            const titles = searchText?.split(",");
            filters.titles = [...titles];
          } else {
            filters.titles = [search];
          }
        }
        if (brands?.length > 0) {
          filters.brands = brands;
        }
        if (categories) {
          const formattedCategory = categories.map((categoryArray: any) =>
            categoryArray.join("|")
          );
          filters.categories = formattedCategory;
        }
        if (asinsFromGrid?.total < 100) {
          setSelectedProducts(asinsFromGrid?.asins);
          setValue("asins", [], {
            shouldDirty: true,
            shouldValidate: true,
          });
          gridRef?.current!.api.forEachNode((node) => {
            node.setSelected(true);
          });
        } else {
          getAllEcommerceProducts(filters)
            .then((res: any) => {
              setProductsLength(res, search);
              setSelectedProducts(res?.asins);
              setValue("asins", [], {
                shouldDirty: true,
                shouldValidate: true,
              });
              if (brands?.length > 0 || categories?.length > 0 || search) {
                gridRef?.current!.api.forEachNode((node) => {
                  node.setSelected(true);
                });
              } else {
                gridRef?.current!.api.selectAll();
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
        // const selectedAsins: string[] = [];
        // const nodesOnPage: any[] = [];
        // // Get the number of displayed rows
        // const displayedRowCount = api?.getDisplayedRowCount() || 0;
        // // Calculate the range of rows for the current page
        // const startRow = currentPage * pageSize;
        // const endRow = Math.min(startRow + pageSize, displayedRowCount);

        // // Collect nodes for the current page
        // for (let i = startRow; i < endRow; i++) {
        //   const rowNode = api?.getDisplayedRowAtIndex(i);
        //   if (rowNode && rowNode.data) {
        //     nodesOnPage.push(rowNode);
        //     selectedAsins.push(rowNode.data.asin);
        //   }
        // }
        // // Slice to get the last 100 ASINs from nodes on the current page
        // const index = Math.max(0, selectedAsins.length - 100);
        // const last100Asins = selectedAsins.slice(index);

        // // Select only the last 100 nodes on the current page
        // nodesOnPage.forEach((node) => {
        //   if (last100Asins.includes(node.data.asin)) {
        //     node.setSelected(true);
        //   } else {
        //     node.setSelected(false); // Ensure other nodes are deselected
        //   }
        // });
        // setSelectedProducts(last100Asins);
      } else {
        deSelectAll();
      }
    },
    [deSelectAll, setSelectedProducts]
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>, type: string) => {
      setAnchorEl(event.currentTarget);
      setOpenPopper(type);
    },
    []
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setOpenPopper(null);
  }, []);

  const onBrandChange = useCallback((brands: string[]) => {
    setSelectedBrands(brands);
  }, []);

  // const onCategoryChange = useCallback((categories: string[]) => {
  //   const formattedCategories = categories.map((category) =>
  //     category.split("|")
  //   );
  //   setSelectedCategories(formattedCategories);
  // }, []);

  // const totalProducts = useMemo(
  //   () => asinsFromGrid?.total,
  //   [asinsFromGrid?.total]
  // );
  const selectedCount = useMemo(
    () => selectedProducts?.length,
    [selectedProducts]
  );

  useEffect(() => {
    const isEmptyCatalogueState =
      selectedBrands?.length !== 0 ||
      selectedCategories.length !== 0 ||
      search?.length !== 0
        ? false
        : asinsFromGrid?.isDataReady && asinsFromGrid?.asins?.length === 0;
    setIsEmpty(isEmptyCatalogueState);
  }, [
    asinsFromGrid?.asins?.length,
    asinsFromGrid?.isDataReady,
    search?.length,
    selectedBrands?.length,
    selectedCategories.length,
  ]);

  const handleChangeLayout = useCallback(
    (_: React.MouseEvent<HTMLElement, MouseEvent>, value: string) => {
      if (value) {
        setView(value);
      }
    },
    [setView]
  );

  return (
    <Stack
      spacing={1.5}
      height={
        err
          ? view === "table"
            ? "calc(100vh - 200px)"
            : "calc(100vh - 256px)"
          : view === "table"
            ? "calc(100vh - 179px)"
            : "calc(100vh - 235px)"
      }
    >
      {/* <Typography variant="h5" fontWeight={600} fontSize={16}>
        {`Select Products ${totalProducts > 0 ? `(${totalProducts})` : ""}`}
      </Typography> */}
      <Stack
        direction={"row"}
        spacing={1.5}
        sx={{ marginTop: "10px !important" }}
      >
        <Button
          variant="outlined"
          sx={{
            minHeight: "32px",
            padding: "4px 12px",
            border:
              openPopper === "brand"
                ? `1px solid ${theme.palette.primary.main}`
                : "",
          }}
          endIcon={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              {selectedBrands?.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: theme.palette.primary.light,
                    color: theme.palette.secondary.main,
                    borderRadius: "32px",
                    height: "18px",
                    padding: "0 6px",
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  {selectedBrands?.length}
                </Box>
              )}
              <DropDownIcon />
            </Box>
          }
          onClick={(event) => handleClick(event, "brand")}
        >
          <Typography sx={theme.typography.body3}>Brand</Typography>
        </Button>
        <Button
          variant="outlined"
          sx={{
            minHeight: "32px",
            padding: "4px 12px",
            border:
              openPopper === "category"
                ? `1px solid ${theme.palette.primary.main}`
                : "",
          }}
          endIcon={
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              {selectedCategories?.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: theme.palette.primary.light,
                    color: theme.palette.secondary.main,
                    borderRadius: "32px",
                    height: "18px",
                    padding: "0 6px",
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  {selectedCategories?.length}
                </Box>
              )}
              <DropDownIcon />
            </Box>
          }
          onClick={(event) => handleClick(event, "category")}
        >
          <Typography sx={theme.typography.body3}>Category</Typography>
        </Button>

        <Search
          fullWidth
          placeholder="Search ASIN or title"
          value={search}
          autoComplete="off"
          sx={{
            fontSize: "15px",
            maxWidth: "596px",
            "& .MuiInputBase-root": {
              background: `${theme.palette.background.default} !important`,
              height: "34px",
            },
            // "&:hover": {
            //   border: "1px #A19EA5"
            // },
            "&:focus": {
              background: `${theme.palette.background.default} !important`,
            },
            "&: active": {
              background: `${theme.palette.background.default} !important`,
            },
          }}
          onChange={(e) => setSearch(e.target.value)}
          id="product-search"
        />
        <Stack
          sx={{
            margin: "auto 0 auto auto",
            "& .MuiStack-root>:not(style)~:not(style)": {
              marginLeft: "auto",
            },
            marginLeft: "auto !important",
          }}
          alignSelf="flex-end"
        >
          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={handleChangeLayout}
            sx={{
              height: 32,
              "& .MuiToggleButton-root.Mui-selected": {
                background: theme.palette.primary.main,
                ":hover": {
                  background: theme.palette.primary.main,
                },
                "& svg": {
                  color: theme.palette.background.default,
                },
              },
              "& svg": {
                color: theme.palette.secondary.main,
              },
            }}
          >
            <ToggleButton
              value="grid"
              aria-label="grid"
              sx={{
                padding: "5px 8px",
              }}
            >
              <GridViewOutlinedIcon />
            </ToggleButton>
            <ToggleButton
              sx={{
                padding: "5px 8px",
              }}
              value="table"
              aria-label="table"
            >
              <FormatListBulletedOutlinedIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Stack>
      <Stack height="100%">
        {isEmpty ? (
          <EmptyCatalogState />
        ) : view === "table" ? (
          <ProductList
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            gridRef={gridRef}
            asinsFromGrid={asinsFromGrid}
            setAsinsFromGrid={setAsinsFromGrid}
            search={search}
            selectedBrands={selectedBrands}
            selectedCategories={selectedCategories}
            deSelectAll={deSelectAll}
            selectAgGrid={SelectAgGrid}
            setValue={setValue}
          />
        ) : (
          <CatalogViewGrid
            search={search}
            selectedBrands={selectedBrands}
            selectedCategories={selectedCategories}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            asinsFromGrid={asinsFromGrid}
            setAsinsFromGrid={setAsinsFromGrid}
          />
        )}
      </Stack>
      <Popper
        open={openPopper === "brand"}
        anchorEl={anchorEl}
        placement="bottom-start"
        disablePortal
        sx={{ zIndex: 10 }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper sx={{ padding: 2, width: 400, height: 400 }}>
            <FilterBrands
              selectedBrands={selectedBrands}
              onChange={onBrandChange}
            />
          </Paper>
        </ClickAwayListener>
      </Popper>
      <Popper
        open={openPopper === "category"}
        anchorEl={anchorEl}
        placement="bottom-start"
        disablePortal
        sx={{ zIndex: 10 }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper sx={{ padding: 2, width: 400, height: 400 }}>
            {/* <FilterCategoriesV2
              selectedCategories={selectedCategories}
              selectedItems={selectedItems}
              setSelectedCategories={setSelectedCategories}
              setSelectedItems={setSelectedItems}
            /> */}
            <FilterCategoriesV3
              selectedCategories={selectedCategories}
              setSelectedCategories={setSelectedCategories}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
            />
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Stack>
  );
  // );
};

export default CatalogView;
