import { Box, Typography } from "@mui/material";
import theme from "../../themes/light";

interface PropTypes {
  title?: string;
  qId?: string;
  chatType?: string;
  params?: any;
  handleOnClick?: () => void;
  icon?: any;
}

const ChatView = ({ title, icon, handleOnClick }: PropTypes) => {
  return (
    <Box
      sx={{
        boxShadow: "0px 4px 12px 0px rgba(44, 34, 76, 0.08)",

        background: theme.palette.primary.contrastText,
        height: "110px",
        width: "175px",
        borderRadius: "8px",
        padding: "1rem 1rem",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column-reverse",
        justifyContent: "flex-end",
        gap: "0.5rem",
        outline: "1px solid rgba(0, 0, 0, 0.10)",
        ":hover": {
          outline: "1px solid rgba(0, 0, 0, 0.20)",
          backgroundColor: " #fafafa",
          boxShadow:
            "0px 12px 45px -4px rgba(44, 34, 76, 0.05), 0px 8px 20px -6px rgba(44, 34, 76, 0.05)",
        },
      }}
      onClick={handleOnClick}
    >
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: 500,
        }}
      >
        {title}
      </Typography>
      {icon}
    </Box>
  );
};
export default ChatView;
