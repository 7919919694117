import { useContext } from "react";
import _ from "lodash";
import { insightsChatContext } from "../InsightsChatProvider";
import dayjs from "dayjs";
import {
  subDays,
  subYears,
} from "date-fns";
import { DateRange } from "mui-daterange-picker";

export const useInsightChatContext = () => useContext(insightsChatContext);

export const getFormattedDate = (dateVal: Date) => {
  if (dateVal) {
    const dateStr = dayjs(dateVal);
    const formattedDate = dateStr.format("DD/MM/YYYY");
    return formattedDate;
  }
};

export const findGraphData = (data: any): boolean => {
  const graphDataExists = data?.story_points.every((el: any) => {
    const graphData = el?.graph_data ? JSON.parse(el.graph_data) : null;
    return graphData && graphData.graph_data;
  });
  return graphDataExists;
};

export const findGraphDataLastStoryPoint = (data: any): boolean => {
  const checkLength = data?.story_points?.length;
  const graphDataExists =
    data?.story_points?.[checkLength - 1]?.graph_data !== null ? true : false;
  return graphDataExists;
};

export const getLastStoryPointdata = (data: any) => {
  const storyLenght = data?.story_points?.length || 0;

  const preIndexData =
    storyLenght >= 2
      ? data?.story_points[storyLenght - 1]?.type ===
        data?.story_points[storyLenght - 2]?.type
        ? true
        : false
      : true;

  if (preIndexData) {
    return { storyData: data, isMatch: true };
  } else {
    const copyData: any = { ...data };
    copyData.story_points = [data?.story_points[storyLenght - 1]];
    return { storyData: copyData, isMatch: false };
  }
};

export const parseDateRangeParam = (dateRange: any) => {
  if (dateRange) {
    const startDate = dayjs(dateRange[0]).format("DD/MM/YYYY");
    const endDate = dayjs(dateRange[1]).format("DD/MM/YYYY");
    return { startDate, endDate };
  }
}

export const genDateRangeParam = (dateRange: DateRange) => {
  if (dateRange) {
    const startDate = dayjs(dateRange.startDate).format("YYYY-MM-DD")
    const endDate = dayjs(dateRange.endDate).format("YYYY-MM-DD")
    return [startDate, endDate]
  }
}

export const getFormattedDateObject = (range: any) => {
  if (range?.endDate && range?.startDate) {
    const startDate = dayjs(range.startDate, "DD/MM/YYYY");
    const endDate = dayjs(range.endDate, "DD/MM/YYYY");
    return { startDate, endDate }
  } else if (range?.end_date && range?.start_date) {
    const startDate = dayjs(range.start_date, "DD/MM/YYYY");
    const endDate = dayjs(range.end_date, "DD/MM/YYYY");
    return { startDate, endDate }
  } else {
    if (parseInt(range) > 0) {
      const startDate = dayjs()
        .subtract(parseInt(range), "day").format("DD/MM/YYYY")
      const endDate = dayjs().format("DD/MM/YYYY")
      return { startDate, endDate }
    } else {
      const startDate = dayjs()
        .subtract(90, "day").format("DD/MM/YYYY")
      const endDate = dayjs().format("DD/MM/YYYY")
      return { startDate, endDate }
    }
  }
};

export const getFormattedDateString = (range: any, defaultLabel?: string) => {
  if(range) {
    const dateRange = getFormattedDateObject(range)
    const formattedStartDate = dayjs(dateRange?.startDate).format("MMM D");
    const formattedEndDate = dayjs(dateRange?.endDate).format("MMM D, YYYY");
    return `${formattedStartDate} - ${formattedEndDate}`;
  } else {
    return defaultLabel
  }
  // if (range?.endDate && range?.startDate) {
  //   const startDate = dayjs(range.startDate);
  //   const endDate = dayjs(range.endDate);
  //   const formattedStartDate = startDate.format("MMM D");
  //   const formattedEndDate = endDate.format("MMM D, YYYY");
  //   return `${formattedStartDate} - ${formattedEndDate}`;
  // } else if (range?.end_date && range?.start_date) {
  //   const startDate = dayjs(range.start_date,"DD/MM/YYYY");
  //   const endDate = dayjs(range.end_date,"DD/MM/YYYY");
  //   const formattedStartDate = startDate.format("MMM D");
  //   const formattedEndDate = endDate.format("MMM D, YYYY");
  //   return `${formattedStartDate} - ${formattedEndDate}`;
  // } else {
  //   if (parseInt(defaultRange) > 0) {
  //     const startDate = dayjs()
  //       .subtract(parseInt(defaultRange), "day")
  //       .format("MMM D, YYYY");
  //     const endDate = dayjs().format("MMM D, YYYY");
  //     return `${startDate} - ${endDate}`;
  //   } else {
  //     return defaultRange;
  //   }
  // }
};

export const getNewFormattedDateString = (dateRange: any, defaultLabel?: string) => {
  if(dateRange) {
    const formattedStartDate = dayjs(dateRange?.startDate).format("MMM D, YYYY");
    const formattedEndDate = dayjs(dateRange?.endDate).format("MMM D, YYYY");
    return `${formattedStartDate} - ${formattedEndDate}`;
  } else {
    return defaultLabel
  }
}

export const getStoryBoardGroupData = (data: any) => {
  const copyData = JSON.parse(JSON.stringify(data));
  const storyPoints = data?.story_points || [];
  const groupedStoryPoints: any = {};
  let groupIndex = 0;
  storyPoints.forEach((point: any) => {
    const pointType = point.type;
    const keyArray = Object.keys(groupedStoryPoints)
    let flag = 0;
    if(keyArray?.length > 0) {
      keyArray?.forEach(keyValue => {
        const storyPointsArray = groupedStoryPoints[keyValue]
        if(storyPointsArray[0]?.type === pointType) {
          groupedStoryPoints[keyValue].push(point);
          flag = 1;
        }
      })
      if(flag === 0) {
        groupIndex = groupIndex + 1;
        groupedStoryPoints[groupIndex] = [point];
      }
    } else {
      groupedStoryPoints[groupIndex] = [point];
    }

    // if (index === 0) {
    //   if (groupedStoryPoints[flag]) {
    //     groupedStoryPoints[flag].push(point);
    //   } else {
    //     groupedStoryPoints[flag] = [point];
    //   }
    // } else {
    //   const preType = storyPoints[index - 1].type;
    //   if (preType === pointType) {
    //     groupedStoryPoints[flag].push(point);
    //   } else {
    //     flag = flag + 1;
    //     groupedStoryPoints[flag] = [point];
    //   }
    // }
    
  });
  copyData.story_points = groupedStoryPoints;
  return copyData;
};

export const checkTime = (createdAtDate: any): boolean => {
  const t1 = new Date().toISOString();
  const t2 = createdAtDate; //  
  const d1 = t1.split("T");
  const d2 = t2.split("T");

  const t1Array = d1[1].split(":");
  const t2Array = d2[1].split(":");
  const date1 = new Date(d1[0]);
  const date2 = new Date(d2[0]);

  const h1 = parseInt(t1Array[0], 10);
  const m1 = parseInt(t1Array[1], 10);
  const h2 = parseInt(t2Array[0], 10);
  const m2 = parseInt(t2Array[1], 10);

  date1.setHours(h1);
  date1.setMinutes(m1);
  date1.setSeconds(0);

  date2.setHours(h2);
  date2.setMinutes(m2);
  date2.setSeconds(0);

  const currentDate = new Date(date1);
  const previousDate = new Date(date2);

  // Calculate the time difference in milliseconds
  const timeDifference = currentDate.getTime() - previousDate.getTime();
  // Convert the time difference to minutes
  const timeDifferenceInSeconds = (timeDifference / 1000) * 60;
  if (timeDifferenceInSeconds >= 5) {
    return true;
  } else {
    return false;
  }
};

export const getCompareTimeFrame = (timeFrame: string) => {
  const time = Number(timeFrame)
  if(time && !isNaN(time)) {
    const currentDate = new Date();

    const endDateOfLastYear = subYears(currentDate, 1);

    const startDateOfCurrentYear = subDays(currentDate, time);

    const startDateOfLastYear = subYears(startDateOfCurrentYear, 1)

    return {
      startDate: startDateOfLastYear,
      endDate: endDateOfLastYear,
      label: "Previous year"
    }
  }
}

const getNewFormattedDate = (dateString: string) => {
  if(dateString && typeof dateString === "string") {
    const parts = dateString.split("/");
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);
    const date = new Date(year, month, day);
    return date
  } return new Date()
}

export const getParsedDate = (dateRange: any) => {
  // const startDate = dayjs(dateRange?.startDate).format("DD/MM/YYYY")
  // const endDate = dayjs(dateRange?.endDate).format("DD/MM/YYYY")
  // if(startDate && endDate) {
  //   const parsedStartDate = new Date(startDate);
  //   const parsedEndDate = new Date(endDate);
  //   return { startDate: parsedStartDate, endDate: parsedEndDate }
  // } else {
  //   return getDefaultDateRange()
  // }

  const formattedStartDate = getNewFormattedDate(dateRange?.startDate)
  const formattedEndDate = getNewFormattedDate(dateRange?.endDate)
  return { startDate: formattedStartDate, endDate: formattedEndDate }
}

export const getTimeStampParsedDate = (timeStamp: any) => {
  const date = new Date(timeStamp)
  const label = dayjs(date).format("DD, MMM, YYYY");
  return label
}

export const getSmallestDateIndices = (dates: any) => {
  // Parse dates into Day.js objects and group by month and year
  const groupedDates = dates.reduce((acc: any, date: any, index: any) => {
    const parsedDate = dayjs(date, "DD, MMM, YYYY");
    const key = parsedDate.format("MMM, YYYY");
    if (!acc[key] || parsedDate.isBefore(acc[key].date)) {
        acc[key] = { date: parsedDate, index };
    }
    return acc;
  }, {});

  // Extract the indices of the smallest dates
  return Object.values(groupedDates).map((dates: any) => dates?.index);
};