import { Box } from "@mui/material";
import ChatView from "./ChatView";
import { useChatQuestionList } from "./useChatApi";

const Discover = () => {
  const { data: QuestionList } = useChatQuestionList();
  return (
    <Box
      display="flex"
      gap="12px"
      flexDirection="column"
      sx={{
        marginTop: "18px",
        "& .MuiTypography-root": {
          fontSize: "12px !important",
          lineHeight: "initial",
        },
        "& .MuiBox-root": {
          boxShadow: "none",
          border: "1px solid #E8E8E8",
          paddingInline: "16px",
        },
      }}
    >
      {QuestionList &&
        QuestionList?.length > 0 &&
        QuestionList?.map((el: any) => (
          <ChatView
            title={el?.question_text}
            qId={el?.id}
            params={el?.params}
          />
        ))}
    </Box>
  );
};
export default Discover;
