import { Button, ButtonGroup } from "@mui/material";
import theme from "../themes/light.ts";
import { Dispatch, SetStateAction } from "react";

export type Button = {
  text: string;
  value: string;
};
const NoogataButtonGroup = ({
  buttons,
  value,
  onChange,
}: {
  buttons: Button[];
  value: string;
  onChange: Dispatch<SetStateAction<string>>;
}) => {
  return (
    <ButtonGroup
      variant="outlined"
      aria-label="outlined primary button group"
      size="small"
      sx={{
        height: "31px",
        "& .MuiButtonGroup-grouped": {
          minWidth: "43px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 8px",
          height: "31px",
          minHeight: "31px",
        },
      }}
    >
      {buttons.map((btn, key) => (
        <Button
          key={`button-${key}-${btn.value}`}
          onClick={() => onChange(btn.value)}
          sx={{
            background:
              value === btn.value ? "#2C224C" : theme.palette.backgrounds.white,
            cursor: value === btn.value ? "default" : "pointer",
            border:
              value === btn.value
                ? "1px solid #2C224C !important"
                : "1px solid #D7D5DB !important",
            "&[disabled]": { background: theme.palette.backgrounds.default },
            "&:hover": {
              border: "1px solid #2C224C !important",
              background:
                value === btn.value
                  ? "#2C224C"
                  : theme.palette.backgrounds.white,
            },
            fontSize: "13px",
            lineHeight: "24px",
            color:
              value === btn.value ? "#FFFFFF" : theme.palette.secondary.main,
          }}
        >
          {btn.text}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default NoogataButtonGroup;
