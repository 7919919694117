import Row from "../Components/Row";
import { Box, Stack } from "@mui/material";
import { RowData } from "../types";
import { useEffect, useRef } from "react";
import useOnScreen from "../../../hooks/useOnScreen.tsx";
import { useSalesAssistant } from "../SalesAssistantProvider.tsx";

const List = ({ data, total }: { data?: RowData[]; total?: number }) => {
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);
  const { fetchNextPage, salesAssistantListFetching } = useSalesAssistant();

  useEffect(() => {
    if (isVisible && !salesAssistantListFetching) {
      fetchNextPage();
    }
  }, [isVisible, salesAssistantListFetching]);

  return (
    <Stack gap="8px" marginTop="16px" overflow="auto" height="100%">
      {(!data || !data.length) && (
        <Stack justifyContent="center" alignItems="center" height="100%">
          <Box>No results found for the selected filter</Box>
        </Stack>
      )}
      {data?.map((item, index) => (
        <Row key={`item-${index}-${item?.title}`} data={item}></Row>
      ))}
      {(data?.length ?? 0) < (total ?? 0) && (
        <Box textAlign="center" padding="12px" ref={ref}>
          {salesAssistantListFetching
            ? "Loading more..."
            : "Waiting for loading more"}
        </Box>
      )}
    </Stack>
  );
};

export default List;
