import ScoreCard from "./ScoreCard.tsx";
import { Button, Stack } from "@mui/material";
import theme from "../../themes/light.ts";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

const ScoreBar = () => {
  return (
    <Stack direction="row" alignItems="center" width="100%">
      <ScoreCard title="Content Rank" />
      <ScoreCard title="Title" baseScore={40} />
      <ScoreCard title="About" baseScore={9} />
      <ScoreCard title="Description" baseScore={15} />
      <Stack direction="row" flex={1} justifyContent="end">
        <Button
          variant="text"
          startIcon={<AutoAwesomeIcon />}
          sx={{
            color: theme.palette.secondary.main,
            padding: "0 1rem 0 1.3rem",
            ":focus": {
              background: "unset",
            },
          }}
        >
          Optimize
        </Button>
      </Stack>
    </Stack>
  );
};

export default ScoreBar;
