import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  useIsThereSalesAssistant,
  useProductsFilterList,
  useSalesAssistantCards,
  useSalesAssistantHeaderData,
  useSalesAssistantList,
} from "./api.ts";
import {
  CardData,
  ListOutput,
  RowData,
  SalesAssistantHeaderData,
} from "./types.ts";
import { useParams } from "react-router-dom";
import { useQueryParams } from "../../hooks";

export type SalesAssistantState = {
  searchInput: string;
  setSearchInput: Dispatch<SetStateAction<string>>;
  daysToShow: string;
  setDaysToShow: Dispatch<SetStateAction<string>>;
  sortBy: string;
  setSortBy: Dispatch<SetStateAction<string>>;
  isLoading: boolean;
  salesAssistantList?: ListOutput;
  productGroups?: { id: string; name: string; asins?: number }[];
  selectedProductGroup: string;
  setSelectedProductGroup: Dispatch<SetStateAction<string>>;
  selectedGroupBy: string;
  spaceId?: string;
  setSelectedGroupBy: Dispatch<SetStateAction<string>>;
  isThereSalesAssistant?: boolean;
  isThereSalesAssistantLoading?: boolean;
  salesAssistantHeaderData?: SalesAssistantHeaderData[];
  fetchNextPage: () => void;
  salesAssistantListFetching?: boolean;
  selectedProduct?: RowData;
  salesCardData?: CardData[];
  salesCardsDataLoading?: boolean;
};
export const SalesAssistantContext = createContext<SalesAssistantState>(
  {} as SalesAssistantState,
);

export const useSalesAssistant = () => useContext(SalesAssistantContext);

const SalesAssistantProvider = ({ children }: { children: ReactNode }) => {
  const queryParams = useQueryParams();

  const [daysToShow, setDaysToShow] = useState("7d");
  const [selectedProductGroup, setSelectedProductGroup] =
    useState("ALL_PRODUCTS");
  const [selectedGroupBy, setSelectedGroupBy] = useState("spaces");
  const [searchInput, setSearchInput] = useState("");
  const [sortBy, setSortBy] = useState("-revenue");
  const { spaceId } = useParams<{ spaceId?: string }>();
  const {
    data: isThereSalesAssistant,
    isLoading: isThereSalesAssistantLoading,
  } = useIsThereSalesAssistant();

  const {
    data: salesAssistantHeaderData,
    isLoading: salesAssistantHeaderDataLoading,
  } = useSalesAssistantHeaderData({
    product_group_id: selectedProductGroup,
    isThereSalesAssistant: !!isThereSalesAssistant,
  });

  const { data: salesCardData, isLoading: salesCardsDataLoading } =
    useSalesAssistantCards({
      isThereSalesAssistant: !!isThereSalesAssistant,
      space_id: spaceId,
      asin: queryParams.get("asin") || undefined,
    });

  const {
    data: salesAssistantListPages,
    isLoading: salesAssistantListLoading,
    isFetching: salesAssistantListFetching,
    fetchNextPage,
  } = useSalesAssistantList({
    daysToShow,
    selectedGroupBy,
    selectedProductGroup,
    searchInput,
    spaceId,
    sortBy,
    isThereSalesAssistant: !!isThereSalesAssistant,
  });

  const salesAssistantList = useMemo(() => {
    return salesAssistantListPages?.pages.reduce(
      (acc, val) => {
        acc.total = val.total;
        acc.data = [...acc.data, ...val.data];

        return acc;
      },
      { total: 0, data: [] } as ListOutput,
    );
  }, [salesAssistantListPages]);

  const { data: productsFilterList, isLoading: productsFilterListLoading } =
    useProductsFilterList({ isThereSalesAssistant });

  const productGroups = useMemo(
    () =>
      productsFilterList?.reduce<
        { id: string; name: string; asins?: number }[]
      >(
        (acc, val) => {
          acc.push({ id: val.id, name: val.name, asins: val.total });
          return acc;
        },
        [
          {
            id: "ALL_PRODUCTS",
            name: "All products",
          },
        ],
      ),
    [productsFilterList],
  );

  const isLoading = useMemo(
    () =>
      salesAssistantListLoading ||
      productsFilterListLoading ||
      salesAssistantHeaderDataLoading ||
      salesCardsDataLoading,
    [
      salesAssistantListLoading,
      productsFilterListLoading,
      salesAssistantHeaderDataLoading,
      salesCardsDataLoading,
    ],
  );

  const selectedProduct = useMemo(() => {
    if (!queryParams.has("asin")) {
      return undefined;
    } else {
      return salesAssistantList?.data?.find(
        (product) => product.title === queryParams.get("asin"),
      );
    }
  }, [queryParams, salesAssistantList]);

  const value = {
    searchInput,
    setSearchInput,
    daysToShow,
    setDaysToShow,
    sortBy,
    setSortBy,
    isLoading,
    salesAssistantList,
    productGroups,
    selectedProductGroup,
    setSelectedProductGroup,
    selectedGroupBy,
    setSelectedGroupBy,
    isThereSalesAssistant,
    isThereSalesAssistantLoading,
    spaceId,
    salesAssistantHeaderData,
    fetchNextPage,
    salesAssistantListFetching,
    selectedProduct,
    salesCardData,
    salesCardsDataLoading,
  };
  return (
    <SalesAssistantContext.Provider value={value}>
      {children}
    </SalesAssistantContext.Provider>
  );
};

export default SalesAssistantProvider;
