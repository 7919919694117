import { useQuery } from "react-query";
import apiRequest, { APIError } from "../../services/apiRequest";
import { RollingDataType } from "./types";

function getChatQuestionList(): Promise<RollingDataType[]> {
  return apiRequest<RollingDataType[]>(`v1/storyboards/story_points`, "GET");
}

export function useChatQuestionList() {
  return useQuery<RollingDataType[], APIError>(
    ["getChatQuestionList"],
    () => getChatQuestionList(),
    {
      staleTime: Infinity,
    }
  );
}

function getChatSessionList(): Promise<RollingDataType[]> {
  return apiRequest<RollingDataType[]>("v1/storyboards", "GET");
}

export function useChatSessionsList() {
  return useQuery<RollingDataType[], APIError>(
    ["useChatSessionsList"],
    () => getChatSessionList(),
    {
      staleTime: Infinity,
    }
  );
}

interface Body {
  story_point_type: string;
  params: { id: string; value: string }[];
  storyboard_id?: string;
  position?: number | null;
}

function getQuestionsBrand(options_url: string): Promise<any> {
  const modifiedUrl = options_url.replace("/api", "");
  return apiRequest<any>(`${modifiedUrl}`, "GET");
}

export function useQuestionsBrand(options_url: string) {
  return useQuery<[], APIError>(
    ["usegetFilterBrand"],
    () => getQuestionsBrand(options_url),
    {
      staleTime: Infinity,
    }
  );
}

export function getOptionsData(
  options_url: string,
  space_id: string
): Promise<any> {
  const params = {
    limit: 100,
    offset: 0,
    space_id,
  };
  const modifiedUrl = options_url.replace("/api/", "");
  return apiRequest<string[]>(`${modifiedUrl}`, "GET", params);
}

function getPublicStoryboard(token: string): Promise<RollingDataType[]> {
  return apiRequest<any>("v1/storyboards/public/" + token, "GET");
}

export function usePublicStoryboard(token: string) {
  return useQuery<any, APIError>(
    ["usePublicStoryboard"],
    () => getPublicStoryboard(token),
    {
      staleTime: Infinity,
    }
  );
}

export function createStoryboardApi(title: string): Promise<any> {
  return apiRequest<any>("v1/storyboards", "POST", undefined, { title });
}

export function getStoryboardApi(id: string): Promise<any> {
  return apiRequest<any>(`v1/storyboards/${id}`, "GET");
}

export function deleteStoryboardApi(storyboard_id: string): Promise<any[]> {
  return apiRequest<any[]>(
    `v1/storyboards/${storyboard_id}`,
    "DELETE",
    undefined
  );
}

export function updateStoryboardApi(
  body: any,
  storyboard_id: string
): Promise<any[]> {
  return apiRequest<any[]>(
    `v1/storyboards/${storyboard_id}`,
    "PUT",
    undefined,
    body
  );
}

export function syncStoryboardApi(storyboard_id: string): Promise<any[]> {
  return apiRequest<any[]>(
    `v1/storyboards/sync/${storyboard_id}`,
    "PUT",
    undefined
  );
}

export function copyStoryboardApi(storyboard_id: string): Promise<any[]> {
  return apiRequest<any[]>(
    `v1/storyboards/copy/${storyboard_id}`,
    "POST",
    undefined
  );
}

///// Story Points /////

export function addStoryPointToStoryboardApi(
  storyPointType: string,
  // shortId: string,
  storyboardId: string,
  // categoryList?:any,
  params?: any,
  index?: number | null
): Promise<any> {
  const body: Body = {
    story_point_type: storyPointType,
    params: [...params],
    position: index,
  };
  if (storyboardId) {
    body.storyboard_id = storyboardId;
  }
  return apiRequest<any>("v1/storyboards/story_point", "POST", undefined, body);
}

export function getStoryPointApi(story_point_id: string): Promise<any> {
  return apiRequest<any>(`v1/storyboards/story_point/${story_point_id}`, "GET");
}

export function deleteStoryPointApi(story_point_id: string): Promise<any[]> {
  return apiRequest<any[]>(
    `v1/storyboards/story_point/${story_point_id}`,
    "DELETE",
    undefined
  );
}

export function updateStoryPointApi(
  story_point_id: string,
  body: any
): Promise<any[]> {
  return apiRequest<any[]>(
    `v1/storyboards/story_point/${story_point_id}`,
    "PUT",
    undefined,
    body
  );
}

export function exportStoryPointApi(story_point_id: string): Promise<any[]> {
  return apiRequest<any[]>(
    `v1/storyboards/story_point/${story_point_id}/export`,
    "POST"
  );
}
