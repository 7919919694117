import { Box, Collapse, Divider, Stack, Typography } from "@mui/material";
import theme from "../../../themes/light.ts";
import SearchBox from "./SearchBox.tsx";
import FiltersAndSort from "./FiltersAndSort.tsx";
import TopCards from "./TopCards.tsx";
import SelectDropDown from "../../../components/SelectDropDown.tsx";
import { useSalesAssistant } from "../SalesAssistantProvider.tsx";
import { useHistory, useLocation } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const TopBar = () => {
  const {
    spaceId,
    productGroups,
    selectedProductGroup,
    setSelectedProductGroup,
    selectedGroupBy,
    setSelectedGroupBy,
    salesAssistantList,
  } = useSalesAssistant();
  const history = useHistory();
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(true);
  return (
    <>
      <Stack
        borderBottom={`1px solid ${theme.palette.borders.lines}`}
        direction="row"
        justifyContent="space-between"
        spacing={2}
        padding="12px 40px"
        alignItems="center"
        overflow="hidden"
      >
        <Stack
          flex={spaceId && salesAssistantList?.data?.[0]?.space_name ? 1.5 : 1}
          direction="row"
          alignItems="center"
          gap="10px"
        >
          <Typography
            variant="headline3"
            component="div"
            display="flex"
            sx={{ cursor: "pointer" }}
            color={
              spaceId && salesAssistantList?.data?.[0]?.space_name
                ? theme.palette.primary.main
                : theme.palette.text.read
            }
            flex={1}
            onClick={() => {
              setSelectedGroupBy("spaces");
              history.push(`/${pathname.split("/")[1]}`);
            }}
          >
            Sales Assistant
          </Typography>
          {spaceId && salesAssistantList?.data?.[0]?.space_name && (
            <>
              <ArrowRightAltIcon />
              <Typography
                variant="headline3"
                component="div"
                display="flex"
                flex={1}
              >
                {salesAssistantList?.data?.[0]?.space_name}
              </Typography>
            </>
          )}
          <Divider orientation="vertical" flexItem />

          <SelectDropDown
            value={selectedProductGroup}
            items={
              productGroups?.map((item) => ({
                value: item.id,
                text: item.name,
              })) ?? []
            }
            onChange={setSelectedProductGroup}
          />
        </Stack>
        <Box flex={1} textAlign="center">
          <SearchBox />
        </Box>
        <Box flex={1} textAlign="end">
          <FiltersAndSort />
        </Box>
      </Stack>
      <Stack
        borderBottom={`1px solid ${theme.palette.borders.lines}`}
        padding="12px 40px"
      >
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <TopCards />
        </Collapse>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="end"
          display="flex"
        >
          <Box flex={1}>
            <SelectDropDown
              title="View By:"
              value={selectedGroupBy}
              items={[
                { value: "spaces", text: "Spaces", disabled: !!spaceId },
                { value: "asins", text: "ASINs" },
              ]}
              onChange={setSelectedGroupBy}
            />
          </Box>
          <Box flex={3} textAlign="center" marginBottom="-13px">
            <Box
              sx={{
                background: "#fff",
                border: `1px solid ${theme.palette.borders.lines}`,
                display: "inline-block",
                borderRadius: "4px 4px 0 0 ",
                padding: "0 24px",
                cursor: "pointer",
              }}
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Box>
          </Box>
          <Box flex={1} />
        </Stack>
      </Stack>
    </>
  );
};

export default TopBar;
