import { ContextHolder } from "@frontegg/rest-api";
import { APIErrorResponse } from "../types";

export class APIError extends Error {
  public statusCode: number;

  public code: string;

  public message: string;

  public type: string;

  constructor(statusCode: number, errorResponse: APIErrorResponse) {
    super(errorResponse.error.message);
    this.statusCode = statusCode;
    this.code = errorResponse.error.code;
    this.message = errorResponse.error.message;
    this.type = errorResponse.error.type;
  }
}

export async function getApiResponse(
  url: string,
  method?: string,
  params?: object,
  body?: object,
  files?: File[]
): Promise<Response> {
  const token = ContextHolder.getAccessToken();

  // Define required headers
  const headers = new Headers();
  headers.append("Authorization", `Bearer ${token}`);
  headers.append("Content-Type", "application/json");

  const urlObject: URL = new URL(`${window.location.origin}/api/${url}`);

  const notNullParams = Object.fromEntries(
    Object.entries(params || {}).filter(([, v]) => v != null)
  );
  if (notNullParams) {
    urlObject.search = new URLSearchParams(
      notNullParams as Record<string, string>
    ).toString();
  }

  // In case we are uploading a file
  const filesBody: FormData = new FormData();
  if (files) {
    // The content-type header will be built by the browser in case it does not exists
    headers.delete("Content-Type");
    files.forEach((file) => filesBody.append("files", file, file.name));
  }

  return fetch(urlObject.toString(), {
    method: method || "GET",
    headers,
    body: files ? filesBody : body ? JSON.stringify(body) : undefined,
  });
}

export default async function apiRequest<T>(
  url: string,
  method?: string,
  params?: object,
  body?: object,
  files?: File[]
): Promise<T> {
  const response = await getApiResponse(url, method, params, body, files);

  // In case operation failed (status code >= 300 means errors)
  if (response.status >= 300) {
    const parsedReponse: APIErrorResponse = await response
      ?.json()
      ?.then((data) => {
        if (Array.isArray(data.detail)) {
          return {
            error: {
              message: JSON.stringify(data.detail[0]),
              ...data.detail[0],
            },
          };
        }
        return data;
      });
    throw new APIError(response.status, parsedReponse);
  }

  const parsedReponse = await response?.json();
  return parsedReponse;
}
