import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import theme from "../../themes/light";
import { ClearIcon } from "@mui/x-date-pickers/icons";
import { CopyIcon, ShareCopyIcon } from "../../assets";
import { enqueueSnackbar } from "notistack";
import { SharedInfo } from "./ChatHistory";
// import { updateStoryboardById, useChatSessionsList } from "./useChatApi";
// import { useQueryParams } from "../../hooks";
// import { useSnackbar } from "notistack";
// import {

//   useInsightChatContext,
// } from "./hooks/selectors";
interface PropTypes {
  open: boolean;
  onClose: () => void;
  // href: string;
  // text: string;/
  sharedInfo: SharedInfo;
}

const ShareDialog = (props: PropTypes) => {
  // const { refetch } = useChatSessionsList();

  // const {

  //   fetchSessionData,
  // } = useInsightChatContext();
  const { open, onClose, sharedInfo } = props;
  // const [title, setTitle] = useState<string>("")
  // const queryParams = useQueryParams();
  // const snackbar = useSnackbar();

  // const sessionId = queryParams.get("storyboardId") || "";
  // const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const value = e.target.value;
  //   setTitle(value)
  // }
  // const handleEditMessage = async () => {
  //   const body = {
  //     title: title,
  //     share_link: sharedInfo?.link,
  //   };
  //   if(sharedInfo?.title === title) {
  //     return;
  //   }
  //   const res = await updateStoryboardById(body, sessionId);
  //   if (res) {
  //     refetch();
  //     snackbar.enqueueSnackbar("Title updated!", {
  //       variant: "success",
  //     });
  //   } else {
  //     snackbar.enqueueSnackbar("Something went wrong: ", {
  //       variant: "error",
  //     });
  //     setTitle(sharedInfo?.title);
  //   }
  //   fetchSessionData(sessionId);
  // };
  // useEffect(() => {
  //   setTitle(sharedInfo?.title)
  // }, [open,sharedInfo])

  const action = () => (
    <>
      <Link
        href={sharedInfo?.link}
        target={"_blank"}
        sx={{
          color: theme.palette.primary.contrastText,
          // border: `1px solid ${theme.palette.primary.contrastText}`,
          textDecoration: "none",
          padding: "5px 10px",
          borderRadius: "4px",
          background: theme.palette.secondary.main,
        }}
      >
        Open Link
      </Link>
    </>
  );

  const handleCopy = () => {
    navigator.clipboard.writeText(sharedInfo?.link).then(() =>
      enqueueSnackbar("Link copied successfully", {
        variant: "success",
        action,
      })
    );
  };

  // const handleCopyAndUpdate = () => {
  //   handleEditMessage();
  //   handleCopy();
  // };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        // width: "500px",
        // height: "500px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto",
        padding: "24px",
        "& .MuiDialogContent-root": {
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        },
        "& .MuiPaper-root": {
          gap: "24px",
          padding: "24px",
          borderRadius: "12px",
          // height: "375px"
        },
      }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{ position: "relative", padding: "0px" }}
      >
        <Typography
          variant="h6"
          sx={{ lineHeight: "24px", fontSize: "18px", fontWeight: "600" }}
        >
          Share
        </Typography>
        <Button
          sx={{
            position: "absolute",
            padding: "0px",
            minWidth: "10px",
            minHeight: "10px",
            top: "-14px",
            right: "-14px",
            "&:hover": {
              backgroundColor: "transparent",
            },
            "& svg": {
              fontSize: "16px",
            },
          }}
          onClick={onClose}
        >
          <ClearIcon />
        </Button>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: "0px",
          "& .MuiOutlinedInput-root": {
            backgroundColor: theme.palette.grey[50],
            borderRadius: "8px",
            padding: "0 !important",
          },
        }}
      >
        {/* <FormControl sx={{ width: "440px", gap: "8px" }}>
          <Typography
            variant="body4"
            sx={{ fontWeight: "500", fontSize: "13px", color: "#75717B" }}>
            Title
          </Typography>
          <TextField
            id="outlined-textarea"
            multiline
            InputLabelProps={{ shrink: false }}
            value={title}
            onChange={handleChangeTitle}
            sx={{
              border: "8px",
              padding: "0px",
              borderColor: "none",
              "& .MuiInputBase-input": {
                border: "none",
                color: theme.palette.secondary.main,
                fontSize: "20px",
                fontWeight: "600",
                lineHeight: "28px",
                padding: "12px 16px",
                borderRadius: "8px !important",
                "&:focus": {
                  backgroundColor: "rgba(127, 60, 238, 0.05)",
                  border: `1px solid ${theme.palette.primary.main}`
                },
                "&:hover": {
                  border: `1px solid ${theme.palette.primary.main}`
                }
              },
              "& .MuiTextField-root": {
                borderRadius: "8px !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          />
        </FormControl> */}
        <FormControl sx={{ width: "440px", gap: "8px" }}>
          <Typography
            variant="body4"
            sx={{ fontWeight: "500", fontSize: "13px", color: "#75717B" }}
          >
            Your link
          </Typography>
          <TextField
            id="outlined-textarea"
            multiline
            InputLabelProps={{ shrink: false }}
            InputProps={{
              startAdornment: (
                <Box
                  sx={{ display: "flex", marginLeft: "16px" }}
                  onClick={handleCopy}
                >
                  <ShareCopyIcon />
                </Box>
              ),
            }}
            value={
              sharedInfo?.link?.slice(0, 45) + "..." ||
              "http://app.noogata.com/quo-unde-autem-nobis"
            }
            sx={{
              border: "8px",
              padding: "0px",
              borderColor: "none",
              "& .MuiInputBase-input": {
                color: theme.palette.secondary.main,
                fontSize: "15px",
                fontWeight: "400",
                lineHeight: "24px",
                padding: "12px 16px 12px 0",
              },
              "& .MuiTextField-root": {
                borderRadius: "8px !important",
                backgroundColor: "red",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiOutlinedInput-root": {
                gap: "8px",
              },
              "& svg": {
                "&:hover": {
                  cursor: "pointer",
                },
              },
            }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ padding: "0px" }}>
        {/* <Button onClick={onClose} variant="outlined">
          Cancel
        </Button> */}
        <Button
          onClick={handleCopy}
          color="success"
          variant="contained"
          sx={{
            display: "flex",
            gap: "10px",
            backgroundColor: "#2C224C",
            "&:hover": { backgroundColor: "#2C224C" },
            "& svg": {
              width: "15px",
              height: "15px",
            },
          }}
        >
          <CopyIcon />
          <Box>Copy</Box>
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ShareDialog;
