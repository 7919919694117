import TabsContainer from "@mui/material/Tabs";
import Tab, { TabProps } from "@mui/material/Tab";
import { ContentType } from "../../types.ts";
import { usePerfectContent } from "../../PerfectContentProvider.tsx";

interface Tab extends TabProps {
  value: ContentType;
}

export const tabs: Tab[] = [
  {
    label: "Title",
    value: "title",
  },
  {
    label: "About",
    value: "about_this_item",
  },
  {
    label: "Description",
    value: "description",
  },
];

const container = {
  '& [class*="MuiTabs-root"]': {
    minHeight: "36px !important",
  },
  '& [class*="MuiTabs-flexContainer"]': {
    gap: "20px",
  },
  '& [class*="MuiTabs-scroller"]': {
    marginBottom: "10px",
    height: "36px !important",
  },
  '&&& [class*="MuiTabs-indicator"]': {
    height: "2px",
    bottom: "0px",
  },
  '& [class*="MuiTab-wrapper"]': {
    textTransform: "none",
    fontSize: "15px",
    transition: "0.2s font-size",
  },
  '& [class*="MuiTab-selected"], & [class*="Mui-selected"]': {
    '& [class*="MuiTab-wrapper"]': {
      fontSize: "15px",
      color: "#7F3CEE",
    },
  },

  '& [class*="MuiButtonBase-root"]': {
    minWidth: "auto",
    padding: "0px",
    fontSize: "14px",
    fontWeight: " 600",
    textTransform: "capitalize",
    minHeight: "36px",
    lineHeight: "20px",
  },
};

const Tabs = () => {
  const { activeTab, setActiveTab } = usePerfectContent();

  return (
    <TabsContainer
      sx={container}
      value={activeTab || "title"}
      indicatorColor="primary"
      textColor="primary"
    >
      {tabs.map(({ label, value }: any) => (
        <Tab
          disableRipple
          key={label as string}
          label={label}
          onClick={() => {
            setActiveTab(value);
          }}
          value={value}
        />
      ))}
    </TabsContainer>
  );
};

export default Tabs;
