import { useCallback, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { Box, Stack } from "@mui/material";
import { agGridBoxStyle } from "../SpacesV2/PerfectContent/enums.tsx";
import { usePerfectContent } from "./PerfectContentProvider.tsx";

export const colDefs = [
  {
    headerName: "Keyword",
    field: "search_term",
    minWidth: 280,
    flex: 1,
    valueFormatter: undefined,
    cellRenderer: undefined,
    sortable: false,
    checkboxSelection: true,
  },
  {
    headerName: "Branded",
    field: "brand",
    flex: 1,
    valueFormatter: undefined,
    cellRenderer: undefined,
  },
  {
    headerName: "Impressions",
    field: "impressions",
    flex: 1,
    valueFormatter: (p: any) => Number(p.value).toLocaleString(),
    cellRenderer: undefined,
    sortable: true,
  },
];

const KeywordsList = () => {
  const gridRef = useRef<AgGridReact>(null);
  const { keywordsList, selectedKeywords, setSelectedKeywords } =
    usePerfectContent();

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current!.api.getSelectedRows();
    const keywords = selectedRows.map((keyword) => keyword?.search_term);
    setSelectedKeywords(keywords);
  }, [gridRef]);

  return (
    <Stack height="100%">
      <Box
        flex={1}
        overflow="auto"
        className={"ag-theme-quartz"}
        sx={{ ...agGridBoxStyle, padding: 0, borderRadius: "12px" }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={keywordsList || []}
          columnDefs={colDefs}
          rowSelection="multiple"
          rowMultiSelectWithClick
          onSelectionChanged={onSelectionChanged}
        />
      </Box>
      <Box flex={0} paddingTop="4px">
        {selectedKeywords.length} selected keywords
      </Box>
    </Stack>
  );
};

export default KeywordsList;
