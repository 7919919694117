import {
  Box,
  Button,
  Collapse,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Features } from "./types.ts";
import AddAttributeForm from "./AddAttributeForm.tsx";
import AddAttributeFormJSON from "./AddAttributeFormJson.tsx";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import theme from "../../themes/light.ts";
import { useMutation } from "react-query";
import { runAttributeExtractionApi } from "./useSpaces.ts";
import { useSpace } from "./SpaceProvider.tsx";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { UploadIcon } from "../../assets";

const ExtractFeaturesDrawer = ({
  setIsDrawerOpen,
}: {
  setIsDrawerOpen: (isDrawerOpen: boolean) => void;
}) => {
  const [expanded, setExpanded] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const { spaceId } = useParams<{ spaceId?: string }>();
  if (!spaceId) {
    return null;
  }
  const { spacesListRefetch } = useSpace();
  const snackbar = useSnackbar();
  const { control, handleSubmit, formState, register } = useForm<{
    features: Features[];
  }>({
    mode: "onChange",
    defaultValues: { features: [] },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "features",
  });
  useEffect(() => {
    register("features", { required: true });
  }, [true]);
  const { isSubmitting, isLoading, isValid } = formState;
  const startRunMmutation = useMutation(
    ({ spaceId, features }: { spaceId: string; features: Features[] }) =>
      runAttributeExtractionApi({
        spaceId,
        features,
      })
  );
  return (
    <div>
      <Box
        sx={{
          mx: 4,
          mt: 3,
          mb: 2,
          flex: 1,
          width: (t) => t.spacing(65),
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 4 }}
        >
          <IconButton
            color="default"
            size="small"
            onClick={() => setIsDrawerOpen(false)}
          >
            <Close />
          </IconButton>
        </Box>
        <form
          onSubmit={handleSubmit((values) =>
            startRunMmutation
              .mutateAsync({ spaceId, features: values.features })
              .then(() => {
                setIsDrawerOpen(false);
                snackbar.enqueueSnackbar(
                  "We started to run attribute extraction",
                  {
                    variant: "success",
                  }
                );
                spacesListRefetch();
              })
              .catch((e) => {
                snackbar.enqueueSnackbar("Something went wrong: " + e, {
                  variant: "error",
                });
              })
          )}
        >
          <Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
                Extract features for this space
              </Typography>
              {fields?.length < 5 && (
                <div>
                  <IconButton onClick={() => setExpanded2(!expanded2)}>
                    <UploadIcon />
                  </IconButton>
                  <IconButton onClick={() => setExpanded(!expanded)}>
                    <AddIcon />
                  </IconButton>
                </div>
              )}
            </Stack>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <AddAttributeForm add={append} setExpanded={setExpanded} />
            </Collapse>
            <Collapse in={expanded2} timeout="auto" unmountOnExit>
              <AddAttributeFormJSON add={append} setExpanded={setExpanded2} />
            </Collapse>

            <Stack mt={2} spacing={1}>
              {fields.map((item, index) => (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  key={item.id}
                  sx={{
                    border: `2px solid ${theme.palette.grey[200]}`,
                    borderRadius: "12px",
                  }}
                  p={2}
                >
                  <Stack>
                    <Box display="flex" gap={1}>
                      <Typography fontWeight="bold">Attribute</Typography>
                      <Typography>{item.attribute}</Typography>
                    </Box>
                    <Box display="flex" gap={1}>
                      <Typography fontWeight="bold">Description</Typography>
                      <Typography>{item.description}</Typography>
                    </Box>
                    {item.accepted_values.length > 0 && (
                      <Box display="flex" gap={1}>
                        <Typography fontWeight="bold">
                          Accepted values
                        </Typography>
                        <Typography>{item.accepted_values}</Typography>
                      </Box>
                    )}
                    <Box display="flex" gap={1}>
                      <Typography fontWeight="bold">Closed list</Typography>
                      <Typography>
                        {JSON.stringify(item.closed_list)}
                      </Typography>
                    </Box>
                    <Box display="flex" gap={1}>
                      <Typography fontWeight="bold">Attribute type</Typography>
                      <Typography>{item.type}</Typography>
                    </Box>
                  </Stack>
                  <Button
                    variant="outlined"
                    color="warning"
                    onClick={() => remove(index)}
                    sx={{ display: "flex", gap: 1 }}
                  >
                    <DeleteIcon />
                    Delete
                  </Button>
                </Stack>
              ))}
            </Stack>
            {!expanded && (
              <Button
                type="submit"
                variant="contained"
                sx={{ mt: 2 }}
                disabled={
                  fields.length < 1 || isSubmitting || isLoading || !isValid
                }
              >
                Start extracting
              </Button>
            )}
          </Stack>
        </form>
      </Box>
    </div>
  );
};

export default ExtractFeaturesDrawer;
