import { Box, Paper, Stack, Tooltip } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import { RowData } from "../../types.ts";
import theme from "../../../../themes/light.ts";

const layout = {
  border: `1px solid ${theme.palette.borders.lines}`,
  background: "#fff",
  borderRadius: "8px",
  padding: "7px",
  width: "96px",
  height: "96px",
  "&.small": {
    borderRadius: "4px",
    padding: "6px",
    width: "64px",
    height: "64px",
  },
  "&.medium": {
    borderRadius: "8px",
    padding: "8px",
    width: "64px",
    height: "64px",
  },
};

const container = {
  maxWidth: "80px",
  maxHeight: "80px",
  width: "100%",
  height: "auto",
  "&.small": {
    maxWidth: "40px",
    maxHeight: "40px",
  },
  "&.medium": {
    maxWidth: "56px",
    maxHeight: "56px",
  },
};

const ProductImage = ({
  data,
  size = "large",
  group = false,
  // useTooltip = false,
}: {
  data?: RowData;
  size?: "large" | "medium" | "small";
  group?: boolean;
  // useTooltip?: boolean;
}) => {
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            p: 0,
          },
        },
      }}
      title={
        <Paper variant="elevation" elevation={5}>
          <Stack justifyContent="center" sx={{ height: 250, width: 250 }}>
            {data?.image_url ? (
              <Box
                component="img"
                alt="Product for the opportunity"
                src={data?.image_url}
                sx={{
                  height: "auto",
                  width: "auto",
                  objectFit: "contain",
                }}
              />
            ) : (
              <ImageIcon
                sx={{
                  height: "auto",
                  width: "auto",
                  objectFit: "contain",
                  padding: 10,
                }}
              />
            )}
          </Stack>
        </Paper>
      }
    >
      <Box
        position="relative"
        margin={group ? "5px 5px 16px 5px" : "0 0 16px 0"}
        sx={{ ...layout, border: "0 none" }}
      >
        {group && (
          <Stack
            justifyContent="center"
            className={size}
            marginTop="-5px"
            marginLeft="-5px"
            position="absolute"
            zIndex="0"
            sx={layout}
          />
        )}
        <Stack
          justifyContent="center"
          sx={layout}
          zIndex="1"
          position="absolute"
        >
          {data?.image_url ? (
            <Box
              component="img"
              alt="Product for the opportunity"
              src={data?.image_url}
              sx={{ ...container, objectFit: "contain" }}
            />
          ) : (
            <ImageIcon
              sx={{
                height: "auto",
                width: "auto",
                objectFit: "contain",
              }}
            />
          )}
        </Stack>
      </Box>
    </Tooltip>
  );
};

export default ProductImage;
