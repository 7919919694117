import { Stack } from "@mui/material";
import theme from "../../../themes/light.ts";
import ListHead from "./ListHead.tsx";
import List from "./List.tsx";
import { useSalesAssistant } from "../SalesAssistantProvider.tsx";

const Index = () => {
  const { salesAssistantList, isLoading } = useSalesAssistant();
  return (
    <Stack
      sx={{
        overflow: "hidden",
        background: theme.palette.backgrounds.secondary,
        flex: 1,
        padding: "12px 40px",
      }}
    >
      <ListHead />
      {!isLoading && (
        <List
          data={salesAssistantList?.data}
          total={salesAssistantList?.total}
        />
      )}
    </Stack>
  );
};

export default Index;
