import { useForm } from "react-hook-form";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { Features } from "./types.ts";
import { useSnackbar } from "notistack";

const AddAttributeFormJson = ({
  add,
  setExpanded,
}: {
  add: (attribute: Features) => void;
  setExpanded: (expanded: boolean) => void;
}) => {
  const snackbar = useSnackbar();

  const { formState, register, handleSubmit } = useForm({
    defaultValues: {
      json: "",
    },
  });

  const isJsonString = (str: string) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  return (
    <Stack spacing={1}>
      <TextField
        placeholder="Attribute JSON"
        multiline
        {...register(`json`, { required: true, validate: isJsonString })}
        rows={3}
        error={!formState.isValid}
      />
      {!formState.isValid && (
        <Typography color="error">Invalid JSON</Typography>
      )}
      <Button
        variant="contained"
        type="button"
        onClick={handleSubmit((data) => {
          try {
            let i = 0;
            const attributes = JSON.parse(data.json);
            attributes.forEach((attribute: any) => {
              if (i < 5) {
                add(attribute as Features);
              }
              i++;
            });
          } catch (e) {
            snackbar.enqueueSnackbar("Error occurred" + e, {
              variant: "error",
            });
          }
          setExpanded(false);
        })}
        disabled={!formState.isValid}
      >
        Add features
      </Button>
    </Stack>
  );
};

export default AddAttributeFormJson;
