import theme from "../../../themes/light.ts";
import { Stack, Typography } from "@mui/material";
import { useSalesAssistant } from "../SalesAssistantProvider.tsx";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useCallback } from "react";

const SortableTitle = ({ title, value }: { title: string; value: string }) => {
  const { sortBy, setSortBy } = useSalesAssistant();

  const onClick = useCallback(() => {
    if (sortBy.search(value) >= 0) {
      setSortBy(sortBy[0] === "-" ? value : `-${value}`);
    } else {
      setSortBy(`-${value}`);
    }
  }, [sortBy, value]);

  return (
    <Stack
      direction="row"
      flex={1}
      alignItems="center"
      gap="5px"
      sx={{ cursor: "pointer" }}
      onClick={onClick}
    >
      <Typography variant="caption" color={theme.palette.grey[400]}>
        {title}
      </Typography>
      {sortBy.search(value) >= 0 && (
        <>{sortBy[0] === "-" ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}</>
      )}
    </Stack>
  );
};

export default SortableTitle;
