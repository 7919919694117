import * as React from "react";
import { Editable, Slate } from "slate-react";
import Word, { WordProps } from "./Word";
import SkeletonContent from "./SkeletonContent";
import { Box } from "@mui/material";
import { usePerfectContent } from "../../PerfectContentProvider.tsx";
import { Descendant } from "../../types.ts";

const editorStyle = {
  borderBottom: "none",
  borderRadius: "0px 8px 0 0",
  overflow: "auto",
  paddingTop: "8px",
  paddingRight: "20px",
  outline: "none",
  whiteSpace: "break-spaces",
  minHeight: "300px",
};

const ContentEditor = () => {
  const { isLoading, editor, editorContentValue, setEditorContentValue } =
    usePerfectContent();

  // Updates the app's highlighted keywords useState with debounce
  // const contentHighlightedKeyWords = useUpdateHighlightedKeywords({
  //   keywords,
  //   contentPlainTextValue,
  //   setHighlightedKeywords,
  // });

  // Checks if a word is a highlighted and returns the node ranges of it
  // const decorate = useDecorate({ contentHighlightedKeyWords });

  return (
    <Slate
      editor={editor}
      initialValue={editorContentValue}
      onChange={(value: any) => setEditorContentValue(value as Descendant[])}
    >
      {isLoading ? (
        <Box sx={editorStyle}>
          <SkeletonContent />
        </Box>
      ) : (
        <Editable
          style={editorStyle}
          // decorate={decorate}
          // render custom component for every word
          renderLeaf={(props: any) => (
            <Word
              {...(props as WordProps)}
              editor={editor}
              // contentHighlightedKeyWords={contentHighlightedKeyWords}
            />
          )}
        />
      )}
    </Slate>
  );
};

export default React.memo(ContentEditor);
