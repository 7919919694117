import { Box, Grid, Stack } from "@mui/material";
import { EcommerceProduct } from "../../types/ecommerce";
import { useStyles } from "./useStyles";
import { Typography } from "@mui/material";
import { CheckedIcon } from "../../assets";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";
import { enqueueSnackbar } from "notistack";
import { Dispatch, SetStateAction } from "react";
import { Tooltip } from "@mui/material";

type EComProductProp = {
  product: EcommerceProduct;
  selectedProducts: string[];
  setSelectedProducts: Dispatch<SetStateAction<string[]>>;
};

const ProductPlaceholder = ({ asin }: { asin: string }) => {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70px",
        }}
      >
        <CropOriginalIcon style={{ color: "#8E4FF6", fontSize: "54px" }} />
      </div>
      <Typography
        style={{
          textAlign: "center",
          fontWeight: "bold",
          color: "#8E4FF6", // Change this to your desired color
        }}
      >
        {asin}
      </Typography>
    </div>
  );
};

const EComProduct = ({
  product,
  selectedProducts,
  setSelectedProducts,
}: EComProductProp) => {
  const classes = useStyles();

  const handleCopy = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    navigator.clipboard.writeText(product.asin).then(() =>
      enqueueSnackbar("Asin copied successfully", {
        variant: "success",
      })
    );
  };

  const brand = product?.brand?.toLowerCase();
  const displayTitle =
    brand && product?.title?.toLowerCase().startsWith(brand)
      ? product?.title?.substring(brand.length)
      : " " + product?.title;

  return (
    <Grid item xs={12} sm={4} md={3} lg={2} xl={12 / 9}>
      <Box
        className={product.title ? "" : classes.shimmer}
        onClick={() => {
          const tempList = (prev: string[]): string[] =>
            prev.includes(product.asin)
              ? prev.filter((e) => e !== product.asin)
              : [...prev, product.asin];
          setSelectedProducts(
            (prev) => Array.from(new Set(tempList(prev))) as unknown as string[]
          );
        }}
        sx={{
          height: "191px",
          width: "100%",
          padding: "14px 0",
          boxShadow:
            "0px 2px 10px -6px rgba(44, 34, 76, 0.05), 0px 2px 5px -4px rgba(44, 34, 76, 0.08)",
          background: "#FFF",
          borderRadius: "12px",
          gap: "12px",
          position: "relative",
          overFlow: "hidden",
          cursor: "pointer",
          "#secondary": {
            position: "absolute",
            bottom: "0",
            left: 0,
            display: "flex",
            padding: "8.5px 12px 0 12px",
            opacity: 0,
            background: "white",
            transition: "opacity 0.15s ease-out allow-discrete",
            width: "calc(100% - 4px)",
            overflow: "hidden",
            textOverflow: "ellipsis",
            borderRadius: "0px 0px 10px 10px",
            boxShadow: "0px -4px 6px 0px rgba(0, 0, 0, 0.09)",
            height: "fit-content",
            marginBottom: "7px",
            marginLeft: "2px",
          },
          "&:hover *": {
            visibility: "visible",
          },
          "&:hover #secondary": {
            opacity: 1,
            bottom: 0,
            display: "flex",
          },
          ":hover": {
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              borderRadius: "inherit",
              padding: "2px",
              background:
                "linear-gradient(to bottom, rgba(142, 79, 246, 1) 0%, rgba(142, 79, 246, 0) 100%)",
              WebkitMask:
                "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
              mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
              WebkitMaskComposite: "xor",
              maskComposite: "exclude",
            },
            "& .MuiCheckbox-root": {
              display: "inline-flex",
            },
          },
          ":hover svg": {
            opacity: "1 !important",
          },
        }}
      >
        <Box
          sx={{
            height: "20px",
            width: "20px",
            position: "absolute",
            top: "10px",
            left: "10px",
            boxShadow: "0px 1px 2px 0px rgba(44, 34, 76, 0.08)",
            borderRadius: "4px",
            background: "#FFF",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span style={{ height: "16px", width: "16px" }}>
            {selectedProducts?.includes(product.asin) && <CheckedIcon />}
          </span>
        </Box>
        <Stack id="main" height="100%" justifyContent="center">
          {product?.image_url ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                component={"img"}
                src={product?.image_url}
                sx={{
                  height: "127px",
                  width: "100%",
                  padding: "0 5px 0 10px",
                  maxHeight: "127px",
                  objectFit: "contain",
                }}
              />
              <Stack
                sx={{
                  display: "flex",
                  width: "100%",
                  marginLeft: "16px",
                  marginTop: "12px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: 600,
                  }}
                  className={classes.truncateMultiLine}
                >
                  <span
                    className={classes.brand}
                    style={{
                      display: "inline-flex",
                      height: "16px",
                      padding: "4px",
                    }}
                  >
                    {product.brand}
                  </span>
                </Typography>
              </Stack>
            </Box>
          ) : (
            <ProductPlaceholder asin={product.asin} />
          )}
        </Stack>
        <Stack id="secondary">
          <Stack>
            <Box>
              <Tooltip
                placement="top"
                title={product.title}
                PopperProps={{
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -10], // Change the second value to adjust the vertical distance
                      },
                    },
                  ],
                }}
              >
                <Typography
                  fontWeight="bold"
                  fontSize={12}
                  className={classes.truncateMultiLine}
                >
                  {product.brand && (
                    <span className={classes.brand}>{product.brand}</span>
                  )}
                  <span style={{ display: "inline" }}>{displayTitle}</span>
                </Typography>
              </Tooltip>
            </Box>
            <Stack>
              <Typography
                sx={{
                  fontSize: "10px",
                  color: "#2C224C",
                  fontWeight: 400,
                  textOverflow: "ellipsis",
                  lineHeight: "16px",
                }}
                className={classes.truncateMultiLine}
              >
                {displayTitle}
              </Typography>
            </Stack>
          </Stack>
          <Stack>
            <Typography
              sx={{
                fontSize: "9px",
                color: "#2C224C",
                fontWeight: 400,
                textOverflow: "ellipsis",
                textDecoration: "underline",
                lineHeight: "17.6px",
              }}
              className={classes.truncateMultiLine}
              onClick={handleCopy}
            >
              {product.asin}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Grid>
  );
};

export default EComProduct;
