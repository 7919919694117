import { AgGridReact } from "ag-grid-react";
import theme from "../../../themes/light.ts";
import { Box, LinearProgress, Skeleton } from "@mui/material";
import { useSpaceASINsQuery } from "../useSpaces.ts";
import { SpaceASINsResponse } from "../types.ts";
import { agGridBoxStyle, colDefs } from "./enums.tsx";
import { useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";

const AsinsListPerfectContentView = ({ spaceId }: { spaceId: string }) => {
  const gridRef = useRef<AgGridReact>(null);
  const history = useHistory();

  const { data: asinsList, isFetching: isFetchingAsins } = useSpaceASINsQuery({
    spaceId: spaceId,
  });

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current!.api.getSelectedRows();
    history.push(`/perfect-content-v2/${spaceId}/${selectedRows[0].asin}`);
  }, []);

  return (
    <Box
      sx={{
        flex: 1,
        background: theme.palette.grey[50],
        overflow: "auto",
      }}
    >
      {isFetchingAsins && <LinearProgress />}
      <Box
        sx={{
          display: "flex",
          padding: "1rem 2rem 0 2rem",
          justifyContent: "space-between",
          height: "50px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {isFetchingAsins ? (
            <Skeleton
              animation="wave"
              width="160px"
              sx={{
                height: "10px",
                borderRadius: "8px",
                transform: "unset",
              }}
            />
          ) : (
            <>
              Showing {(asinsList?.pages[0] as SpaceASINsResponse)?.total ?? ""}
            </>
          )}
        </Box>
      </Box>
      <Box className={"ag-theme-quartz"} sx={agGridBoxStyle}>
        <AgGridReact
          ref={gridRef}
          rowData={(asinsList?.pages[0] as SpaceASINsResponse)?.asins ?? []}
          columnDefs={colDefs}
          rowSelection={"single"}
          onSelectionChanged={onSelectionChanged}
        />
      </Box>
    </Box>
  );
};

export default AsinsListPerfectContentView;
