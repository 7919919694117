import { useHistory, useRouteMatch } from "react-router";

import SVG from "react-inlinesvg";
import { LeftNavBarItem } from "./LeftNavBarItem";
import { FC } from "react";

export const InsightsChatNavItem: FC = () => {
  const pageMatch = useRouteMatch({ path: ["/chat"] });
  const history = useHistory();

  // const label = true ? "Stories" : "Research Assistant"
  const label = "AI Stories";

  return (
    <LeftNavBarItem
      selected={!!pageMatch}
      icon={
        <SVG src={"/sales/sparkle.svg"} fill="inherit" width={20} height={20} />
      }
      onClick={() => history.push("/chat")}
      label={label}
    />
  );
};
