import {ListItemButton, ListItemIcon, ListItemText, Skeleton,} from "@mui/material";

import SVG from "react-inlinesvg";
import {useRouteMatch} from "react-router";
import {useHistory} from "react-router-dom";
import {sortBy} from "lodash";
import {useBoards} from "../../../services/boards";
import {CircuitBoard} from "../../../types";
import {BoardsMenu} from "./BoardsMenu";
import {ExpendableList, ExpendableListHeader} from "./ExpendableList";
import {StyledTooltip} from "./StyledTooltip";
import {ApplicationType} from "./types";
import {FC, useState} from "react"

export const PerfectContentIcon = (props: any) => (
    <SVG
        src={"/perfect-content.svg"}
        width={20}
        height={20}
        fill="inherit"
        {...props}
    />
);

export const usePerfectContentBoardsWithPublishedRun = () =>
  useBoards(undefined, {
    select: (blocks) =>
      sortBy(
        blocks.filter(
          (b) =>
            b.template_id.search("asins-content-optimization") > -1 &&
            b.published_run_id
        ),
        "displayName"
      ),
  });

export const PerfectContentList: FC<{ open: boolean }> = ({ open }) => {
  const [openList, setOpenList] = useState(false);
  const pageMatch = useRouteMatch(
    ["", ApplicationType.PERFECT_CONTENT].join("/")
  );
  const blockMatch = useRouteMatch<{ boardId: string }>(
    ["", ApplicationType.PERFECT_CONTENT, ":boardId"].join("/")
  );
  const history = useHistory();

  const { data: blocks = [] as CircuitBoard[], isLoading } =
    usePerfectContentBoardsWithPublishedRun();

  const handlePerfectContentMenu = () => {
    setOpenList(!openList);
  };

  if (isLoading) {
    return (
      <ListItemButton>
        <Skeleton height="100%" width="100%" />
      </ListItemButton>
    );
  }

  if (!blocks.length) {
    return null;
  }
  if (!open) {
    return (
      <StyledTooltip
        title={
          <BoardsMenu
            blocks={blocks}
            prefix={ApplicationType.PERFECT_CONTENT}
          />
        }
      >
        <ListItemButton
          onClick={() => {
            history.push(["", ApplicationType.PERFECT_CONTENT].join("/"));
          }}
          selected={!!pageMatch}
        >
          <ListItemIcon>
            <PerfectContentIcon />
          </ListItemIcon>
          <ListItemText
            primary="Perfect Content"
            sx={{ whiteSpace: "nowrap" }}
          />
        </ListItemButton>
      </StyledTooltip>
    );
  }

  return (
    <ExpendableList
        open={openList}
        label={(props: any) => (
            <ExpendableListHeader
                icon={<PerfectContentIcon/>}
                onClick={handlePerfectContentMenu}
                label="Perfect Content"
                {...props}
            />
        )}
    >
      {blocks.map((b, i) => (
        <ListItemButton
          key={i}
          selected={b.identifier === blockMatch?.params.boardId}
          onClick={() =>
            history.push(
              ["", ApplicationType.PERFECT_CONTENT, b.identifier].join("/")
            )
          }
        >
          {b.displayName}
        </ListItemButton>
      ))}
    </ExpendableList>
  );
};
