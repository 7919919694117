import { Dashboard } from "@mui/icons-material";
import { List, ListItemButton, ListItemIcon, ListItemText, Paper, Skeleton, } from "@mui/material";
import { uniq } from "lodash";
import { FC, useState } from "react";
import { useRouteMatch } from "react-router";
import { useHistory } from "react-router-dom";
import { useDashboards } from "../../Dashboards/api";
import { ExpendableList, ExpendableListHeader } from "./ExpendableList";
import { StyledTooltip } from "./StyledTooltip";
import { useQueryParams, useSplitEnabled } from "../../../hooks";
import { Split } from "../../../FeatureFlags/enums";

const DashboardsMenu: FC<{
  dashboards: string[];
  closeTooltip: () => void;
  navHandlerProvider: (tabName: string) => () => void
}> = ({ dashboards, closeTooltip, navHandlerProvider }) => {

  const match = useRouteMatch<{ dashboardName: string }>(
    "/dashboards/:dashboardName"
  );
  return (
    <Paper elevation={2}>
      <List>
        {dashboards.map((d, index) => {
          const navHandler = navHandlerProvider(d)
          return <ListItemButton
            key={index}
            selected={match?.params?.dashboardName === d}
            onClick={(e) => {
              e.stopPropagation();
              closeTooltip();
              navHandler()
            }}
          >
            {d}
          </ListItemButton>
        })}
      </List>
    </Paper>
  );
};

export const DigitalShelfList: FC<{ open: boolean }> = ({ open }) => {
  const newNavigationEnabled = useSplitEnabled(Split.DASHBOARDS_NEW_NAVIGATION);

  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const custom = useQueryParams().get("custom") === "true";
  const [openList, setOpenList] = useState(false);
  const pageMatch = useRouteMatch<{ tabName: string, tabId: string }>([
    "/dashboards/:tabName/:tabId",
  ]);
  const history = useHistory();

  const { data: dashboards = [], isLoading } = useDashboards({
    options: {
      select: (data) => data.filter((d) => !d.isCustom()),
    },
  });

  const tabNames = newNavigationEnabled ? uniq(
    dashboards.filter(({ name }) => !!name).map(({ name }) => name as string)
  ).sort() : uniq(
    dashboards.filter(({ tab }) => !!tab).map(({ tab }) => tab as string)
  ).sort();

  const handleDigitalShelf = () => {
    setOpenList(!openList);
  };

  const getNavHandler = (tabName: string) => newNavigationEnabled ?
    () => {
      const selectedDashboardId = pageMatch?.params.tabId
      const currentDashboard = dashboards.find(d => d.id == Number(selectedDashboardId))
      const dashboard = dashboards.find(d =>
        d.name == tabName && (d.tab == currentDashboard?.tab || !currentDashboard)
      ) ?? dashboards.find(d => d.name == tabName)
      history.push(["", "dashboards", dashboard?.tab, dashboard?.id].join("/") +
        `?boardName=${dashboard?.name}&custom=${dashboard?.custom}`)
    } :
    () => history.push(["", "dashboards", tabName].join("/"))



  if (isLoading) {
    return (
      <ListItemButton>
        <Skeleton height="100%" width="100%" />
      </ListItemButton>
    );
  }

  if (!dashboards.length) {
    return null;
  }
  if (!open) {
    return (
      <StyledTooltip
        open={isTooltipOpen}
        onOpen={() => setTooltipOpen(true)}
        title={
          <DashboardsMenu
            dashboards={tabNames}
            closeTooltip={() => setTooltipOpen(false)}
            navHandlerProvider={getNavHandler}
          />
        }
        onClose={() => {
          setTooltipOpen(false);
        }}
      >
        <ListItemButton
          selected={!!pageMatch && !custom}
        >
          <ListItemIcon>
            <Dashboard />
          </ListItemIcon>
          <ListItemText primary="Digital Shelf" sx={{ whiteSpace: "nowrap" }} />
        </ListItemButton>
      </StyledTooltip>
    );
  }

  return (
    // @ts-ignore
    <ExpendableList
      open={openList}
      label={(props) => (
        <ExpendableListHeader
          icon={<Dashboard />}
          onClick={handleDigitalShelf}
          label="Digital Shelf"
          {...props}
        />
      )}
    >
      {tabNames.map((tabName, index) => (
        <ListItemButton
          key={index}
          selected={tabName === dashboards.find(d => d.id == Number(pageMatch?.params.tabId))?.name}
          onClick={getNavHandler(tabName)}
        >
          {tabName}
        </ListItemButton>
      ))}
    </ExpendableList>
  );
};
