import { useHistory, useRouteMatch } from "react-router";

import { LeftNavBarItem } from "./LeftNavBarItem";
import { FC } from "react";
import { CATALOG_ENRICHMENT_ROUTE } from '../Router'
import CategoryIcon from '@mui/icons-material/Category';

export const CatalogEnrichmentNavItem: FC = () => {
  const pageMatch = useRouteMatch({ path: [CATALOG_ENRICHMENT_ROUTE] });
  const history = useHistory();
  return (
    <LeftNavBarItem
      selected={!!pageMatch}
      icon={<CategoryIcon sx={{ color: "white" }} />}
      onClick={() => history.push(CATALOG_ENRICHMENT_ROUTE)}
      label="Catalog Enrichment"
    />
  );
};
