import SkeletonCard from "./SkeletonCard.tsx";
import { Box, Stack } from "@mui/material";
import { salesColumnWidths } from "../../../SalesAssistant/constants.ts";
import { RowData } from "../../types.ts";
import ProductImage from "./ProductImage.tsx";
import InfoSection from "./InfoSection.tsx";
import { useSalesAssistant } from "../../SalesAssistantProvider.tsx";
import NumberSection from "./NumberSection.tsx";
import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

const Row = ({ loading, data }: { loading?: boolean; data?: RowData }) => {
  const { selectedGroupBy, setSelectedGroupBy } = useSalesAssistant();
  const history = useHistory();
  const { pathname } = useLocation();

  const onClickRow = useCallback(() => {
    if (selectedGroupBy === "spaces" && data?.space_id) {
      setSelectedGroupBy("asins");
      history.push(`/${pathname.split("/")[1]}/space/${data?.space_id}`);
    } else if (data?.title) {
      history.push(`${pathname}?asin=${data?.title}`);
    }
  }, [selectedGroupBy, data, pathname]);

  if (loading) return <SkeletonCard />;

  return (
    <Stack
      direction="row"
      alignItems="center"
      padding="12px 10px"
      gap="45px"
      justifyContent="space-between"
      position="relative"
      display="flex"
      sx={{
        cursor: "pointer",
        background: "#fff",
        borderRadius: "16px",
        "&:hover": {
          "& .MuiBox-root": {
            opacity: 1,
          },
        },
        "& .trackImg": {
          opacity: 1,
        },
      }}
      onClick={onClickRow}
    >
      <Stack
        direction="row"
        gap="16px"
        flex={2.5}
        sx={{ display: "flex", gap: "16px", alignItems: "center" }}
        width={salesColumnWidths.info}
      >
        <Box minWidth={salesColumnWidths.image}>
          <ProductImage data={data} group={selectedGroupBy === "spaces"} />
        </Box>
        <Box>
          <InfoSection
            data={data}
            type={selectedGroupBy === "spaces" ? "metadata" : "product"}
          />
        </Box>
      </Stack>
      <Box flex={1}>
        <NumberSection
          unit="$"
          number={data?.revenue}
          percentage={data?.revenue_percentage}
          change={data?.revenue_change}
        />
      </Box>
      <Box flex={1}>
        <NumberSection
          number={data?.units_sold}
          percentage={data?.units_sold_percentage}
          change={data?.units_sold_change}
        />
      </Box>
      <Box flex={1}>
        <NumberSection
          unit="%"
          number={data?.conversion}
          percentage={data?.conversion_percentage}
          change={data?.conversion_change}
        />
      </Box>
      <Box flex={1}>
        <NumberSection
          number={data?.glance_views}
          percentage={data?.glance_views_percentage}
          change={data?.glance_views_change}
        />
      </Box>
    </Stack>
  );
};

export default Row;
