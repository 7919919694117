import { ChatTemplate } from "./types";

export const convertNumberToShortFormat = (
  value: string | number,
  percentage: boolean = false
) => {
  const number = Number(value);
  const thresholds = [
    { value: 1e9, symbol: "B" },
    { value: 1e6, symbol: "M" },
    { value: 1e3, symbol: "K" },
  ];

  const threshold = thresholds.find((threshold) => number >= threshold.value);

  if (threshold) {
    return (
      (number / threshold.value).toFixed(2).replace(/\.?0+$/, "") +
      threshold.symbol
    );
  }

  return percentage
    ? `${(number * 100).toFixed(2).replace(/\.?0+$/, "")}%`
    : number.toFixed(6).replace(/\.?0+$/, "");
};

export const toTitleCase = (str: string) =>
  str?.replace(
    /\w\S*/g,
    (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );

export const formatChatTemplate = (template: ChatTemplate) => {
  switch (template) {
    case "executive_summary":
      return "Executive Summary";
    case "sales_report":
      return "Weekly Sales Report";
    case "keywords_opportunities":
      return "Keywords Opportunities";
    default:
      return template;
  }
};

export const getBaseLabel = (column: string, substr: string) =>
  column
    .replace(`_${substr}_`, "_")
    .replace(`_${substr}`, "")
    .replace(`${substr}_`, "")
    .replace(`${substr}`, "");
