import { Box } from "@mui/material";
import { FC, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme

import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { getBaseLabel, toTitleCase } from "../utils";
import {
  brandedRenderer,
  changeRenderer,
  growthRenderer,
  productRenderer,
  ratingRenderer,
  relevancyRenderer,
} from "./CellRenderers";
import { createSortingMachine } from "./Sorting";
import { GridApi } from "ag-grid-community";
ModuleRegistry.registerModules([ClientSideRowModelModule]);

interface TableViewProps {
  data: any;
  schema: any;
}

const TableView: FC<TableViewProps> = (props) => {
  const { data, schema } = props;

  const [gridApi, setGridApi] = useState<GridApi>();

  const columnsWithChange: { [key: string]: string } = {};
  schema.forEach((item: any) => {
    schema.forEach((i: any) => {
      if (
        i.label !== item.label &&
        item.label.includes("change") &&
        item.label.includes(i.label)
      ) {
        const baseLabel = getBaseLabel(item.label, "change");
        columnsWithChange[baseLabel] = item.label;
      }
    });
  });

  // Manually change/format the value of a cell
  const valueFormatters: { [key: string]: (p: any) => string } = {
    currency: (p) => {
      const formatted = Number(p.value).toLocaleString(undefined, {
        maximumFractionDigits: 2,
      });
      if (formatted == "NaN") {
        return "$" + p.value;
      }
      return "$" + formatted;
    },
    // growth: (p) => Number(p.value).toFixed(1) + "%",
    number: (p) =>
      Number(p.value).toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }),
    // text: (p) => {
    //   switch (p) {
    //     default:
    //       return p;
    //   }
    // },
  };
  // Manually render a cell
  const getCellRenderer = (field: string) => {
    if (field === "rating") {
      return ratingRenderer;
    }
    if (field === "asin") {
      return productRenderer;
    }
    if (
      field.includes("growth") ||
      field.includes("reduction") ||
      field.includes("percentage")
    ) {
      return growthRenderer;
    }
    if (columnsWithChange[field]) {
      return changeRenderer(field, columnsWithChange[field]);
    }
    if (field === "branded_unbranded") {
      return brandedRenderer;
    }
    if (field === "relevancy") {
      return relevancyRenderer;
    }
    return (p: any) => <Box>{p.value}</Box>;
  };

  const getHeaderName = (field: string) => {
    const label = field?.split("_").join(" ");
    let headerName = "";
    switch (field) {
      case "asin":
        headerName = "Product";
        break;
      case "total_impressions":
        headerName = "Impressions";
        break;
      case "growth_of_impressions":
        headerName = "Growth";
        break;
      case "num_of_reviews":
        headerName = "Reviews";
        break;
      case "kpis":
        headerName = "KPIs";
        break;
      case "ordered_revenue":
        headerName = "Revenue";
        break;
      case "asp":
        headerName = "ASP";
        break;
      case "percentage_asp":
        headerName = "% ASP";
        break;
      case "branded_unbranded":
        headerName = "Branded";
        break;
      default:
        headerName = toTitleCase(label).replace("Percentage", "%");
        break;
    }
    return headerName;
  };

  const getCustomWidth = (field: string) => {
    switch (field) {
      case "asin":
      case "search_term":
      case "competitor_impressions":
        return 200;
      case "average_price":
      case "percentage_unit_sales":
      case "percentage_revenue":
      case "percentage_average_price":
      case "recommendation":
      case "my_impressions":
        return 175;
      default:
        return 130;
    }
  };

  const updatedSchema = schema?.filter(
    (item: any) =>
      item.type !== "image" &&
      item.label !== "image_url" &&
      item.label !== "title"
  );

  const colDefs = updatedSchema?.map((schemaItem: any) => {
    return {
      headerName: getHeaderName(schemaItem?.label),
      field: schemaItem?.label,
      valueFormatter: valueFormatters[schemaItem?.type],
      cellRenderer: getCellRenderer(schemaItem?.label),
      minWidth: getCustomWidth(schemaItem?.label),
    };
  });

  const colDefsWithChange = colDefs?.map((colDef: any) => {
    if (columnsWithChange[colDef.field]) {
      const sortingMachine = createSortingMachine(
        colDef.field,
        columnsWithChange[colDef.field]
      );
      return {
        ...colDef,
        // comparator: sortingMachine.currentState.sortComparator,
        comparator: (_: any, __: any, nodeA: any, nodeB: any) =>
          sortingMachine.currentState.sortComparator(nodeA.data, nodeB.data),
        onHeaderClick: () => {
          sortingMachine.transitionState();
          gridApi?.refreshCells({ force: true });
        },
      };
    }
    return {
      ...colDef,
      hide: Object.values(columnsWithChange).includes(colDef.field),
    };
  });

  const rowData = data.map((row: any) => {
    return {
      ...row,
      asin: { image_url: row.image_url, asin: row.asin, title: row.title },
      brand: toTitleCase(row?.brand),
    };
  });

  return (
    <Box
      className={"ag-theme-quartz"}
      sx={{
        width: "100%",
        height: "475px",
      }}
    >
      <AgGridReact
        rowData={rowData}
        columnDefs={colDefsWithChange}
        defaultColDef={{ flex: 1 }}
        onGridReady={(params) => {
          setGridApi(params.api);
        }}
      />
    </Box>
  );
};

export default TableView;
