import { useHistory, useRouteMatch } from "react-router";

import SVG from "react-inlinesvg";
import { LeftNavBarItem } from "./LeftNavBarItem";
import { FC } from "react";

export const InsightsNavItem: FC = () => {
  const pageMatch = useRouteMatch({ path: ["/insights"] });
  const history = useHistory();
  return (
    <LeftNavBarItem
      selected={!!pageMatch}
      icon={
        <SVG
          src={"/insights/insights-icon-new.svg"}
          fill="inherit"
          width={20}
          height={20}
        />
      }
      onClick={() => history.push("/insights")}
      label="Insights"
    />
  );
};
