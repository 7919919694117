import React from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  FormControlLabel,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { Features } from "./types.ts";

const AddAttributeForm = ({
  add,
  setExpanded,
}: {
  add: (attribute: Features) => void;
  setExpanded: (expanded: boolean) => void;
}) => {
  const { watch, setValue, register, handleSubmit } = useForm({
    defaultValues: {
      attribute: "",
      description: "",
      accepted_values: [],
      closed_list: false,
      type: "string",
    },
  });
  const typeValue = watch(`type`);
  return (
    <Stack spacing={1}>
      <TextField
        placeholder="Attribute"
        {...register(`attribute`, { required: true })}
      />
      <TextField
        placeholder="Description"
        multiline
        {...register(`description`, { required: true })}
        rows={3}
      />
      <TextField
        placeholder="Accepted values (seprated by comma)"
        multiline
        rows={3}
        {...register(`accepted_values`)}
      />
      <FormControlLabel
        control={<Switch {...register(`closed_list`)} />}
        label="Closed list"
      />
      <ToggleButtonGroup
        onChange={(_e: React.MouseEvent<HTMLElement>, value: string) => {
          setValue("type", value);
        }}
        exclusive
      >
        <ToggleButton value="string" selected={typeValue === "string"}>
          String
        </ToggleButton>
        <ToggleButton value="int" selected={typeValue === "int"}>
          Integer
        </ToggleButton>
        <ToggleButton value="boolean" selected={typeValue === "boolean"}>
          Boolean
        </ToggleButton>
      </ToggleButtonGroup>
      <Button
        variant="contained"
        type="button"
        onClick={handleSubmit((data) => {
          setExpanded(false);
          add(data as Features);
        })}
      >
        Add feature
      </Button>
    </Stack>
  );
};

export default AddAttributeForm;
