import { usePublicStoryboard } from "./useChatApi.ts";
import { Box, Button } from "@mui/material";
import theme from "../../themes/light.ts";
import { DownloadIcon, ListIcon } from "../../assets/index.tsx";
import dayjs from "dayjs";
import ErrorComponent from "./ErrorComponent.tsx";
import GenericChart from "./GenericChart.tsx";

import TableView from "./Charts/TableView.tsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const PublicPage = ({ match }: any) => {
  const { data, isLoading } = usePublicStoryboard(match.params.token);
  const isMobile = useMediaQuery("(max-width:768px)");

  if (isLoading) {
    return "loading...";
  }

  const downloadPdfDocument = async () =>
    await html2canvas(document.body).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      const date = new Date();
      const formattedDate = date
        .toLocaleDateString("en-GB")
        .split("/")
        .join("-"); // Format date as DD-MM-YYYY
      const formattedTime = date
        .toLocaleTimeString("en-GB", {
          hour: "2-digit",
          minute: "2-digit",
        })
        .split(":")
        .join("-"); // Format time as HH-MM
      pdf.save(`noogata-report-${formattedDate}-${formattedTime}.pdf`);
    });

  return (
    <>
      <Box
        sx={{
          display: "flex",
          borderBottom: "1px solid rgba(44, 34, 76, 0.10)",
          height: "76px",
        }}
      >
        <Box
          sx={{
            // width: "100%",
            width: isMobile ? "90vw" : "50vw",
            height: "100%",
            // border: "1px solid red",
            margin: "auto",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() =>
              (window.location.href = window.location.origin + "/chat")
            }
          >
            <img
              alt=""
              src="/logo-black.svg"
              height={32}
              width={117}
              style={{
                marginLeft: 2,
              }}
            />
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <ListIcon />
              <Box
                sx={{
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                {data?.user_email || ""}
              </Box>
              <Button
                onClick={downloadPdfDocument}
                sx={{
                  minHeight: "32px",
                  maxHeight: "32px",
                  gap: "4px",
                  color: theme.palette.secondary.main,
                  padding: "10px",
                }}
              >
                <DownloadIcon />
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          // width: "100%",
          width: isMobile ? "90vw" : "50vw",
          height: "100%",
          // border: "1px solid red",
          margin: "1rem auto",
        }}
      >
        <Box sx={{ fontSize: "22px", fontWeight: 600 }}>{data?.title}</Box>
        {data?.story_points?.map((story_point: any, index: number) => {
          // let seriesData: any[] = [];
          // let categories: any[] = [];
          if (story_point?.graph_data) {
            const isTextBlock = story_point?.type === "simple-text";
            // const currentSeriesData = JSON.parse(
            //   JSON.parse(story_point?.graph_data)?.graph_data
            // )?.map((el2: any) => el2.current_year_impressions);
            // const previousSeriesData = JSON.parse(
            //   JSON.parse(story_point?.graph_data)?.graph_data
            // )?.map((el2: any) => el2.previous_year_impressions);
            // const categories = JSON.parse(
            //   JSON.parse(story_point?.graph_data)?.graph_data
            // )?.map((el3: any) => (el3.brand === null ? "unknown" : el3.brand));

            return (
              <Box
                key={story_point.id}
                sx={{
                  // boxShadow:
                  //   "0px 12px 45px -4px rgba(44, 34, 76, 0.05), 0px 8px 20px -6px rgba(44, 34, 76, 0.05)",
                  background: theme.palette.primary.contrastText,
                  // padding: "8px 20px",
                  minHeight: "64px",
                  borderRadius: "8px",
                  // display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "1rem",
                  marginBottom: `${!isTextBlock ? "1rem" : "-1rem"}`,
                  // border: "1px solid black",
                  // width: "80vw"
                }}
              >
                {/* <p>Updated at: {story_point?.updated_at}</p> */}
                {index === 0 && (
                  <Box
                    sx={{
                      color: "#A6A3AF",
                      fontSize: "12px",
                      fontWeight: 500,
                      padding: "0 0 1rem",
                    }}
                  >
                    {story_point?.updated_at
                      ? dayjs(story_point?.updated_at).format("MMMM DD, YYYY")
                      : ""}
                  </Box>
                )}

                {/*<pre>{JSON.parse(JSON.parse(story_point?.graph_data).graph_data)}</pre>*/}
                <Box
                  sx={{
                    height: "100%",
                    borderTop: "1px solid rgba(44, 34, 76, 0.10)",
                  }}
                >
                  {!isTextBlock && <h3>{story_point.title}</h3>}
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        "<style>.label {color: rgb(92, 48, 164) !important; background: rgba(92, 48, 164,0.3) !important; padding:2px 10px; border-radius: 12px }</style>" +
                        story_point.summary,
                    }}
                  />
                  {isTextBlock ? null : story_point?.status === "failed" ||
                    story_point.graph_data === null ? (
                    <ErrorComponent />
                  ) : (
                    <Box>
                      {story_point.graph_data.graph_type === "table" ? (
                        <TableView
                          data={story_point.graph_data.data}
                          schema={story_point.output_schema}
                        />
                      ) : (
                        <GenericChart graph_data={story_point.graph_data} />
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            );
          }
          return <></>;
        })}
      </Box>
    </>
  );
};

export default PublicPage;
