import { useAuthUserOrNull } from "@frontegg/react";
import {
  Avatar,
  Box,
  // CardHeader,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
// import { useStyles } from "../../../components/LabLeftNav/useStyles";
import { useEffect, useState } from "react";
import { getUserMostPrivilegeRole } from "../../../services/auth";
import { PlatformRole } from "@noogata/types";
import { useHistory } from "react-router-dom";
import { DataLabColuredIcon, LogoutColouredIcon } from "../../../assets";
import { useAppContext } from "../../../ApplicationContext";
import { UserSectionV2 } from "../../../components/LabLeftNav/UserSectionV2";

interface ProfileMenuProps {
  sideBarOpen: boolean;
}

const ProfileMenu = ({ sideBarOpen }: ProfileMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const user = useAuthUserOrNull();
  // const classes = useStyles();
  const role: string = user
    ? getUserMostPrivilegeRole(user).key
    : PlatformRole.ApplicationUser;

  const history = useHistory();
  const { applicationMode, setApplicationMode } = useAppContext();

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  useEffect(() => {
    setAnchorEl(null);
    return () => {
      setAnchorEl(null);
    };
  }, [sideBarOpen]);

  return (
    <Box>
      {sideBarOpen ? (
        <UserSectionV2 open onClick={handleClick} sx={{ cursor: "pointer" }} />
      ) : (
        <Stack alignItems="center" onClick={handleClick}>
          <Avatar src={user?.profilePictureUrl || ""} />
        </Stack>
      )}
      <Popper
        id="simple-popper"
        anchorEl={(anchorEl as unknown as HTMLElement) || null}
        open={Boolean(anchorEl)}
        sx={{
          transform: `translate(${sideBarOpen ? 200 : 80}px ,-10px) !important`,
          zIndex: 10,
        }}
        keepMounted={false}
      >
        {anchorEl && (
          <Box
            sx={{
              background: "#FFF",
              padding: "8px",
              borderRadius: "4px",
              boxShadow:
                " 0px 1px 1px 0px rgba(44, 34, 76, 0.08), 0px 4px 16px 0px rgba(44, 34, 76, 0.08), 0px 4px 12px 0px rgba(44, 34, 76, 0.04), 0px 2px 6px 0px rgba(44, 34, 76, 0.12)",
              cursor: "pointer",
            }}
          >
            <UserSectionV2
              open
              sx={{
                borderRadius: "4px",
                background: "#F4F3F8",
                "& .MuiTypography-root": {
                  color: "#000000",
                },
              }}
            />
            {role !== PlatformRole.ApplicationUser && (
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "8px",
                  gap: "10px",
                }}
                onClick={() => {
                  setApplicationMode(!applicationMode);
                  history.push("/");
                }}
              >
                <DataLabColuredIcon />
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 400,
                    lineHeight: "24px",
                  }}
                >
                  Data Lab
                </Typography>
              </Stack>
            )}
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                padding: "8px",
                gap: "10px",
              }}
              onClick={() => {
                setApplicationMode(false);
                history.push("/account/logout");
              }}
            >
              <LogoutColouredIcon />
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: 400,
                  lineHeight: "24px",
                }}
              >
                Logout
              </Typography>
            </Stack>
          </Box>
        )}
      </Popper>
    </Box>
  );
};

export default ProfileMenu;
