import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import { BarGraphIcon, LineGraphIcon, TableIcon } from "../../assets";
import { FC } from "react";
import { RollingDataType } from "./types";
import ChatQuestionsSkeleton from "./ChatQuestionsSkeleton";

interface ChatQuestionsProps {
  isLoading: boolean;
  questionList: RollingDataType[] | undefined;
  handleOnClick: any;
}

interface ChatQuestionProps {
  question: any;
  handleOnClick: any;
}

const ChatQuestion: FC<ChatQuestionProps> = (props) => {
  const { question, handleOnClick } = props;

  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "1rem",
        padding: "12px",
        borderRadius: "12px",
        boxShadow:
          "0px 8px 20px -6px rgba(44, 34, 76, 0.05), 0px 12px 45px -4px rgba(44, 34, 76, 0.05)",
        background: theme.palette.text.dark,
        cursor: "pointer",
        outline: "1px solid rgba(0, 0, 0, 0.10)",
        ":hover": {
          outline: "1px solid rgba(0, 0, 0, 0.20)",
          backgroundColor: " #fafafa",
          boxShadow:
            "0px 12px 45px -4px rgba(44, 34, 76, 0.05), 0px 8px 20px -6px rgba(44, 34, 76, 0.05)",
        },
        height: "75px",
        width: "350px",
      }}
      onClick={() => handleOnClick(question.id)}
      className="question-card"
    >
      {question.graph_type === "area" && <LineGraphIcon small={true} />}
      {question.graph_type === "bar" && <BarGraphIcon small={true} />}
      {question.graph_type === "table" && <TableIcon small={true} />}
      {question.graph_type === "line" && <LineGraphIcon small={true} />}
      <Tooltip placement="top" title={question?.question_text} arrow>
      <Typography
        sx={{
          fontSize: "14px",
          fontWeight: 550,
          lineHeight: "1rem",
          color: theme.palette.secondary.main,
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          maxWidth: "75%",
        }}
      >
        {question?.question_text}
      </Typography>
      </Tooltip>
    </Box>
  );
};

const ChatQuestions: FC<ChatQuestionsProps> = (props) => {
  const { isLoading, questionList, handleOnClick } = props;
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        gap: "1rem",
        alignItems: "center",
      }}
      id="questions-container"
    >
      {isLoading ? (
        <ChatQuestionsSkeleton questionsNum={questionList?.length} />
      ) : (
        questionList &&
        questionList?.length > 0 &&
        questionList?.map((question: any, index: number) => {
          return (
            <ChatQuestion
              key={`question-${index}`}
              question={question}
              handleOnClick={handleOnClick}
            />
          );
        })
      )}
    </Box>
  );
};

export default ChatQuestions;
