import { Box, Skeleton, Tab, Tabs, Typography } from "@mui/material";
import ChatList from "./ChatList";
import theme from "../../themes/light";
import React, { useState } from "react";
import { ChatMainIcon, KeywordsOpportunitiesIcon } from "../../assets";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
// import ChatFilter from "./ChatFilter";
import { Icon } from "../../components/Icon";
import Discover from "./DiscoverList";
// import ChatMenuList from "./ChatMenuList";
import { useInsightChatContext } from "./hooks/selectors";
import ShareDialog from "./ShareDialog";
// import { DefinedRange } from "../../components/DateRangePicker/types";
// import { getDefaultDateRange } from "./defaults";
// import GraphSkeleton from "./GraphSkeleton";
// import ChatFilterSkeleton from "./ChatFilterSkeleton";
// import ClearIcon from "@mui/icons-material/Clear";
import ChatView from "./ChatView";
import { useSplitEnabled } from "../../hooks";
import { Split } from "../../FeatureFlags/enums";
import SpaceMenuList from "./SpaceMenuList";
// import ChatFilterNew from "./ChatFilterNew";
import ChatQuestions from "./ChatQuestions";
import ChatHeader from "./ChatHeader";
// import RelatedStoryPoints from "./RelatedStoryPoints";
// import ErrorComponent from "./ErrorComponent";
import StoryPoint from "./StoryPoint";
import StoryPointSkeletonLoader from "./StoryPointSkeletonLoader";
import TextBlock from "./TextBlock";
import AddStoryPoint from "./AddStoryPoint";
import SyncDialog from "./SyncDialog";
import { useHistory } from "react-router-dom";
// import RelatedStoryPoints from "./RelatedStoryPoints";
// import TableView from "./TableView";

export interface SharedInfo {
  title: string;
  link: string;
}

const InsightsChats = () => {
  const {
    graphData,
    filteredQuestionList,
    toggle,
    setToggle,
    deleteStoryPoint,
    storyboardList,
    emptyCurrentSession,
    updateStoryPoint,
    addStoryPointToStoryboard,
    setSession,
    startNewSession,
    storyboardId,
    copyStoryboard,
    syncStoryboard,
  } = useInsightChatContext();
  const enableFilters = useSplitEnabled(Split.RESEARCH_ASSISTANT_FILTERS);
  const enableKeywordsOpportunitiesReport = useSplitEnabled(
    Split.STORY_POINTS_REPORT_KEYWORDS_OPPORTUNITY
  );
  const [value, setValue] = React.useState(0);
  const [loadingNewStoryboard, setLoadingNewStoryboard] = useState(false);
  const [openShareDialog, setOpenShareDialog] = useState(false);
  const [openSyncDialog, setOpenSyncDialog] = useState(false);
  const [storyboardIdToSync, setStoryboardIdToSync] = useState<string>("");

  const [sharedInfo, setSharedInfo] = useState<SharedInfo>({
    title: "",
    link: "",
  });

  const history = useHistory();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    event.preventDefault();
    setValue(newValue);
  };
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleOpenShareDialog = (title: string, link: string) => {
    setOpenShareDialog(true);
    setSharedInfo({
      title,
      link,
    });
  };

  const handleOpenSyncDialog = (storyboardId: string) => {
    setOpenSyncDialog(true);
    setStoryboardIdToSync(storyboardId);
  };

  const handleShowStoryPoint = (storyPoint: any, idx: number) => {
    const graphType = storyPoint?.graph_data
      ? storyPoint.graph_data.graph_type ||
        JSON.parse(storyPoint.graph_data).graph_type
      : "";

    const shownElement = storyPoint?.isLoader ? (
      <StoryPointSkeletonLoader />
    ) : storyPoint?.isDeleting ? (
      <Skeleton
        animation="wave"
        variant="text"
        sx={{
          width: "100%",
          borderRadius: "12px",
          transform: "unset",
          height: storyPoint.type != "simple-text" ? "675px" : "90px",
        }}
      />
    ) : graphType === "text" ? (
      <TextBlock
        htmlText={storyPoint.summary}
        onDelete={() => deleteStoryPoint(storyPoint.id)}
        onEdit={(html: string) =>
          updateStoryPoint(storyPoint.id, { summary: html })
        }
      />
    ) : (
      <StoryPoint
        storyPoint={storyPoint}
        storyBoardIndex={storyPoint.order}
        story_points={graphData?.story_points}
      />
    );

    const isLoaderOrDeleting = storyPoint?.isLoader || storyPoint?.isDeleting;

    return (
      <Box
        key={`story_point_${storyPoint.id}`}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "1000px !important",
          minWidth: "500px",
          paddingBottom:
            storyPoint.order === graphData?.story_points.length ||
            isLoaderOrDeleting
              ? "2rem"
              : "0",
        }}
      >
        {idx === 0 && (
          <AddStoryPoint
            storyBoardIndex={0}
            storyPoints={graphData?.story_points}
            nearLoader={!isLoaderOrDeleting}
          />
        )}
        {shownElement}

        {!isLoaderOrDeleting && (
          <AddStoryPoint
            storyBoardIndex={storyPoint.order}
            storyPoints={graphData?.story_points}
            nearLoader={!isLoaderOrDeleting}
          />
        )}
      </Box>
    );
  };

  const startNewSessionHandler = async (type: string) => {
    history.push(`/chat?chatTemplate=${type}`);
    setLoadingNewStoryboard(true);
    await startNewSession(type);
    setLoadingNewStoryboard(false);
  };

  return (
    <>
      <Box sx={{ position: "relative" }}>
        {toggle && (
          <Box
            sx={{
              position: "absolute",
              top: "12px",
              left: "-3px",
              zIndex: "9999",
              cursor: "pointer",
            }}
            onClick={() => setToggle(false)}
          >
            <Icon width="16px" height="56px" src="insights/handle1.svg" />
          </Box>
        )}
        <Box
          sx={{ width: "100%" }}
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection="column"
            sx={{
              height: "100vh",
              boxShadow:
                "0px 12px 45px 20px rgba(44, 34, 76, 0.05), 0px 20px 24px -6px rgba(44, 34, 76, 0.10)",
              background: theme.palette.primary.contrastText,
              width: toggle ? "0px" : "292px",
              padding: toggle ? 0 : "20px 16px",
              position: "relative",
              transition: "all ease 0.2s",
              overflow: "hidden",
            }}
            id="chat-sidebar"
          >
            <Box>
              {storyboardList ? (
                <Box>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="RECENT" {...a11yProps(0)} />
                    {/* <Tab label="Discover" {...a11yProps(1)} /> */}
                  </Tabs>
                </Box>
              ) : (
                <Box display="flex" gap="1rem">
                  {Array.from({ length: 3 }, (_, index) => (
                    <Skeleton
                      key={index}
                      animation="wave"
                      width={70}
                      sx={{
                        height: "24px",
                        borderRadius: "16px",
                        transform: "unset",
                      }}
                    />
                  ))}
                </Box>
              )}
              <CustomTabPanel value={value} index={0}>
                {storyboardList ? (
                  <ChatList
                    setSession={setSession}
                    handleOpenShareDialog={handleOpenShareDialog}
                    handleOpenSyncDialog={handleOpenSyncDialog}
                    copyStoryboard={copyStoryboard}
                  />
                ) : (
                  <Box
                    sx={{
                      width: 260,
                      borderRadius: 2,
                    }}
                    display="flex"
                    flexDirection="column"
                    gap="0.7rem"
                    padding="10px 0"
                    bgcolor="linear-gradient(to right, #BAC5D1 20%, #DDE3EA 59.9%, #BAC5D1 100%)"
                  >
                    {Array.from({ length: 3 }, (_, index) => (
                      <Skeleton
                        key={index}
                        animation="wave"
                        width={260}
                        sx={{
                          height: "40px",
                          borderRadius: "12px",
                          transform: "unset",
                        }}
                      />
                    ))}
                  </Box>
                )}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Discover />
              </CustomTabPanel>
            </Box>

            <Box
              sx={{
                position: "absolute",
                top: "12px",
                right: 0,
                zIndex: "10",
                cursor: "pointer",
              }}
              onClick={() => setToggle(true)}
            >
              <Icon width="16px" height="56px" src="insights/handle.svg" />
            </Box>
          </Box>
          <Box
            sx={{
              width: toggle ? "100%" : "calc(100% - 292px)",
              background: theme.palette.grey[50],
              height: "100%",
              minHeight: "100vh",
            }}
            id="chat-main"
          >
            <ChatHeader
              handleBack={emptyCurrentSession}
              handleOpenShareDialog={handleOpenShareDialog}
              handleOpenSyncDialog={handleOpenSyncDialog}
              copyStoryboardCallback={async () =>
                await copyStoryboard(storyboardId)
              }
            />
            <Box
              sx={{
                height: "calc(100% - 100px)",
                width: "100%",
                overflow: "auto",
                display: "flex",
                justifyContent: "center",
              }}
              id="chat-content"
            >
              {!storyboardId && !loadingNewStoryboard ? (
                <Box
                  display="flex"
                  gap="35px"
                  flexDirection="column"
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    height: "calc(100vh - 200px)",
                  }}
                >
                  <ChatMainIcon />

                  <Typography
                    variant="body4"
                    sx={{
                      display: "flex",
                      fontSize: "20px",
                      alignItems: "center",
                      width: "400px",
                      gap: "8px",
                    }}
                  >
                    I would like to focus on:
                    <SpaceMenuList />
                  </Typography>
                  <Box
                    display="flex"
                    gap="12px"
                    flexDirection="row"
                    sx={{
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "0px 100px ",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "16px",
                        alignItems: "center",
                      }}
                    >
                      <ChatView
                        title="Create an Executive Summary"
                        handleOnClick={() =>
                          startNewSessionHandler("executive_summary")
                        }
                        icon={
                          <AssignmentOutlinedIcon sx={{ color: "#8E4FF6" }} />
                        }
                      />
                      <ChatView
                        title="Create a Weekly Sales Report"
                        handleOnClick={() =>
                          startNewSessionHandler("sales_report")
                        }
                        icon={
                          <CalendarMonthOutlinedIcon
                            sx={{ color: "#8E4FF6" }}
                          />
                        }
                      />
                      {enableKeywordsOpportunitiesReport && (
                        <ChatView
                          title="Create a Keywords Opportunities Report"
                          handleOnClick={() =>
                            startNewSessionHandler("keywords_opportunities")
                          }
                          icon={<KeywordsOpportunitiesIcon />}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "80%",
                    height: "calc(100vh - 100px)",
                  }}
                >
                  {storyboardId &&
                  graphData &&
                  graphData?.story_points?.length ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: "28px",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          width: enableFilters ? "calc(100% - 295px)" : "100%",
                          height: "calc(100vh - 100px)",
                          display: "flex",
                          flexDirection: "column",
                          // gap: "2rem",
                          padding: "0rem 1rem 10rem",
                          alignItems: "center",
                        }}
                      >
                        {graphData?.story_points?.map(
                          (storyPoint: any, idx: number) =>
                            handleShowStoryPoint(storyPoint, idx)
                        )}
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        marginTop: "2rem",
                      }}
                    >
                      <ChatQuestions
                        isLoading={loadingNewStoryboard}
                        questionList={filteredQuestionList}
                        handleOnClick={addStoryPointToStoryboard}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <ShareDialog
        open={openShareDialog}
        onClose={() => setOpenShareDialog(false)}
        // href={sharedLink}
        // text={sharedTitle}
        sharedInfo={sharedInfo}
      />
      <SyncDialog
        open={openSyncDialog}
        onClose={() => setOpenSyncDialog(false)}
        onSync={async () => await syncStoryboard(storyboardIdToSync)}
      />
    </>
  );
};

export default InsightsChats;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
