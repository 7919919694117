import {
  Chip,
  // IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import {
  Box,
  InputLabel,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import theme from "../../themes/light";
import { Icon } from "../../components/Icon";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  // ArrowIcon,
  ArrowUpIcon,
  CalenderIcon,
  DropDownIcon,
  // DropRightIcon,
} from "../../assets";
import DateRangeSelector from "./DateRangeSelector";
import { DefinedRange } from "../../components/DateRangePicker/types";
// import CategoryPopup from "./CategoryPopup";
import {
  genDateRangeParam,
  getNewFormattedDateString,
  getParsedDate,
  parseDateRangeParam,
  useInsightChatContext,
} from "./hooks/selectors";
// import BrandPopup from "./BrandPopup";
import {
  getCompareDateRanges,
  getDateRanges,
  getDefaultDateRange,
} from "./defaults";
import { getOptionsData } from "./useChatApi";
import MultiChoiceComponent from "./MultiChoiceComponent";
import ChatFilterSkeleton from "./ChatFilterSkeleton";

interface ChatFilterProps {
  filterData: any;
  params: any;
  storyPointType: string;
  storyBoardIndex: number;
  onclose: () => void;
  setIsVisible: (value: boolean) => void;
}

export interface InitialDataRange {
  startDate: Date;
  endDate: Date;
}
const ChatFilterNew = ({
  filterData,
  params,
  storyPointType,
  storyBoardIndex,
  onclose,
  setIsVisible,
}: ChatFilterProps) => {
  const { addStoryPointToStoryboard, spacesList } = useInsightChatContext();

  // Date range logic
  const [dateRange, setDateRange] = useState<DefinedRange>(
    getDefaultDateRange()
  );
  const [compareDateRange, setCompareDateRange] = useState<DefinedRange | null>(
    null
  );
  const [openDate, setOpenDate] = useState(false);
  const dateRangeRef = useRef<any>(null);
  const [openCompareDate, setOpenCompareDate] = useState(false);
  const compareDateRangeRef = useRef<any>(null);

  // Brand logic
  const [brandList, setBrandList] = useState<any>([]);
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
  const [brandType, setBrandType] = useState<string>("");
  const [multiBrandType, setMultiBrandType] = useState<string>("");
  const [multiBrand, setMultiBrand] = useState<string>("");

  const getBrandsUrl =
    filterData &&
    filterData?.params?.find((param: any) => param?.id === "brand")
      ?.get_options_url;

  useEffect(() => {
    if (getBrandsUrl) {
      getOptionsData(getBrandsUrl, params?.space_id).then((res) => {
        setBrandList(res);
      });
    }
  }, [getBrandsUrl]);

  // Category logic
  const [paidType, setPaidType] = useState<string>("");
  const [multiPaidType, setMultiPaidType] = useState<string>("");

  // Space logic
  const [pickedSpace, setPickedSpace] = useState<string | "">("");

  // Period logic
  const [period, setPeriod] = useState<string>("");
  const [comparisonPeriod, setComparisonPeriod] = useState<string>("");

  useEffect(() => {
    if (period) {
      setComparisonPeriod(period === "year_to_date" ? "previous_year" : "previous_period");
    }
  }, [period]);


  // ASINs logic
  const [asinsList, setAsinsList] = useState<string[]>([]);
  const [selectedAsins, setSelectedAsins] = useState<string[]>([]);

  const getAsinsUrl = filterData?.params?.find(
    (param: any) => param?.id === "asin"
  )?.get_options_url;

  useEffect(() => {
    if (getAsinsUrl) {
      getOptionsData(getAsinsUrl, params?.space_id).then((res) =>
        setAsinsList(res)
      );
    }
  }, [getAsinsUrl]);

  // Relevancy logic
  const [relevancyList, setRelevancyList] = useState<any[]>([]); // { label: string, value: string }
  const [relevancyLabelsList, setRelevancyLabelsList] = useState<string[]>([]); // [label1, label2, ...]
  const [selectedRelevancies, setSelectedRelevancies] = useState<string[]>([]); // [label1, label2, ...]

  const relevancyOptions = filterData?.params?.find(
    (param: any) => param?.id === "relevancy"
  )?.options;

  useEffect(() => {
    if (relevancyOptions) {
      setRelevancyList(relevancyOptions);
      setRelevancyLabelsList(relevancyOptions.map((item: any) => item.label));
    }
  }, [relevancyOptions]);

  // General logic
  const [filterParams, setFilterParams] = useState<any>({});
  const [allowedApply, setAllowedApply] = useState(true);

  const scrollToDropdown = (ref: any) => {
    ref.current?.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (openDate) {
      scrollToDropdown(dateRangeRef);
    }
    if (openCompareDate) {
      scrollToDropdown(compareDateRangeRef);
    }
  }, [openDate, openCompareDate]);

  const getDefaultSettings = () => {
    setFilterParams(params);
    setPeriod(params?.period); // Text or Number
    setComparisonPeriod(params?.comparison_period); // Text
    setMultiBrandType(params?.branded_vs_unbranded);
    setBrandType(params?.branded_vs_unbranded);
    setMultiBrand(params?.brand);
    setSelectedBrands([]);
    setMultiPaidType(params?.paid_total_organic);
    setPaidType(params?.paid_total_organic);
    const dateRange = parseDateRangeParam(
      params?.date_range || getDefaultDateRange()
    );
    setDateRange(getParsedDate(dateRange));
    const compareDateRange = parseDateRangeParam(params?.compared_date_range);
    setCompareDateRange(
      compareDateRange ? getParsedDate(compareDateRange) : null
    );
    setPickedSpace(params?.space_id);
  };

  const getMemoizedDefaultsettings = useCallback(getDefaultSettings, [
    filterData?.params?.length,
    JSON.stringify(params),
  ]);

  useEffect(() => {
    getMemoizedDefaultsettings();
  }, [filterData?.params?.length, JSON.stringify(params)]);

  const handleDateRangeSelector = () => {
    setOpenDate(!openDate);
  };

  const handleCompareDateRangeSelector = () => {
    setOpenCompareDate(!openCompareDate);
  };
  const handleApplyDate = (range: DefinedRange, id: string) => {
    setDateRange({ ...range, id });
    if (compareDateRange && compareDateRange?.label) {
      setCompareDateRange(getCompareDateRanges(range)[0]);
    } else {
      setCompareDateRange(null);
    }
    handleDateRangeSelector();
  };

  const handleApplyCompareDate = (range: DefinedRange, id: string) => {
    setCompareDateRange({ ...range, id });
    handleCompareDateRangeSelector();
  };

  useEffect(() => {
    let updatedFilterParams = { ...filterParams };
    if (dateRange) {
      updatedFilterParams = {
        ...updatedFilterParams,
        date_range: genDateRangeParam(dateRange),
      };
    }
    if (compareDateRange) {
      updatedFilterParams = {
        ...updatedFilterParams,
        compared_date_range: genDateRangeParam(compareDateRange),
      };
    }

    const { date_range, compared_date_range } = params;
    if (
      (date_range && !dateRange) ||
      (compared_date_range && !compareDateRange)
    ) {
      setAllowedApply(false);
    } else {
      setAllowedApply(true);
    }

    setFilterParams(updatedFilterParams);
  }, [dateRange, compareDateRange]);

  // const handeCloseCategoryModal = () => {
  //   setOpenCategoryDialog(false);
  // };

  // const handleOpenCategoryDialog = (option: string) => {
  //   setOpenCategoryDialog(true);
  //   getOptionsUrlData(option, "catgory");
  // };

  const handleApplyFilter = async () => {
    if (!allowedApply) return;
    setIsVisible(false);
    const filterOptionParam = Object.keys(filterParams)?.map((keyName) => ({
      id: keyName,
      value: filterParams[keyName],
    }));
    await addStoryPointToStoryboard(
      storyPointType,
      filterOptionParam,
      storyBoardIndex
    ).then(() => onclose());
  };

  const addBrandType = (brandTypeName: string) => {
    setBrandType(brandTypeName);
    setFilterParams({
      ...filterParams,
      branded_vs_unbranded: brandTypeName,
    });
  };

  const addPaidType = (paidTypeName: string) => {
    setPaidType(paidTypeName);
    setFilterParams({
      ...filterParams,
      paid_total_organic: paidTypeName,
    });
  };
  // const handleChat = () => {
  //   setOpenCategoryDialog(false);
  // };

  const onPeriodChange = (e: any) => {
    setPeriod(e.target.value);
    setFilterParams({
      ...filterParams,
      period: e.target.value,
    });
  };

  const onComparisonPeriodChange = (e: any) => {
    setComparisonPeriod(e.target.value);
    setFilterParams({
      ...filterParams,
      comparison_period: e.target.value,
    });
  };

  const onMultiBrandTypeChange = (e: any) => {
    setMultiBrandType(e.target.value);
    setFilterParams({
      ...filterParams,
      branded_vs_unbranded: e.target.value,
    });
  };

  const onMultiPaidTypeChange = (e: any) => {
    setMultiPaidType(e.target.value);
    setFilterParams({
      ...filterParams,
      paid_total_organic: e.target.value,
    });
  };

  const onMultiBrandChange = (e: any) => {
    setMultiBrand(e.target.value);
    setFilterParams({
      ...filterParams,
      brand: e.target.value,
    });
  };

  const onSpaceChange = (e: any) => {
    setPickedSpace(e.target.value);
    setFilterParams({
      ...filterParams,
      space_id: e.target.value,
    });
  };

  useEffect(() => {
    if (selectedBrands?.length > 0) {
      setFilterParams({
        ...filterParams,
        brand: [...selectedBrands]?.join(","),
      });
    }
  }, [selectedBrands?.length]);

  useEffect(() => {
    if (selectedAsins?.length > 0) {
      setFilterParams({
        ...filterParams,
        asin: [...selectedAsins]?.join(","),
      });
    }
  }, [selectedAsins?.length]);

  useEffect(() => {
    if (selectedRelevancies?.length > 0) {
      // Labels to values
      const selectedRelevancyValues = relevancyList
        ?.filter((item) => selectedRelevancies.includes(item.label))
        ?.map((item) => item.value);
      setFilterParams({
        ...filterParams,
        relevancy: selectedRelevancyValues,
      });
    }
  }, [selectedRelevancies?.length]);

  const selectFieldCount = filterData?.params?.reduce(
    (acc: number, curr: any) => {
      if (curr?.input_type === "select") {
        acc++;
      }
      return acc;
    },
    0
  );

  const filterButtonWidth =
    selectFieldCount > 4
      ? "25%"
      : selectFieldCount === 1
        ? "50%"
        : `${(100 / selectFieldCount).toFixed(2)}%`;

  const upperFiltersCount = filterData?.params?.filter(
    (prms: any) =>
      prms.id === "period" || // Including comparison_period
      prms.id === "date_range" || // Including compared_date_range
      (prms.id === "space_id" && prms.input_type === "select")
  )?.length;

  return !filterData ? (
    <ChatFilterSkeleton />
  ) : (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "1rem",
        minHeight: "100px",
      }}
      id="new-filter-container"
    >
      <Box
        id="all-filters-container"
        padding="0"
        sx={{
          display: "flex",
          padding: "0",
          width: "100%",
          gap: "22px",
          flexDirection: "column",
          "& .MuiInputLabel-root": {
            fontSize: "13px",
            fontWeight: 500,
            color: theme.palette.grey[500],
          },
          "& .MuiChip-label": {
            fontSize: "13px",
            fontWeight: 500,
            color: theme.palette.grey[500],
            padding: 0,
          },
          "& .MuiChip-filled": {
            background: theme.palette.grey[25],
            padding: "8px",
            width: "fit-content",
            borderRadius: "100px",
            minWidth: "32px",
          },
        }}
      >
        {upperFiltersCount > 0 && (
          <Box
            id="upper-filters"
            sx={{
              display: "flex",
              gap: "1rem",
            }}
          >
            {filterData?.params?.map((el: any) =>
              el?.input_type === "hidden" ? null : (
                <>
                  {(el.id === "period" ||
                    el.id === "date_range" ||
                    el.id === "space_id") &&
                    (el?.input_type === "select" ? (
                      <Box
                        sx={{
                          width: filterButtonWidth,
                        }}
                      >
                        <InputLabel
                          htmlFor="color"
                          sx={{ marginBottom: "5px" }}
                        >
                          {el?.name}
                        </InputLabel>
                        {el.id !== "space_id" ? (
                          <Select value={period} onChange={onPeriodChange}>
                            {el?.options?.map((option: any) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <Select value={pickedSpace} onChange={onSpaceChange}>
                            {spacesList?.map((space: any) => (
                              <MenuItem key={space.id} value={space.id}>
                                {space.name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme.palette.grey[200],
                          },
                          "& .MuiSelect-select": {
                            color: theme.palette.grey[500],
                            fontSize: "13px",
                            fontWeight: 500,
                          },
                          width: filterButtonWidth,
                        }}
                      >
                        <InputLabel
                          htmlFor="color"
                          sx={{ marginBottom: "5px" }}
                        >
                          {el?.name}
                        </InputLabel>
                        <Box
                          display="flex"
                          flexDirection="column"
                          gap="5px"
                          position="relative"
                        >
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            borderRadius="4px"
                            border="1px solid #D7D5DB"
                            padding="8px 12px"
                            gap="10px"
                            sx={{
                              cursor: "pointer",
                              "&:hover": {
                                border: "1px solid #2C224C",
                              },
                              ...(openDate && {
                                border: (theme) =>
                                  `1px solid ${theme.palette.primary.main}`,
                              }),
                            }}
                            onClick={handleDateRangeSelector}
                          >
                            <Box display="flex" gap="10px" alignItems="center">
                              <CalenderIcon />
                              <Box>
                                {getNewFormattedDateString(
                                  dateRange,
                                  "Select a date range"
                                )}
                              </Box>
                            </Box>
                            {openDate ? <ArrowUpIcon /> : <DropDownIcon />}
                          </Box>
                          <Box
                            position="absolute"
                            top="100%"
                            // right={0}
                            zIndex={1}
                            ref={dateRangeRef}
                          >
                            <DateRangeSelector
                              open={openDate}
                              close={handleDateRangeSelector}
                              handleSubmitDateRangePicker={(e: any) =>
                                handleApplyDate(e, "date_range")
                              }
                              dateRanges={getDateRanges(
                                new Date(),
                                el?.options
                              )}
                              currentDateRange={dateRange}
                              initialDateRange={{
                                startDate: dateRange.startDate,
                                endDate: dateRange.endDate,
                              }}
                              showDefinedRanges={!!el?.options}
                            />
                          </Box>
                        </Box>
                      </Box>
                    ))}

                  {(el.id === "comparison_period" ||
                    el?.id === "compared_date_range") &&
                    (el?.input_type === "select" ? (
                      <Box
                        sx={{
                          width: filterButtonWidth,
                        }}
                      >
                        <InputLabel
                          htmlFor="color"
                          sx={{ marginBottom: "5px" }}
                        >
                          {el?.name}
                        </InputLabel>
                        <Select
                          // variant="filled"
                          id="tenant-select-component"
                          value={comparisonPeriod}
                          onChange={onComparisonPeriodChange}
                        >
                          {(
                            period === "year_to_date" ? 
                            el?.options?.filter((option: any) => option.value === "previous_year") || []:
                            el?.options || []
                          ).map((option: any) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: theme.palette.grey[200],
                          },
                          "& .MuiSelect-select": {
                            color: theme.palette.grey[500],
                            fontSize: "13px",
                            fontWeight: 500,
                          },
                          width: filterButtonWidth,
                        }}
                      >
                        <InputLabel
                          htmlFor="color"
                          sx={{ marginBottom: "5px" }}
                        >
                          {el?.name}
                        </InputLabel>
                        <Box
                          display="flex"
                          flexDirection="column"
                          gap="5px"
                          position="relative"
                        >
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            borderRadius="4px"
                            border="1px solid #D7D5DB"
                            padding="8px 12px"
                            gap="10px"
                            minWidth="250px"
                            sx={{
                              cursor: "pointer",
                              "&:hover": {
                                  border: "1px solid #2C224C",
                              },
                              ...(openCompareDate && {
                                  border: (theme) =>
                                    `1px solid ${theme.palette.primary.main}`,
                              }),
                            }}
                            onClick={handleCompareDateRangeSelector}
                        >
                            <Box display="flex" gap="10px" alignItems="center">
                              <CalenderIcon />
                              <Box>
                                  {getNewFormattedDateString(
                                    compareDateRange,
                                    "No comparison"
                                  )}
                              </Box>
                            </Box>
                            {openCompareDate ? (
                              <ArrowUpIcon />
                            ) : (
                              <DropDownIcon />
                            )}
                        </Box>
                        <Box
                            position="absolute"
                            top="100%"
                            // right={0}
                            zIndex={1}
                            ref={compareDateRangeRef}
                          >
                            <DateRangeSelector
                              open={openCompareDate}
                              close={handleCompareDateRangeSelector}
                              handleSubmitDateRangePicker={(e: any) =>
                                handleApplyCompareDate(e, "compared_date_range")
                              }
                              dateRanges={getCompareDateRanges(dateRange)}
                              currentDateRange={compareDateRange}
                              showNoComparison
                              showDefinedRanges={!!el?.options}
                            />
                          </Box>
                        </Box>
                      </Box>
                    ))}

                  {el?.input_type === "text" && el.id !== "view_prefix" && (
                    <Box
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: theme.palette.grey[200],
                        },
                        "& .MuiSelect-select": {
                          color: theme.palette.grey[500],
                          fontSize: "13px",
                          fontWeight: 500,
                        },
                      }}
                    >
                      <InputLabel htmlFor="color" sx={{ marginBottom: "5px" }}>
                        {el?.name}
                      </InputLabel>
                      <TextField
                        fullWidth
                        id={el.id}
                        label={el.name}
                        defaultValue={el.default_value || ""}
                        margin="normal"
                      />
                    </Box>
                  )}
                  {el?.id === "branded_vs_unbranded" &&
                    el?.input_type === "select" && (
                      <Box
                        sx={{
                          width: filterButtonWidth,
                        }}
                      >
                        <InputLabel
                          htmlFor="color"
                          sx={{ marginBottom: "5px" }}
                        >
                          {el?.name}
                        </InputLabel>
                        <Select
                          // variant="filled"
                          id="tenant-select-component"
                          value={multiBrandType}
                          onChange={onMultiBrandTypeChange}
                        >
                          {el?.options?.map((option: any) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    )}

                  {el?.id === "paid_total_organic" &&
                    el?.input_type === "select" && (
                      <Box
                        sx={{
                          width: filterButtonWidth,
                        }}
                      >
                        <InputLabel
                          htmlFor="color"
                          sx={{ marginBottom: "5px" }}
                        >
                          {el?.name}
                        </InputLabel>
                        <Select
                          // variant="filled"
                          id="tenant-select-component"
                          value={multiPaidType}
                          onChange={onMultiPaidTypeChange}
                        >
                          {el?.options?.map((option: any) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    )}
                  {/* {el?.id === "categories" && (
                    <Box
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: theme.palette.grey[200],
                        },
                        "& .MuiSelect-select": {
                          color: theme.palette.grey[500],
                          fontSize: "13px",
                          fontWeight: 500,
                        },
                      }}
                    >
                      <InputLabel htmlFor="color" sx={{ marginBottom: "5px" }}>
                        {el?.name}
                      </InputLabel>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        borderRadius="4px"
                        border="1px solid #D7D5DB"
                        padding="8px 12px"
                        gap="10px"
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            border: "1px solid #2C224C",
                          },
                        }}
                        onClick={() =>
                          handleOpenCategoryDialog(el.get_options_url)
                        }
                      >
                        <Box
                          display="flex"
                          fontSize="13px"
                          color="#504C55"
                          borderRadius="100px"
                          padding="1px 8px"
                          gap="10px"
                          alignItems="center"
                          bgcolor={theme.palette.primary.light}
                        >
                          {`${selectedCategories?.length} selections`}
                        </Box>
                        <DropRightIcon />
                      </Box>
                    </Box>
                  )} */}
                  {el.id === "brand" && el?.input_type === "select" && (
                    <Box>
                      <InputLabel htmlFor="color" sx={{ marginBottom: "5px" }}>
                        {el?.name}
                      </InputLabel>
                      <Select
                        // variant="filled"
                        id="tenant-select-component"
                        value={multiBrand}
                        onChange={onMultiBrandChange}
                      >
                        {el?.options?.map((option: any) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  )}
                </>
              )
            )}
          </Box>
        )}
        <Box
          id="lower-filters"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {filterData?.params?.map((el: any) => (
            <>
              {el?.id === "branded_vs_unbranded" &&
              el?.input_type !== "select" ? (
                <Box
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme.palette.grey[200],
                    },
                    "& .MuiSelect-select": {
                      color: theme.palette.grey[500],
                      fontSize: "13px",
                      fontWeight: 500,
                    },
                    width: filterButtonWidth,
                  }}
                >
                  <InputLabel htmlFor="color" sx={{ marginBottom: "5px" }}>
                    {el?.name}
                  </InputLabel>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "8px",
                      flexWrap: "wrap",
                    }}
                  >
                    {el?.options &&
                      el.options.length > 0 &&
                      el.options.map((op: any) => {
                        const isCheck = brandType === op?.value;
                        return (
                          <Chip
                            label={op?.label}
                            sx={{
                              background: isCheck
                                ? "#EEE5FE !important"
                                : "transparent",
                              "& .MuiChip-label": {
                                color: isCheck
                                  ? "#5C30A4 !important"
                                  : theme.palette.grey[500],
                              },
                            }}
                            onClick={() => addBrandType(op.value)}
                          />
                        );
                      })}
                  </Box>
                </Box>
              ) : (
                <></>
              )}

              {el?.id === "paid_total_organic" &&
              el?.input_type !== "select" ? (
                <Box
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme.palette.grey[200],
                    },
                    "& .MuiSelect-select": {
                      color: theme.palette.grey[500],
                      fontSize: "13px",
                      fontWeight: 500,
                    },
                    width: filterButtonWidth,
                  }}
                >
                  <InputLabel htmlFor="color" sx={{ marginBottom: "5px" }}>
                    {el?.name}
                  </InputLabel>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "8px",
                      flexWrap: "wrap",
                    }}
                  >
                    {el?.options &&
                      el.options.length > 0 &&
                      el.options.map((op: any) => {
                        const isCheck = paidType === op?.value;
                        return (
                          <Chip
                            label={op?.label}
                            sx={{
                              background: isCheck
                                ? "#EEE5FE !important"
                                : "transparent",
                              "& .MuiChip-label": {
                                color: isCheck
                                  ? "#5C30A4 !important"
                                  : theme.palette.grey[500],
                              },
                            }}
                            onClick={() => addPaidType(op.value)}
                          />
                        );
                      })}
                  </Box>
                </Box>
              ) : (
                <></>
              )}
              {el?.input_type === "multi-select" && (
                <Box
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: theme.palette.grey[200],
                    },
                    "& .MuiSelect-select": {
                      color: theme.palette.grey[500],
                      fontSize: "13px",
                      fontWeight: 500,
                    },
                  }}
                >
                  {el.id === "brand" ? (
                    <Box>
                      <Typography
                        variant="caption"
                        sx={{
                          color: theme.palette.grey[400],
                          display: "block",
                        }}
                      >
                        {el?.name}
                      </Typography>
                      <MultiChoiceComponent
                        isPending={brandList.length === 0}
                        choiceList={brandList}
                        selectedChoices={selectedBrands}
                        setSelectedChoices={setSelectedBrands}
                      />
                    </Box>
                  ) : el.id === "asin" || el.id === "relevancy" ? (
                    <Box>
                      <Typography
                        variant="caption"
                        sx={{
                          color: theme.palette.grey[400],
                          display: "block",
                        }}
                      >
                        {el?.name}
                      </Typography>
                      <MultiChoiceComponent
                        isPending={
                          el.id === "asin"
                            ? asinsList.length === 0
                            : relevancyLabelsList.length === 0
                        }
                        choiceList={
                          el.id === "asin" ? asinsList : relevancyLabelsList
                        }
                        selectedChoices={
                          el.id === "asin" ? selectedAsins : selectedRelevancies
                        }
                        setSelectedChoices={
                          el.id === "asin"
                            ? setSelectedAsins
                            : setSelectedRelevancies
                        }
                      />
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
              )}
            </>
          ))}
        </Box>
      </Box>
      {/* <CategoryPopup
        open={openCategoryDialog}
        onClose={handeCloseCategoryModal}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        handleChat={handleChat}
      /> */}
      <Box
        id="filter-side-buttons"
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            height: "30px",
            alignSelf: "flex-start",
          }}
        >
          <Tooltip
            onClick={() => onclose()}
            placement="top"
            title="Close"
            arrow
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -5],
                    },
                  },
                ],
              },
            }}
          >
            <IconButton
              // onClick={onBack}
              size="small"
              className="close-btn"
              sx={{
                height: "30px",
                borderRadius: "50%",
                backgroundColor: "unset",
                "&:hover": {
                  backgroundColor: theme.palette.primary.light,
                  "& .MuiSvgIcon-root": {
                    color: theme.palette.primary.dark,
                  },
                },
                display: "none",
              }}
            >
              <ClearIcon
                sx={{
                  color: theme.palette.grey[300],
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
        <Box sx={{ margin: "auto 0" }}>
          <Typography
            onClick={() => handleApplyFilter()}
            sx={{
              display: "inline-flex",
              cursor: `${allowedApply ? "pointer" : "default"}`,
              opacity: `${allowedApply ? "1" : "0.25"}`,
            }}
          >
            <Icon
              src={`insights/${allowedApply ? "reset" : "view"}.svg`}
              height="32px"
              width="32px"
            />
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
};
export default ChatFilterNew;
